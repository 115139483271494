import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import firebase from "firebase/app"
import { initializeIcons } from "@uifabric/icons"
import "firebase/auth"
import "firebase/firestore"

firebase.initializeApp({
    apiKey: "AIzaSyDX9CJxZnVCulrA_RRfStxkmC7O9XqA97E",
    authDomain: "zohari.firebaseapp.com",
    projectId: "zohari",
    storageBucket: "zohari.appspot.com",
    messagingSenderId: "163188641106",
    appId: "1:163188641106:web:a5289ec7ee3e4648ec3e8b",
    measurementId: "G-EHBFX4V31R",
})

initializeIcons()

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
