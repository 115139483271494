import React from "react"
import { observer } from "mobx-react"
import styled from "styled-components"
import { CommandBar, ICommandBarItemProps } from "@fluentui/react"
import {
    Switch,
    Route,
    RouteComponentProps,
    withRouter,
} from "react-router-dom"
import { Flex, TopLoadingBar } from "components/shared"
import { Login } from "components/auth/login"
import { Register } from "components/auth/register"
import { ResetStart } from "components/auth/reset"
import { GlobalContext, GlobalState } from "data/state"

@observer
class RoutedAuth extends React.Component<RouteComponentProps> {
    static contextType = GlobalContext

    componentDidMount() {
        this.context.confirmAuth()
    }

    componentDidUpdate(prevProps: RouteComponentProps) {
        if (this.props.location !== prevProps.location) {
            this.context.setOrgFromPath()
        }
    }

    render() {
        const { children } = this.props
        const state = this.context as GlobalState
        const { authorized, loading, darkMode } = state
        if (authorized) {
            return children
        }
        const menuItems: ICommandBarItemProps[] = [
            {
                key: "theme",
                text: darkMode ? "Light Mode" : "Dark Mode",
                iconProps: {
                    iconName: darkMode ? "Sunny" : "ClearNight",
                },
                onClick: () => state.toggleMode(),
            },
            {
                key: "help",
                text: "Help",
                iconProps: { iconName: "Unknown" },
                ariaLabel: "Help",
            },
        ]
        return (
            <StyledContainer className={state.darkMode ? "dark" : "light"}>
                <TopLoadingBar loading={loading} />
                <nav>
                    <img className="banner" src="/banner.png" alt="" />
                    <CommandBar items={menuItems} />
                </nav>
                <div className="inner">
                    <Switch>
                        <Route exact path="/auth/new" component={Register} />
                        <Route
                            exact
                            path="/auth/reset"
                            component={ResetStart}
                        />
                        <Route component={Login} />
                    </Switch>
                </div>
            </StyledContainer>
        )
    }
}

const StyledContainer = styled(Flex)`
    align-items: center;
    flex-direction: column;
    padding-top: 120px;

    nav {
        .banner {
            height: 36px;
            width: auto;
            align-self: flex-start;
            margin: 5px 4px;
        }
        top: 2px;
        right: 0;
        left: 0;
        position: fixed;
        display: flex;
        justify-content: space-between;
    }
    .inner {
        width: 400px;
    }
    @media (max-width: 500px) {
        .inner {
            width: 100%;
        }
    }
`

export const Auth = withRouter(RoutedAuth)
