import React from "react"
import { Contact } from "data/contacts"
import { RouteComponentProps, Link, Prompt } from "react-router-dom"
import { observer } from "mobx-react"
import styled from "styled-components"
import { GlobalContext, GlobalState } from "data/state"
import { PageCommandBar, Flex, Break, DataPoint } from "components/shared"
import { NotFound } from "components/notfound"
import TimeAgo from "react-timeago"
import { AttributeEditor } from "./attributes"
import { PrimaryButton } from "@fluentui/react"
import { HasScope } from "components/access"
import { Scope } from "data/scope"

interface ContactViewProps extends RouteComponentProps {
    match: {
        isExact: boolean
        params: {
            contactId: string
            query?: string
        }
        path: string
        url: string
    }
}

interface ContactViewState {
    contact?: Contact
    screen: "info" | "flows"
}

@observer
export class ContactView extends React.Component<
    ContactViewProps,
    ContactViewState
> {
    static contextType = GlobalContext
    constructor(props: ContactViewProps) {
        super(props)
        this.state = {
            screen: "info",
        }
    }
    componentDidMount() {
        const { contactId } = this.props.match.params
        const state: GlobalState = this.context
        this.setState({
            contact: new Contact(
                contactId,
                undefined,
                state.promiseLoadingHelper()
            ),
        })
    }
    componentDidUpdate({
        match: {
            params: { contactId },
        },
    }: ContactViewProps) {
        const state: GlobalState = this.context
        if (contactId !== this.props.match.params.contactId) {
            this.setState({
                contact: new Contact(
                    this.props.match.params.contactId,
                    undefined,
                    state.promiseLoadingHelper()
                ),
            })
        }
    }
    render() {
        if (!this.state || this.state.contact === undefined) return null
        const contact = this.state.contact
        const { screen } = this.state
        const state: GlobalState = this.context
        const {
            match: {
                params: { query, contactId },
            },
        } = this.props
        if (!contact.exists) {
            return (
                <HasScope scope={Scope.ReadContacts}>
                    <NotFound title="Contact Not Found">
                        Contact <kbd>{contactId}</kbd> was not found in{" "}
                        {state.organization?.Name}.<br />
                        {query === undefined && (
                            <Link to={`/${state.organizationId}/contacts`}>
                                Go to contacts list.
                            </Link>
                        )}
                        {query !== undefined && (
                            <Link
                                to={`/${state.organizationId}/contacts/q/${query}`}>
                                Back to search results.
                            </Link>
                        )}
                    </NotFound>
                </HasScope>
            )
        }
        return (
            <HasScope scope={Scope.ReadContacts}>
                <StyledContactView>
                    <Prompt
                        when={contact.changes.length > 0}
                        message={`The changes you've made to this contact will be lost. Do you want to continue?`}
                    />
                    <PageCommandBar
                        icon={`https://lipis.github.io/flag-icon-css/flags/1x1/${contact.Country.toLowerCase()}.svg`}
                        title={contact.DisplayName}>
                        {[
                            {
                                key: "undo",
                                iconProps: { iconName: "Undo" },
                                iconOnly: true,
                                disabled: !contact.canUndo,
                                onClick: () => contact.undo(),
                            },
                            {
                                key: "redo",
                                iconProps: { iconName: "Redo" },
                                iconOnly: true,
                                disabled: !contact.canRedo,
                                onClick: () => contact.redo(),
                            },
                            {
                                key: "info",
                                text: "Info",
                                className: screen === "info" ? "selected" : "",
                                onClick: () =>
                                    this.setState({ screen: "info" }),
                                iconProps: { iconName: "ContactInfo" },
                            },
                        ]}
                    </PageCommandBar>
                    <Flex>
                        <DataPoint name="Registered">
                            <TimeAgo
                                className="content"
                                date={contact.RegisteredDate}
                            />
                        </DataPoint>
                        <DataPoint name="Last Seen">
                            <TimeAgo
                                className="content"
                                date={contact.LastAccess}
                            />
                        </DataPoint>
                    </Flex>
                    <AttributeEditor contact={contact} />
                    <Break size={3} />
                    <Flex justify="flex-end" style={{ paddingRight: "0.5em" }}>
                        <PrimaryButton
                            disabled={
                                contact.changes.length === 0 ||
                                !state.user?.Scope.can_write_contacts
                            }
                            onClick={() => {
                                contact
                                    .update()
                                    .finally(state.promiseLoadingHelper())
                            }}
                            text="Save"
                        />
                    </Flex>
                </StyledContactView>
            </HasScope>
        )
    }
}

const StyledContactView = styled.div`
    flex-grow: 1;
`
