import React from "react"
import styled from "styled-components"
import { observer } from "mobx-react"
import {
    TextField,
    Dropdown,
    IDropdownOption,
    Icon,
    ActionButton,
    IconButton,
    IContextualMenuProps,
    ContextualMenu,
    Toggle,
} from "@fluentui/react"
import { Break, Flex, FlexColumn, SmallTitle } from "components/shared"
import { Logic, TypeData, NewLogic } from "data/flows"
import { GlobalContext, GlobalState } from "data/state"
import { AdvancedEditor } from "components/flows/advanced-editor"

interface QuestionEditorProps {
    logic: Logic
    parent: {
        index: number
        logic: Logic
    } | null
    mayBeRedraw: () => void
    onClose: () => void
}

interface QuestionEditorState {
    advanced: boolean
}

@observer
export class QuestionEditor extends React.Component<
    QuestionEditorProps,
    QuestionEditorState
> {
    static contextType = GlobalContext
    constructor(props: QuestionEditorProps) {
        super(props)
        this.state = {
            advanced: false,
        }
    }
    setQuestionType(questionType: string) {
        const { logic, mayBeRedraw } = this.props
        if (questionType === "END") {
            logic.type = questionType
            logic.next = null
            logic.options = null
            logic.enumOptions = null
            logic.customData = null
            logic.redirect = null
        }
        if (questionType === "OPTIONS") {
            logic.type = questionType
            logic.next = null
            logic.options = [NewLogic()]
            logic.enumOptions = null
            logic.customData = null
            logic.redirect = null
        }
        if (questionType === "OPEN_TEXT" || questionType === "OPEN_NUMBER") {
            logic.type = questionType
            if (!logic.next) logic.next = NewLogic()
            logic.options = null
            logic.enumOptions = null
            logic.customData = null
            logic.redirect = null
        }
        if (questionType === "ENUM") {
            logic.type = questionType
            if (!logic.next) logic.next = NewLogic()
            logic.options = null
            logic.enumOptions = [
                {
                    label: "",
                    logic: null,
                },
            ]
            logic.customData = null
            logic.redirect = null
        }
        if (questionType === "CUSTOM") {
            logic.type = questionType
            if (!logic.next) logic.next = NewLogic()
            logic.options = null
            logic.enumOptions = null
            logic.customData = {
                code: "",
                errorMessage: "",
            }
            logic.redirect = null
            logic.displayText = ""
        }
        if (questionType === "REDIRECT") {
            logic.type = questionType
            logic.next = null
            logic.options = null
            logic.enumOptions = null
            logic.customData = null
            logic.redirect = ""
            logic.displayText = ""
        }
        mayBeRedraw()
    }

    addOption() {
        const { logic, mayBeRedraw } = this.props
        logic.options?.push(NewLogic())
        mayBeRedraw()
    }

    addResponse() {
        const { logic, mayBeRedraw } = this.props
        logic.enumOptions?.push({
            label: "",
            logic: null,
        })
        mayBeRedraw()
    }

    removeSelf() {
        const { parent } = this.props
        if (parent === null) return
        parent.logic.options?.splice(parent.index, 1)
    }

    deleteOption(i: number) {
        const { logic } = this.props
        logic.enumOptions?.splice(i, 1)
        console.log("Deleting option " + i)
    }

    move(steps: number) {
        const { parent } = this.props
        if (parent === null || parent.logic.options === null) return
        const temp = parent.logic.options.splice(parent.index, 1)
        parent.logic.options.splice(parent.index + steps, 0, temp[0])
    }

    render() {
        const { logic, onClose, parent } = this.props
        const { advanced } = this.state
        const state = this.context as GlobalState

        const menuProps: IContextualMenuProps = {
            items: [
                {
                    key: "advance",
                    text: "Advanced Editor",
                    onClick: () => this.setState({ advanced: true }),
                    iconProps: { iconName: "EditCreate" },
                },
            ],
        }

        if (parent !== null) {
            menuProps.items.push({
                key: "delete",
                text: "Delete Option",
                iconProps: { iconName: "Delete" },
                onClick: this.removeSelf.bind(this),
            })
        }

        return (
            <AdvancedEditor
                logic={logic}
                advanced={advanced}
                onClose={() => this.setState({ advanced: false })}>
                <StyledQuestionEditor
                    className={advanced ? "advanced" : "inline"}>
                    {!Boolean(parent && parent.logic.type === "ENUM") && (
                        <TextField
                            placeholder="Question title..."
                            label="Question Title"
                            value={logic.title}
                            onChange={(_, value) => {
                                if (value !== undefined) {
                                    logic.title = value
                                }
                            }}
                        />
                    )}
                    {!Boolean(parent && parent.logic.type === "ENUM") && (
                        <Break />
                    )}
                    <Dropdown
                        label="Question Type"
                        selectedKey={logic.type}
                        options={questionTypes}
                        onChange={(_, option) => {
                            if (logic?.type !== option?.key) {
                                this.setQuestionType(`${option?.key}`)
                            }
                        }}
                        onRenderOption={(option) => (
                            <Flex align="center">
                                <StyledIcon
                                    iconName={TypeData[`${option?.key}`].icon}
                                />
                                <span>{TypeData[`${option?.key}`].label}</span>
                            </Flex>
                        )}
                        onRenderTitle={() => (
                            <Flex align="center">
                                <StyledIcon
                                    iconName={TypeData[logic.type].icon}
                                />
                                <span>{TypeData[logic.type].label}</span>
                            </Flex>
                        )}
                    />
                    <Break />
                    {Boolean(
                        logic.type !== "REDIRECT" && logic.type !== "CUSTOM"
                    ) && (
                        <TextField
                            multiline
                            placeholder="Question content..."
                            resizable={false}
                            autoAdjustHeight
                            label="Question Text"
                            value={logic.displayText}
                            onChange={(_, value) => {
                                if (value !== undefined) {
                                    logic.displayText = value
                                }
                            }}
                        />
                    )}
                    {logic.type === "REDIRECT" && (
                        <Dropdown
                            label="Goto Flow"
                            selectedKey={logic.redirect}
                            options={Object.values(state.flows).map((fl) => ({
                                key: fl.doc.id,
                                text: fl.Name,
                            }))}
                            placeholder="Select A flow"
                            onChange={(_, option) => {
                                if (option === undefined) return
                                logic.redirect = `${option.key}`
                            }}
                            onRenderOption={(option) => (
                                <Flex align="center">
                                    <StyledIcon iconName="FlowChart" />
                                    <span>{option?.text}</span>
                                </Flex>
                            )}
                            onRenderTitle={() => (
                                <Flex align="center">
                                    <StyledIcon iconName="FlowChart" />
                                    <span>
                                        {state.flows[`${logic.redirect}`].Name}
                                    </span>
                                </Flex>
                            )}
                        />
                    )}
                    <Break />
                    {advanced &&
                        logic.type !== "REDIRECT" &&
                        logic.type !== "END" && (
                            <FlexColumn>
                                <Toggle
                                    onText={
                                        "Enable reply with * to go to previous"
                                    }
                                    offText={
                                        "Enable reply with * to go to previous"
                                    }
                                    checked={logic.showPrev !== null}
                                    onChange={(evt, checked) => {
                                        if (checked) {
                                            logic.showPrev = ""
                                        } else {
                                            logic.showPrev = null
                                        }
                                    }}
                                />
                                {logic.showPrev !== null && (
                                    <TextField
                                        label="Go to previous text"
                                        placeholder="_blank_"
                                        value={logic.showPrev}
                                        onChange={(evt, value) => {
                                            if (value !== undefined) {
                                                logic.showPrev = value
                                            }
                                        }}
                                    />
                                )}
                                {logic.showPrev !== null && <Break />}
                            </FlexColumn>
                        )}
                    {advanced &&
                        logic.type !== "REDIRECT" &&
                        logic.type !== "END" && (
                            <FlexColumn>
                                <Toggle
                                    onText={"Enable reply with # to go to home"}
                                    offText={
                                        "Enable reply with # to go to home"
                                    }
                                    checked={logic.showHome !== null}
                                    onChange={(evt, checked) => {
                                        if (checked) {
                                            logic.showHome = ""
                                        } else {
                                            logic.showHome = null
                                        }
                                    }}
                                />
                                {logic.showHome !== null && (
                                    <TextField
                                        label="Go to home text"
                                        placeholder="_blank_"
                                        value={logic.showHome}
                                        onChange={(evt, value) => {
                                            if (value !== undefined) {
                                                logic.showHome = value
                                            }
                                        }}
                                    />
                                )}
                                {logic.showHome !== null && <Break />}
                            </FlexColumn>
                        )}
                    {logic.type === "ENUM" && (
                        <FlexColumn>
                            <SmallTitle>Acceptable Responses</SmallTitle>
                            {logic.enumOptions?.map((value, i) => {
                                return (
                                    <ResponseItem
                                        key={i}
                                        index={i}
                                        logic={logic}
                                        onDelete={() => this.deleteOption(i)}
                                        mayBeRedraw={this.props.mayBeRedraw}
                                    />
                                )
                            })}
                        </FlexColumn>
                    )}

                    <Flex justify="flex-end" align="center">
                        {Boolean(
                            parent !== null && parent.index > 0 && !advanced
                        ) && (
                            <IconButton
                                title="Move Up"
                                iconProps={{ iconName: "Up" }}
                                onClick={() => this.move(-1)}
                            />
                        )}
                        {Boolean(
                            parent !== null &&
                                parent.logic.options !== null &&
                                parent.index <
                                    parent.logic.options.length - 1 &&
                                !advanced
                        ) && (
                            <IconButton
                                title="Move Down"
                                iconProps={{ iconName: "Down" }}
                                onClick={() => this.move(+1)}
                            />
                        )}
                        {Boolean(logic.type === "OPTIONS" && !advanced) && (
                            <ActionButton
                                iconProps={{ iconName: "AddNotes" }}
                                onClick={this.addOption.bind(this)}>
                                Add option
                            </ActionButton>
                        )}
                        {logic.type === "ENUM" && (
                            <ActionButton
                                iconProps={{ iconName: "AddNotes" }}
                                onClick={this.addResponse.bind(this)}>
                                Add response
                            </ActionButton>
                        )}
                        {!advanced && (
                            <ActionButton onClick={onClose}>Close</ActionButton>
                        )}
                        {!advanced && (
                            <IconButton
                                title="More"
                                iconProps={{ iconName: "MoreVertical" }}
                                menuProps={menuProps}
                                menuIconProps={{ style: { display: "none" } }}
                            />
                        )}
                    </Flex>
                </StyledQuestionEditor>
            </AdvancedEditor>
        )
    }
}

const StyledQuestionEditor = styled.div`
    background-color: ${(props) => props.theme.colors.background};
    padding: 0.75em;
    margin-top: 1em;
    margin-bottom: -0.5em;
    border-radius: 0.5em;
    &.advanced {
        width: 300px !important;
        border-right: 1px solid ${(props) => props.theme.colors.textPrimary};
        border-radius: 0;
        margin-bottom: 0.5em;
    }
`

const questionTypes: IDropdownOption[] = [
    { key: "OPTIONS", text: "" },
    { key: "OPEN_TEXT", text: "" },
    { key: "OPEN_NUMBER", text: "" },
    { key: "ENUM", text: "" },
    { key: "REDIRECT", text: "" },
    { key: "CUSTOM", text: "" },
    { key: "END", text: "" },
]

const StyledIcon = styled(Icon)`
    color: ${(props) => props.theme.colors.primaryDark};
    margin: 0.25em 0.5em;
`

const StyledResponseItem = styled(TextField)`
    flex-grow: 1;
    .more {
        cursor: pointer;
        margin: 10px 5px;
        color: ${(props) => props.theme.colors.primaryDark};
        &:hover {
            color: ${(props) => props.theme.colors.primary};
        }
    }
`

interface ResponseItemProps {
    logic: Logic
    index: number
    onDelete: () => void
    mayBeRedraw: () => void
}

interface ResponseItemState {
    menuTarget?: HTMLElement
}

@observer
class ResponseItem extends React.Component<
    ResponseItemProps,
    ResponseItemState
> {
    constructor(props: ResponseItemProps) {
        super(props)
        this.state = {
            menuTarget: undefined,
        }
    }
    render() {
        const { logic, index, onDelete } = this.props
        const { menuTarget } = this.state
        if (!logic.enumOptions) return
        const value = logic.enumOptions[index]
        const menuProps: IContextualMenuProps = {
            items: [],
            target: menuTarget,
            hidden: !menuTarget,
            onDismiss: () => this.setState({ menuTarget: undefined }),
        }
        if (value.logic) {
            menuProps.items.push({
                text: "Remove Branch",
                key: "goto",
                iconProps: {
                    iconName: "Blocked",
                },
                onClick: () => {
                    value.logic = null
                    this.props.mayBeRedraw()
                },
            })
        } else {
            menuProps.items.push({
                text: "Branch Off",
                key: "goto",
                iconProps: {
                    iconName: "BranchFork",
                },
                onClick: () => {
                    value.logic = NewLogic()
                    value.logic.title = value.label
                    this.props.mayBeRedraw()
                },
            })
        }
        menuProps.items.push({
            text: "Delete",
            key: "Delete",
            iconProps: {
                iconName: "Delete",
            },
            onClick: onDelete,
        })
        return [
            <StyledResponseItem
                key="input"
                underlined
                value={value.label}
                prefix={`${index + 1}`}
                className="input"
                onChange={(evt) => {
                    if (logic.enumOptions === null) return
                    value.label = evt.currentTarget.value
                    if (value.logic !== null) {
                        value.logic.title = evt.currentTarget.value
                    }
                }}
                iconProps={{
                    iconName: "More",
                    className: "more",
                    onClick: (evt) =>
                        this.setState({ menuTarget: evt.currentTarget }),
                }}
            />,
            <ContextualMenu key="menu" {...menuProps} />,
        ]
    }
}
