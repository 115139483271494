import { observable } from "mobx"
import { LiveData } from "data/livedata"

export class FirebaseUser extends LiveData {
    static Collection = LiveData.collection("FirebaseUsers")
    static Fields = ["Emails", "DisplayName", "Created", "LastSeen"]

    @observable Emails: string[] = [""]
    @observable DisplayName: string = ""
    @observable Created: Date = new Date()
    @observable LastSeen: Date = new Date()
}
