import { LiveData } from "data/livedata"
import { computed, observable } from "mobx"
import {
    ChannelType,
    ChannelStatus,
    ChannelCategory,
    ChannelRequestStatus,
    channelLabels,
} from "./channel_types"

export class Channel extends LiveData {
    // Channel identifier e.g. short code, key word...
    // Should probably have the structure: TYPE:COUNTRY:ID
    // ie. SC:KE:20788 since the same short code can be in multiple countries or networks
    @observable UniqueID: string = ""
    // Now this is where the shortcode value or whatsapp number is seen.
    @observable Label: string = ""
    @observable Type: ChannelType = "SHORT_CODE"
    @observable Category: ChannelCategory = "Private"
    @observable OrganizationID: string = ""
    @observable Description: string = ""
    @observable Expires: null | Date = null
    @observable Status: ChannelStatus = "Active"
    @observable RequestId: string | null = null // THe request ID that led to this channel
    @observable FlowID: string | null = null
    @observable Created: Date = new Date()
    @observable Updated: Date = new Date()
    static Collection = LiveData.collection("Channels")
    static Fields = [
        "UniqueID",
        "Label",
        "Type",
        "Category",
        "OrganizationID",
        "Description",
        "Expires",
        "Status",
        "RequestId",
        "FlowID",
        "Created",
        "Updated",
    ]

    @computed get ShortId(): string {
        return this.UniqueID.split(":")[2] + ""
    }

    @computed get KeyWord(): string {
        return this.UniqueID.split(":")[3] + ""
    }

    @computed get label() {
        return channelLabels.get(this.Type)
    }
}

export class ChannelRequest extends LiveData {
    @observable Type: ChannelType = "SHORT_CODE"
    @observable DeliverBefore: Date = new Date()
    @observable RequestedBy: string = "" // User id of the requester
    @observable OrganizationID: string = ""
    @observable Email: string = "" // Pre-filled with the users email, they can provide an alternate contact.
    @observable Phone: string = ""
    @observable Message: string = "" // Message from the requesting user
    @observable Notes: string = ""
    @observable Status: ChannelRequestStatus = "Requested"
    @observable Created: Date = new Date()
    @observable Updated: Date = new Date()
    static Collection = LiveData.collection("ChannelRequests")
    static Fields = [
        "Type",
        "DeliverBefore",
        "RequestedBy",
        "OrganizationID",
        "Email",
        "Phone",
        "Message",
        "Notes",
        "Status",
        "Created",
        "Updated",
    ]

    @computed get label() {
        return channelLabels.get(this.Type)
    }
}
