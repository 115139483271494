import React from "react"
import styled from "styled-components"
import { observer } from "mobx-react"
import { Link, RouteComponentProps } from "react-router-dom"
import TimeAgo from "react-timeago"
import { MessageBar, MessageBarType } from "@fluentui/react"
import { GlobalContext, GlobalState } from "data/state"
import {
    Break,
    ConstrainedBody,
    Flex,
    FlexColumn,
    PageCommandBar,
    ListItem,
    SmallTitle,
} from "components/shared"
import { LiveQuery } from "data/livedata"
import { OrganizationInvite } from "data/organization"
@observer
export class Home extends React.Component<RouteComponentProps> {
    static contextType = GlobalContext

    render() {
        const { history } = this.props
        const state = this.context as GlobalState
        const organizations = Object.values(state.organizations)
        const invites =
            state.invitesQuery === null
                ? new LiveQuery<OrganizationInvite>(OrganizationInvite)
                : state.invitesQuery
        return (
            <Body maxWidth={800}>
                <FlexColumn className="inner">
                    <Flex justify="center">
                        <img className="banner" src="banner-large.png" alt="" />
                    </Flex>
                    <Break />
                    <PageCommandBar
                        top={50}
                        icon="Org"
                        title="Select Organization">
                        {[
                            {
                                key: "create",
                                text: "Create Organization",
                                onClick: () => history.push("/create"),
                                iconProps: { iconName: "AppIconDefaultAdd" },
                            },
                        ]}
                    </PageCommandBar>
                    <Break />
                    {!organizations.length && (
                        <MessageBar messageBarType={MessageBarType.warning}>
                            You are not a member of any organization. You can{" "}
                            <Link to="/create">create one</Link> or ask your
                            organization admin to add you.
                        </MessageBar>
                    )}
                    {organizations.map((orgObj) => {
                        return (
                            <ListItem
                                key={orgObj.org.doc.id}
                                to={`/${orgObj.org.doc.id}`}
                                icon="Org"
                                text={orgObj.org.Name}
                                description={orgObj.org.Description}
                                footer={
                                    <Flex>
                                        <strong>Created: &nbsp;</strong>
                                        <TimeAgo date={orgObj.org.Created} />
                                    </Flex>
                                }
                            />
                        )
                    })}
                    <Break size={2} />
                    {Boolean(state.invitesQuery?.length) && (
                        <SmallTitle>Pending Invites</SmallTitle>
                    )}
                    {invites.map((invite) => {
                        return (
                            <ListItem
                                key={invite.doc.id}
                                to={`/invite/${invite.doc.id}`}
                                icon="AddIn"
                                text={`You're invited to join ${invite.organization.Name}`}
                                description={invite.organization.Description}
                                footer={
                                    <Flex>
                                        <strong>Invited On: &nbsp;</strong>
                                        <TimeAgo date={invite.Invited} />
                                        &nbsp;by {invite.InviterEmail}
                                    </Flex>
                                }
                            />
                        )
                    })}
                    <Break size={2} />
                </FlexColumn>
            </Body>
        )
    }
}

const Body = styled(ConstrainedBody)`
    .inner {
        padding: 2em;
        border-radius: 0.75em;
        background-color: ${(props) => props.theme.colors.backgroundDark}22;
        border: 1px solid ${(props) => props.theme.colors.backgroundDark}aa;
    }
    .banner {
        width: 320px;
        background-color: ${(props) => props.theme.colors.background};
        padding: 1em;
        border-radius: 0.75em;
    }
`
