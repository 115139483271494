import React from "react"
import { observer } from "mobx-react"
import { RouteComponentProps, Link } from "react-router-dom"
import {
    FlexColumn,
    DataPoint,
    Flex,
    Break,
    PageCommandBar,
    Loading,
} from "components/shared"
import { PermissionPicker } from "./permission_picker"
import { OrganizationInvite } from "data/organization"
import { GlobalContext, GlobalState } from "data/state"
import Timeago from "react-timeago"
import { NotFound } from "components/notfound"

interface ViewInviteProps extends RouteComponentProps {
    match: {
        isExact: boolean
        params: {
            inviteId: string
        }
        path: string
        url: string
    }
}

interface ViewUserState {
    invite?: OrganizationInvite
}

@observer
export class ViewInvite extends React.Component<
    ViewInviteProps,
    ViewUserState
> {
    static contextType = GlobalContext
    constructor(props: ViewInviteProps) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
        this.loadInvite()
    }

    loadInvite() {
        const state: GlobalState = this.context
        const { inviteId } = this.props.match.params
        const invite = new OrganizationInvite(
            inviteId,
            undefined,
            state.promiseLoadingHelper()
        )
        this.setState({ invite })
    }

    componentDidUpdate(prevProps: ViewInviteProps) {
        if (
            prevProps.match.params.inviteId !== this.props.match.params.inviteId
        ) {
            this.loadInvite()
        }
    }
    render() {
        const { invite } = this.state
        if (invite === undefined) return null

        if (invite.loading) {
            return <Loading>Loading invite...</Loading>
        }

        if (!invite.exists) {
            return (
                <NotFound>
                    Invite <kbd>{invite.doc.id}</kbd> not found in this
                    organization.
                </NotFound>
            )
        }

        const state: GlobalState = this.context

        return (
            <FlexColumn>
                <PageCommandBar
                    icon="Contact"
                    title={invite.EmailAddress}
                    top={100}>
                    {[
                        {
                            key: "cancel",
                            text: "Cancel Invite",
                            iconProps: { iconName: "BlockContact" },
                            onClick: () => {
                                invite.Status = "canceled"
                                invite
                                    .update()
                                    .finally(state.promiseLoadingHelper())
                            },
                        },
                    ]}
                </PageCommandBar>
                <Flex>
                    <DataPoint name={"Invite Id"}>{invite.doc.id}</DataPoint>

                    <DataPoint name={"Status"}>{invite.Status}</DataPoint>
                </Flex>
                <Flex>
                    <DataPoint name={"Invited By"}>
                        <Link
                            to={`/${state.organizationId}/settings/access/u/${invite.InvitedBy}`}>
                            {invite.invitedBy.firebaseUser.Emails[0]}
                        </Link>
                    </DataPoint>

                    <DataPoint name={"Invited On"}>
                        <Timeago date={invite.Invited} />
                    </DataPoint>
                </Flex>
                <Break />
                <PermissionPicker
                    readonly={true}
                    title="Permissions"
                    scope={invite.Scope}
                />
                <Break size={3} />
            </FlexColumn>
        )
    }
}
