import React from "react"
import { observer } from "mobx-react"
import { RouteComponentProps } from "react-router-dom"
import {
    Break,
    ConstrainedBody,
    Flex,
    Loading,
    PageCommandBar,
} from "components/shared"
import { OrganizationInvite, OrganizationUser } from "data/organization"
import { NotFound } from "components/notfound"
import { CompoundButton } from "@fluentui/react"
import { PermissionPicker } from "./permission_picker"
import { GlobalContext, GlobalState } from "data/state"
import { uniqueId } from "utils"

interface AcceptInviteProps extends RouteComponentProps {
    match: {
        isExact: boolean
        params: {
            inviteId: string
        }
        path: string
        url: string
    }
}

interface AcceptInviteState {
    invite?: OrganizationInvite
}

@observer
export class AcceptInvite extends React.Component<
    AcceptInviteProps,
    AcceptInviteState
> {
    static contextType = GlobalContext
    constructor(props: AcceptInviteProps) {
        super(props)
        this.state = {}
    }
    componentDidMount() {
        const state: GlobalState = this.context
        const stop = state.promiseLoadingHelper()
        const invite = new OrganizationInvite(
            this.props.match.params.inviteId,
            undefined,
            () => {
                stop()
                if (invite.Status === "accepted") {
                    this.props.history.push(`/${invite.OrganizationId}`)
                }
            }
        )
        this.setState({ invite })
    }
    rejectInvite() {
        const { invite } = this.state
        const state: GlobalState = this.context
        const { history } = this.props
        if (invite === undefined) return
        invite.Status = "rejected"
        invite.Updated = new Date()
        invite
            .update()
            .finally(state.promiseLoadingHelper())
            .then(() => history.push("/"))
    }
    acceptInvite() {
        const { invite } = this.state
        const state: GlobalState = this.context
        const { history } = this.props
        if (invite === undefined) return
        invite.Status = "accepted"
        invite.Updated = new Date()
        invite
            .update()
            .finally(state.promiseLoadingHelper())
            .then(() => {
                // Create a user
                const user = new OrganizationUser(uniqueId())
                user.OrganizationId = invite.OrganizationId
                user.Scope = invite.Scope
                user.UserId = state.authorized?.doc.id || ""
                user.update()
                    .finally(state.promiseLoadingHelper())
                    .then(() => history.push(`/${invite.OrganizationId}`))
            })
    }
    render() {
        const { invite } = this.state
        const state: GlobalState = this.context
        if (invite === undefined) return null
        if (invite.loading) return <Loading>Loading invite details...</Loading>
        if (!invite.exists || invite.Status !== "invited") {
            return (
                <NotFound>
                    Invite was not found. If you think this is a mistake contact
                    your organization administrator.
                </NotFound>
            )
        }
        if (invite.EmailAddress !== state.authorized?.Emails[0]) {
            return (
                <NotFound title="Access denied">
                    Invalid email account for this invite. Please login with the
                    email account that you received the invite from.
                </NotFound>
            )
        }
        return (
            <ConstrainedBody maxWidth={800}>
                <PageCommandBar
                    icon="AddIn"
                    title={`Invite to join ${invite.organization.Name}`}
                />
                <PermissionPicker
                    readonly
                    title="You'll have access to:"
                    scope={invite.Scope}
                />
                <Break size={2} />
                <Flex justify="space-between">
                    <CompoundButton
                        iconProps={{ iconName: "Blocked" }}
                        disabled={state.loading}
                        text="Reject Invite"
                        secondaryText="Double click to reject"
                        onDoubleClick={this.rejectInvite.bind(this)}
                    />
                    <CompoundButton
                        primary
                        iconProps={{ iconName: "SkypeCircleCheck" }}
                        disabled={state.loading}
                        text="Accept Invite"
                        secondaryText="Double click to accept"
                        onDoubleClick={this.acceptInvite.bind(this)}
                    />
                </Flex>
                <Break size={3} />
            </ConstrainedBody>
        )
    }
}
