import React from "react"
import { GlobalContext, GlobalState } from "data/state"
import { observer } from "mobx-react"
import styled from "styled-components"
import { RouteComponentProps, withRouter } from "react-router-dom"
import { Nav, INavLinkGroup, INavLink } from "@fluentui/react"

@observer
class RoutedNavMenu extends React.Component<RouteComponentProps> {
    static contextType = GlobalContext
    onLinkClick(evt?: React.MouseEvent<HTMLElement>, item?: INavLink) {
        if (evt === undefined) return
        evt.preventDefault()
        this.props.history.push(`${item?.url}`)
    }
    render() {
        const { organizationId, menuExpanded, user } = this
            .context as GlobalState
        if (user === null) return null
        const link = (
            name: string,
            path: string,
            icon: string,
            canAccess: boolean
        ): INavLink => ({
            name: menuExpanded ? name : "",
            url: `/${organizationId}${path}`,
            icon,
            disabled: !canAccess,
            title: !canAccess ? "Restricted" : undefined,
        })
        const linkGroup = (name: string, links: INavLink[]): INavLinkGroup => ({
            name,
            links,
        })
        const expandedLinks: INavLinkGroup[] = [
            linkGroup("", [link("Home", "", "Home", true)]),
            linkGroup("Contacts", [
                link(
                    "Contacts List",
                    "/contacts",
                    "ContactList",
                    user.Scope.can_read_contacts
                ),
                link(
                    "Attributes",
                    "/attributes",
                    "FieldRequired",
                    user.Scope.can_read_attributes
                ),
            ]),
            linkGroup("Flows", [
                link(
                    "My Flows",
                    "/flows",
                    "MicrosoftFlowLogo",
                    user.Scope.can_read_flows
                ),
                link(
                    "Sessions",
                    "/sessions",
                    "WorkFlow",
                    user.Scope.can_read_sessions
                ),
            ]),
            linkGroup("Settings", [
                // link("Basic", "/settings", "Settings", true),
                link(
                    "Access Management",
                    "/settings/access",
                    "PlayerSettings",
                    user.Scope.can_read_users
                ),
                link(
                    "Money",
                    "/settings/billing",
                    "Money",
                    user.Scope.is_admin
                ),
                link(
                    "Channels",
                    "/settings/channels",
                    "Link",
                    user.Scope.can_read_channels ||
                        user.Scope.can_request_channels
                ),
            ]),
        ]
        const collapsedLinks: INavLinkGroup[] = [
            {
                links: [],
            },
        ]
        expandedLinks.forEach((group) => {
            group.links.forEach((ln) => {
                collapsedLinks[0].links.push(ln)
            })
        })

        return (
            <StyledNav
                className={menuExpanded ? "expand" : "collapse"}
                onLinkClick={this.onLinkClick.bind(this)}
                isOnTop
                groups={menuExpanded ? expandedLinks : collapsedLinks}
            />
        )
    }
}

const StyledNav = styled(Nav)`
    top: 53px;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: ${(p) => p.theme.colors.background};
    filter: brightness(95%);
    &.expand {
        width: 20em;
    }
    &.collapse {
        width: 3em;
    }
    .ms-Nav-groupContent {
        margin-bottom: 1em;
    }
    &::-webkit-scrollbar {
        width: 3px;
        height: 3px;
    }
    &::-webkit-scrollbar-track {
        background-color: ${(p) => p.theme.colors.backgroundLight};
    }

    &::-webkit-scrollbar-thumb {
        background-color: ${(p) => p.theme.colors.primaryDark};
        border-radius: 5px;
    }
`

export const NavMenu = withRouter(RoutedNavMenu)
