import React from "react"
import styled from "styled-components"
import { RouteComponentProps } from "react-router-dom"
import { observer } from "mobx-react"
import { ChannelRequest } from "data/channels"
import {
    Break,
    FlexColumn,
    PageCommandBar,
    Flex,
    DataPoint,
    MessageBar,
    SmallTitle,
    Loading,
} from "components/shared"
import { GlobalContext, GlobalState } from "data/state"
import { HasScope } from "components/access"
import { Scope } from "data/scope"
import { NotFound } from "components/notfound"

interface ViewRequestProps extends RouteComponentProps {
    match: {
        isExact: boolean
        params: {
            requestId: string
        }
        path: string
        url: string
    }
}

interface ViewRequestState {
    request?: ChannelRequest
}

@observer
export class ViewRequest extends React.Component<
    ViewRequestProps,
    ViewRequestState
> {
    static contextType = GlobalContext
    constructor(props: ViewRequestProps) {
        super(props)
        this.state = {}
    }
    componentDidMount() {
        const {
            match: {
                params: { requestId },
            },
        } = this.props
        this.loadRequest(requestId)
    }

    componentDidUpdate(oldProps: ViewRequestProps) {
        const {
            match: {
                params: { requestId },
            },
        } = this.props
        if (requestId !== oldProps.match.params.requestId) {
            this.loadRequest(requestId)
        }
    }

    loadRequest(requestId: string) {
        const state: GlobalState = this.context
        const request = new ChannelRequest(
            requestId,
            undefined,
            state.promiseLoadingHelper()
        )
        this.setState({ request })
    }

    save() {
        const state: GlobalState = this.context
        const { request } = this.state
        if (request === undefined) return
        request.update().finally(state.promiseLoadingHelper())
    }

    render() {
        const { request } = this.state
        if (request === undefined || request.label === undefined) return null
        if (request.loading) return <Loading />
        if (!request.exists)
            return <NotFound title="Request not found"></NotFound>
        return (
            <HasScope scope={Scope.RequestChannels}>
                <Container>
                    <PageCommandBar
                        icon={request.label.icon}
                        title={`${request.label.label}`}
                    />
                    <Break />
                    <Flex>
                        <DataPoint name="Status">{request.Status}</DataPoint>
                    </Flex>
                    <Break size={1} />
                    <SmallTitle>Message:</SmallTitle>
                    <MessageBar>{request.Message}</MessageBar>
                    <Break size={1} />
                    <SmallTitle>Notes from admin</SmallTitle>
                    <MessageBar>{request.Notes || "None"}</MessageBar>
                </Container>
            </HasScope>
        )
    }
}

const Container = styled(FlexColumn)`
    flex-grow: 1;
    padding: 0.25em;
`
