import React from "react"
import styled from "styled-components"
import { observable } from "mobx"
import { observer } from "mobx-react"
import { Flex, FlexColumn, Title, Break, Loading } from "components/shared"
import {
    TextField,
    PrimaryButton,
    Toggle,
    Icon,
    MessageBar,
    MessageBarType,
    Dropdown,
    IconButton,
} from "@fluentui/react"
import { Flow, FlowLogic } from "data/flows"
import { uniqueId } from "utils"
import { QuestionView } from "./question"
import { RouteComponentProps, Link, Prompt } from "react-router-dom"
import { GlobalContext, GlobalState } from "data/state"
import { NotFound } from "components/notfound"
import { ChannelType, ChannelTypeOptions } from "data/channel_types"
import { HasScope } from "components/access"
import { Scope } from "data/scope"

interface FlowEditorState {
    readonly: boolean
    render_id: number
    loading: boolean
    isNew: boolean
    error?: string
    commit_message: string
}

@observer
export class FlowEditor extends React.Component<
    RouteComponentProps,
    FlowEditorState
> {
    interval: any
    @observable flow: Flow = new Flow("blank")
    static contextType = GlobalContext
    constructor(props: any) {
        super(props)
        this.state = {
            readonly: false,
            render_id: new Date().getTime(),
            loading: true,
            isNew: false,
            commit_message: "",
        }
    }

    componentDidMount() {
        const flowId = (this.props.match.params as any)["flowId"]
        this.setUp(flowId)
    }

    componentDidUpdate(prevProps: RouteComponentProps) {
        const flowId = (this.props.match.params as any)["flowId"]
        const prevFlowId = (prevProps.match.params as any)["flowId"]
        if (flowId !== prevFlowId) {
            this.setState({ loading: true }, () => {
                this.setUp(flowId)
            })
        }
    }

    setUp(flowId: string | undefined) {
        const state = this.context as GlobalState
        if (flowId === undefined) {
            // Create a flow instead
            this.flow = new Flow(uniqueId())
            this.flow.OrganizationId = `${state.organizationId}`
            this.setState({
                loading: false,
                isNew: true,
                readonly: false,
            })
            return
        }
        // Load a flow
        // Start loading
        state.startLoading("flow")
        this.flow = new Flow(flowId, undefined, () => {
            this.setState({ loading: false }, () => state.stopLoading("flow"))
        })
        this.setState({ isNew: false, readonly: true })
    }

    mayBeRedraw() {
        this.setState({ render_id: new Date().getTime() })
    }

    save() {
        let { commit_message } = this.state
        const flow = this.flow
        const state = this.context as GlobalState
        flow.Updated = new Date()
        // Backup the logic before saving
        if (flow.changes.includes("Logic")) {
            // Create
            const flowLogic = new FlowLogic(uniqueId())
            flowLogic.Commit = flow.exists
                ? commit_message
                    ? commit_message
                    : "Update flow"
                : "Created flow"
            flowLogic.Flow = flow.doc.id
            flowLogic.Logic = flow.Logic
            flowLogic.ChangedBy = `${state.user?.doc.id}`
            flowLogic
                .update()
                .then(() => this.setState({ commit_message: "" }))
                .finally(state.promiseLoadingHelper())
        }
        flow.update().finally(state.promiseLoadingHelper())
    }

    toggleEdit(readonly: boolean) {
        this.setState({ readonly })
    }

    render() {
        let { readonly, render_id, loading, isNew, commit_message } = this.state
        const flow = this.flow
        const state = this.context as GlobalState
        if (loading) {
            return <Loading title="Loading flow..." />
        }

        if (!state.user?.Scope.can_edit_flows) {
            readonly = true
        }
        if (flow === undefined) return null
        if (
            (!flow.exists && !isNew) ||
            flow.OrganizationId !== state.organizationId
        ) {
            return (
                <NotFound title="Flow Not Found">
                    The flow was doesn't exist. It might have been either
                    deleted or you don't have access to it.
                    {state.user?.Scope.can_create_flows && (
                        <span>
                            Kindly{" "}
                            <Link to={`/${state.organizationId}/flows/new`}>
                                create a flow
                            </Link>{" "}
                            to continue
                        </span>
                    )}
                </NotFound>
            )
        }
        return (
            <HasScope scope={Scope.ReadFlows}>
                <EditorContainer>
                    <Prompt
                        when={flow.changes.length > 0}
                        message={`The changes you've made to this flow will be lost. Do you want to continue?`}
                    />
                    <FlexColumn className="outer">
                        <Flex className="title" align="center">
                            <Title
                                as={Link}
                                to={`/${state.organizationId}/flows`}>
                                Flows
                            </Title>
                            <Icon
                                className="separator"
                                iconName="DoubleChevronRight"
                            />
                            <Title>{flow.Name}</Title>
                        </Flex>
                        <Flex className="flow">
                            <QuestionView
                                editable={!readonly}
                                flow={flow}
                                path=""
                                mayBeRedraw={this.mayBeRedraw.bind(this)}
                                render_id={render_id}
                            />
                        </Flex>
                    </FlexColumn>
                    <FlexColumn
                        className="sidebar"
                        as="form"
                        onSubmit={(evt: React.FormEvent) => {
                            evt.preventDefault()
                            this.save()
                        }}>
                        <Flex justify="space-between">
                            <Flex>
                                {!readonly && (
                                    <IconButton
                                        disabled={!flow.canUndo}
                                        iconProps={{ iconName: "Undo" }}
                                        onClick={() => flow?.undo()}
                                        title={
                                            flow.canUndo ? "Undo" : "Can't Undo"
                                        }
                                    />
                                )}
                                {!readonly && (
                                    <IconButton
                                        disabled={!flow.canRedo}
                                        iconProps={{ iconName: "Redo" }}
                                        onClick={() => flow?.redo()}
                                        title={
                                            flow.canRedo ? "Redo" : "Can't Redo"
                                        }
                                    />
                                )}
                            </Flex>
                            <Toggle
                                label="Edit Mode"
                                inlineLabel
                                onText="On"
                                offText="Off"
                                onChange={(evt, val) => {
                                    this.toggleEdit(!val)
                                }}
                                disabled={!state.user?.Scope.can_edit_flows}
                                checked={!readonly}
                            />
                        </Flex>
                        <Break />
                        <TextField
                            label="Flow Name"
                            value={flow.Name}
                            required
                            onChange={(evt, val) => {
                                if (readonly || flow === undefined) return
                                if (val !== undefined) flow.Name = val
                            }}
                        />
                        <Break />
                        <TextField
                            label="Description"
                            multiline
                            value={flow.Description}
                            onChange={(evt, val) => {
                                if (readonly || flow === undefined) return
                                if (val !== undefined) flow.Description = val
                            }}
                        />
                        <Break />
                        <Dropdown
                            required
                            label="Channels Allowed"
                            placeholder="Select channels"
                            selectedKeys={[...flow.Channels]}
                            multiSelect
                            options={ChannelTypeOptions}
                            onChange={(_, item) => {
                                if (item === undefined || readonly) return
                                const value = item.key as ChannelType
                                if (item?.selected) {
                                    if (!flow.Channels.includes(value)) {
                                        this.flow.Channels.push(value)
                                    }
                                } else {
                                    const i = this.flow.Channels.indexOf(value)
                                    this.flow.Channels.splice(i, 1)
                                }
                            }}
                        />
                        {flow.isValid !== null && <Break />}
                        {flow.isValid !== null && (
                            <MessageBar messageBarType={MessageBarType.error}>
                                {flow.isValid}
                            </MessageBar>
                        )}
                        {Boolean(
                            flow.changes.includes("Logic") &&
                                flow.isValid === null &&
                                flow.exists
                        ) && <Break />}
                        {Boolean(
                            flow.changes.includes("Logic") &&
                                flow.isValid === null &&
                                flow.exists
                        ) && (
                            <TextField
                                label="Notes"
                                multiline
                                placeholder="Notes regarding changes you made on the flow..."
                                value={commit_message}
                                onChange={(evt, val) => {
                                    if (val !== undefined)
                                        this.setState({ commit_message: val })
                                }}
                            />
                        )}

                        <Break />

                        <Flex justify="flex-end">
                            {!readonly && (
                                <PrimaryButton
                                    disabled={
                                        flow.isValid !== null ||
                                        flow.changes.length === 0
                                    }
                                    type="submit"
                                    text="Save"
                                />
                            )}
                        </Flex>
                    </FlexColumn>
                </EditorContainer>
            </HasScope>
        )
    }
}

const EditorContainer = styled.div`
    width: 100%;
    display: flex;
    .title {
        background-color: ${(p) => p.theme.colors.backgroundDark};
        padding: 5px;
        height: 38px;
        .separator {
            margin-top: 5px;
            padding: 7px;
        }
        a:hover {
            color: ${(p) => p.theme.colors.primaryDark};
        }
    }
    .outer {
        height: calc(100vh - 50px);
        width: calc(100% - 300px);
    }

    .sidebar {
        height: calc(100vh - 70px);
        width: 300px;
        padding: 10px 5px;
        background-color: ${(p) => p.theme.colors.backgroundDark};
    }
    .flow {
        overflow: auto;
        position: relative;
        background-color: ${(p) => p.theme.colors.background};
        &::-webkit-scrollbar {
            width: 3px;
            height: 3px;
        }
        &::-webkit-scrollbar-track {
            background-color: ${(p) => p.theme.colors.backgroundLight};
        }

        &::-webkit-scrollbar-thumb {
            background-color: ${(p) => p.theme.colors.primaryDark};
            border-radius: 5px;
        }
    }
`
