import React from "react"
import { observer } from "mobx-react"
import { RouteComponentProps } from "react-router-dom"
import { OrganizationInvite } from "data/organization"
import { PermissionPicker } from "./permission_picker"
import { uniqueId } from "utils"
import { Break, Flex, FlexColumn, PageCommandBar } from "components/shared"
import { HasScope } from "components/access"
import { GlobalContext, GlobalState } from "data/state"
import { PrimaryButton, TextField } from "@fluentui/react"

@observer
export class Invite extends React.Component<RouteComponentProps> {
    static contextType = GlobalContext
    invite = new OrganizationInvite(uniqueId())

    sendInvite() {
        const state: GlobalState = this.context
        const { history } = this.props
        this.invite.OrganizationId = state.organizationId || ""
        this.invite.OrganizationName = state.organization?.Name || ""
        this.invite.InvitedBy = state.user?.doc.id || ""
        this.invite.InviterEmail = state.authorized?.Emails[0] || ""
        this.invite
            .update()
            .finally(state.promiseLoadingHelper())
            .then(() => {
                history.push(
                    `/${state.organizationId}/settings/access/i/${this.invite.doc.id}`
                )
            })
    }

    render() {
        const state: GlobalState = this.context
        return (
            <HasScope scope="admin">
                <FlexColumn>
                    <PageCommandBar
                        top={100}
                        icon="AddFriend"
                        title="Invite User"
                    />
                    <FlexColumn style={{ padding: "0.5em" }}>
                        <TextField
                            label="Email Address"
                            placeholder="user@organization.com"
                            type="email"
                            value={this.invite.EmailAddress}
                            onChange={(evt, value) => {
                                if (value !== undefined)
                                    this.invite.EmailAddress = value
                            }}
                            disabled={state.loading}
                            errorMessage={
                                Boolean(
                                    !this.invite.emailValid &&
                                        this.invite.EmailAddress
                                )
                                    ? "Invalid email address."
                                    : undefined
                            }
                        />
                    </FlexColumn>
                    <PermissionPicker
                        readonly={state.loading}
                        title="Permissions"
                        scope={this.invite.Scope}
                    />
                    <Break />
                    <Flex justify="flex-end">
                        <PrimaryButton
                            disabled={
                                !this.invite.emailValid ||
                                this.invite.Scope.permissions.length === 0
                            }
                            text="Send Invite"
                            onClick={this.sendInvite.bind(this)}
                        />
                    </Flex>
                    <Break size={3} />
                </FlexColumn>
            </HasScope>
        )
    }
}
