import React from "react"
import styled from "styled-components"
import { observer } from "mobx-react"
import { RouteComponentProps, Link, Route } from "react-router-dom"
import {
    ConstrainedBody,
    FlexColumn,
    PageCommandBar,
    MessageBar,
    SubTitle,
    Break,
    ListItem,
    Flex,
} from "components/shared"
import { GlobalContext, GlobalState } from "data/state"
import { RequestChannel } from "./new"
import { ViewChannel } from "./view-channel"
import { ViewRequest } from "./view-request"
import { Channel, ChannelRequest } from "data/channels"
import { LiveQuery } from "data/livedata"
import { channelLabels } from "data/channel_types"
import TimeAgo from "react-timeago"
import { HasScope } from "components/access"
import { Scope } from "data/scope"

@observer
export class ChannelsList extends React.Component<RouteComponentProps> {
    static contextType = GlobalContext
    channelsQuery = new LiveQuery<Channel>(Channel)
    requestsQuery = new LiveQuery<ChannelRequest>(ChannelRequest)
    componentDidMount() {
        const state = this.context as GlobalState
        this.channelsQuery.where("OrganizationID", "==", state.organizationId)
        this.requestsQuery.where("OrganizationID", "==", state.organizationId)
        this.requestsQuery.where("Status", "==", "Requested")
        this.channelsQuery
            .run()
            .then(() => {
                if (this.channelsQuery.isEmpty) {
                    this.maybeCreateDefaultChannel()
                }
            })
            .finally(state.promiseLoadingHelper())
        this.requestsQuery.run().finally(state.promiseLoadingHelper())
    }

    maybeCreateDefaultChannel() {
        const state = this.context as GlobalState
        let orgName = `${state.organization?.Name}`.trim().split(" ")[0]
        function CreateChannel(orName: string, count: number) {
            const done = state.promiseLoadingHelper()
            let serviceId = `WHATSAPP:KE:254787075245:${orName}`.toUpperCase()
            const channel = new Channel(btoa(serviceId), undefined, () => {
                if (channel.exists) {
                    count += 1
                    CreateChannel(`${orgName}${count}`, count)
                    done()
                    return
                }
                channel.OrganizationID = `${state.organizationId}`
                channel.Category = "Shared"
                channel.UniqueID = serviceId
                channel.Type = "WHATSAPP"
                channel.Description = "Shared Whatsapp Channel"
                channel.update().finally(done)
            })
        }
        CreateChannel(orgName, 0)
    }
    render() {
        const {
            match: { isExact },
            history,
        } = this.props
        const state = this.context as GlobalState
        const { organizationId } = this.context as GlobalState
        const showWarning =
            this.channelsQuery.isEmpty && this.requestsQuery.isEmpty
        return (
            <HasScope scope={[Scope.ReadChannels, Scope.RequestChannels]}>
                <ConstrainedBody maxWidth={1000}>
                    <PageCommandBar icon="WorkFlow" title="Channels">
                        {[
                            {
                                key: "Request",
                                text: "Request Channel",
                                iconProps: {
                                    iconName: "AddLink",
                                },
                                onClick: () =>
                                    history.push(
                                        `/${organizationId}/settings/channels/new`
                                    ),
                                disabled:
                                    !state.user?.Scope.can_request_channels,
                            },
                        ]}
                    </PageCommandBar>
                    <StyledChannelsList
                        className={isExact ? "full-width" : "split"}>
                        <FlexColumn className="list">
                            {showWarning &&
                                state.user?.Scope.can_read_channels && (
                                    <MessageBar>
                                        You don't have any channels in your
                                        organization.
                                        <Link
                                            to={`/${organizationId}/settings/channels/new`}>
                                            Request a channel
                                        </Link>{" "}
                                        to start communicating.
                                    </MessageBar>
                                )}
                            {state.user?.Scope.can_read_channels &&
                                this.channelsQuery.map((channel) => {
                                    const label = channelLabels.get(
                                        channel.Type
                                    )
                                    return (
                                        <ListItem
                                            key={channel.doc.id}
                                            to={`/${organizationId}/settings/channels/c/${channel.doc.id}`}
                                            text={
                                                <Flex align="center">
                                                    {label?.label}
                                                    <span className="secondary">
                                                        {channel.ShortId}
                                                    </span>
                                                </Flex>
                                            }
                                            icon={label?.icon}
                                            description={channel.Description}
                                            footer={
                                                <Flex>
                                                    <strong>
                                                        Created:&nbsp;
                                                    </strong>
                                                    <TimeAgo
                                                        date={channel.Created}
                                                    />
                                                </Flex>
                                            }
                                        />
                                    )
                                })}
                            {Boolean(
                                !this.requestsQuery.isEmpty &&
                                    !this.channelsQuery.isEmpty
                            ) && <Break />}
                            {!this.requestsQuery.isEmpty &&
                                state.user?.Scope.can_request_channels && (
                                    <SubTitle>Requested Channels</SubTitle>
                                )}
                            {state.user?.Scope.can_request_channels &&
                                this.requestsQuery.map((request) => {
                                    const label = channelLabels.get(
                                        request.Type
                                    )
                                    return (
                                        <ListItem
                                            key={request.doc.id}
                                            to={`/${organizationId}/settings/channels/r/${request.doc.id}`}
                                            text={label?.label}
                                            description={
                                                <Flex align="center">
                                                    <strong>
                                                        Status:&nbsp;
                                                    </strong>
                                                    <span className="secondary">
                                                        {request.Status}
                                                    </span>
                                                </Flex>
                                            }
                                            icon={label?.icon}
                                            footer={
                                                <Flex>
                                                    <strong>
                                                        Requested:&nbsp;
                                                    </strong>
                                                    <TimeAgo
                                                        date={request.Created}
                                                    />
                                                </Flex>
                                            }
                                        />
                                    )
                                })}
                        </FlexColumn>
                        <Route
                            path={`/${organizationId}/settings/channels/new`}
                            component={RequestChannel}
                        />
                        <Route
                            path={`/${organizationId}/settings/channels/c/:channelId`}
                            component={ViewChannel}
                        />
                        <Route
                            path={`/${organizationId}/settings/channels/r/:requestId`}
                            component={ViewRequest}
                        />
                    </StyledChannelsList>
                </ConstrainedBody>
            </HasScope>
        )
    }
}

const StyledChannelsList = styled.div`
    padding: 0.25em;
    display: flex;
    &.full-width {
        .list {
            width: 100%;
        }
    }
    &.split {
        .list {
            width: 40%;
            margin-right: 0.25em;
        }
    }
`
