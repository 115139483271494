import React from "react"
import styled from "styled-components"
import { RouteComponentProps } from "react-router-dom"
import { observer } from "mobx-react"
import {
    TextField,
    PrimaryButton,
    MessageBar,
    MessageBarType,
} from "@fluentui/react"
import {
    ButtonsHolder,
    FormItem,
    Title,
    ConstrainedBody,
} from "components/shared"
import { Organization, OrganizationUser } from "data/organization"
import { GlobalContext, GlobalState } from "data/state"
import { uniqueId } from "utils"
import { Scope } from "data/scope"

interface CreateOrgState {
    error: null | string
}

@observer
export class CreateOrganization extends React.Component<
    RouteComponentProps,
    CreateOrgState
> {
    static contextType = GlobalContext
    constructor(props: RouteComponentProps) {
        super(props)
        this.state = {
            error: null,
        }
    }
    create(evt: React.FormEvent<HTMLFormElement>) {
        evt.preventDefault()
        const state: GlobalState = this.context
        if (state.authorized === null) return
        this.setState({ error: null })
        // Create organization.
        const orgId = uniqueId()
        const organization = new Organization(orgId)
        const stopLoading = state.promiseLoadingHelper()
        organization.Name = evt.currentTarget.Name.value
        organization.Email = evt.currentTarget.email.value
        organization.Phone = evt.currentTarget.phone.value
        organization.Description = evt.currentTarget.description.value
        organization
            .update()
            .then(() => {
                const organizationUser = new OrganizationUser(uniqueId())
                organizationUser.UserId = state.authorized?.doc.id || ""
                organizationUser.OrganizationId = orgId
                organizationUser.Scope = new Scope([Scope.OrganizationAdmin])
                organizationUser
                    .update()
                    .then(() => {
                        stopLoading()
                        this.props.history.push(`/${orgId}`)
                    })
                    .catch((err) => {
                        this.setState({ error: err.message }, stopLoading)
                    })
            })
            .catch((err) => {
                this.setState({ error: err.message }, stopLoading)
            })
    }
    render() {
        const { error } = this.state
        return (
            <ConstrainedBody maxWidth={800}>
                <StyledForm onSubmit={this.create.bind(this)}>
                    <FormItem style={{ marginTop: 32 }}>
                        <Title>Create Organization</Title>
                    </FormItem>
                    <FormItem>
                        <TextField
                            name="Name"
                            required
                            label="Organization Name"
                            placeholder="Name..."
                        />
                    </FormItem>
                    <FormItem>
                        <TextField
                            name="email"
                            required
                            type="email"
                            label="Contact Email"
                            placeholder="juliusdoe@domain.com"
                        />
                    </FormItem>
                    <FormItem>
                        <TextField
                            name="phone"
                            required
                            type="tel"
                            label="Contact Phone"
                            placeholder="+(n) 0 123 456 689"
                        />
                    </FormItem>
                    <FormItem>
                        <TextField
                            name="description"
                            required
                            label="Description"
                            multiline
                            placeholder="Description of your organization..."
                        />
                    </FormItem>
                    {Boolean(error) && (
                        <FormItem>
                            <MessageBar messageBarType={MessageBarType.error}>
                                {error}
                            </MessageBar>
                        </FormItem>
                    )}
                    <ButtonsHolder style={{ justifyContent: "flex-end" }}>
                        <PrimaryButton
                            type="submit"
                            text="Create Organization"
                        />
                    </ButtonsHolder>
                </StyledForm>
            </ConstrainedBody>
        )
    }
}

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
`
