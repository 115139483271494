import React from "react"
import styled from "styled-components"
import { Link } from "react-router-dom"
import { observer } from "mobx-react"
import {
    Icon,
    TextField,
    IDropdownOption,
    Dropdown,
    IconButton,
} from "@fluentui/react"
import { Break, Flex, SmallTitle, MessageBar } from "components/shared"
import { Contact } from "data/contacts"
import { GlobalContext, GlobalState } from "data/state"

interface AttributeEditorProps {
    contact: Contact
    readonly?: boolean
}

@observer
export class AttributeEditor extends React.Component<AttributeEditorProps> {
    static contextType = GlobalContext
    newAttributesList(): IDropdownOption[] {
        const { contact } = this.props
        const { organization } = this.context as GlobalState
        if (
            organization === null ||
            organization.AttributesSchema === undefined
        )
            return []
        let options: IDropdownOption[] = [
            {
                key: "__place_holder__",
                text: "Select new attribute",
                disabled: true,
            },
        ]
        Object.keys(organization.AttributesSchema).forEach((key) => {
            if (organization.AttributesSchema === undefined) return
            const attr = organization.AttributesSchema[key]
            const attr_key = `${attr.type}:${key}`
            if (!(attr_key in contact.Attributes)) {
                options.push({
                    key: attr_key,
                    text: key,
                })
            }
        })
        return options
    }

    onSelectAttribute(evt: any, option?: IDropdownOption) {
        if (option === undefined) return
        const { contact } = this.props
        if (option.key === "__place_holder__") return
        let value: any = ""
        const data_type = `${option.key}`.split(":")[0]
        switch (data_type) {
            case "DATE":
                value = new Date().getTime()
                break
            case "NUMBER":
                value = 0
        }
        contact.Attributes[option.key] = {
            Value: value,
            Added: new Date().getTime(),
            Updated: new Date().getTime(),
        }
    }
    render() {
        const { readonly, contact } = this.props
        const { organization, user } = this.context as GlobalState
        if (organization === null || user === null) return null
        if (
            organization.AttributesSchema === undefined ||
            Object.keys(organization.AttributesSchema).length === 0
        ) {
            return (
                <MessageBar>
                    You don't have attributes in your organization. If you want
                    to add attributes to this contact, go{" "}
                    <Link to={`/${organization.doc.id}/attributes`}>here</Link>{" "}
                    to setup.
                </MessageBar>
            )
        }
        const options = this.newAttributesList()
        return (
            <StyledEditor>
                <SmallTitle>Attributes</SmallTitle>
                <Break />
                {Object.keys(contact.Attributes).map((key) => {
                    let [type, attr_name] = key.split(":")
                    if (organization.AttributesSchema === undefined) return null
                    const attr = organization.AttributesSchema[attr_name]
                    if (attr === undefined) return null
                    if (attr.type !== type) return null
                    const { Value } = contact.Attributes[key]
                    return (
                        <AttributeItem key={key}>
                            <Flex className="key" align="center">
                                <Icon
                                    iconName={
                                        attr.type === "DATE"
                                            ? "DateTime"
                                            : attr.type === "NUMBER"
                                            ? "NumberField"
                                            : "TextField"
                                    }
                                    className="icon"
                                />
                                <span>{attr_name}</span>
                            </Flex>
                            <Flex className="value">
                                <AttributeInput
                                    type={attr.type}
                                    value={Value}
                                    onChange={(val) => {
                                        if (
                                            organization.AttributesSchema ===
                                            undefined
                                        )
                                            return null
                                        contact.Attributes[key].Value = val
                                        contact.Attributes[key].Updated =
                                            new Date().getTime()
                                    }}
                                    disabled={!user.Scope.can_write_contacts}
                                />
                                {user.Scope.can_write_contacts && (
                                    <IconButton
                                        className="iconButton delete"
                                        title="Double Click to delete"
                                        iconProps={{ iconName: "Delete" }}
                                        onDoubleClick={() => {
                                            delete contact.Attributes[key]
                                        }}
                                    />
                                )}
                            </Flex>
                        </AttributeItem>
                    )
                })}
                {Boolean(
                    options.length > 1 &&
                        !readonly &&
                        user.Scope.can_write_contacts &&
                        user.Scope.can_read_attributes
                ) && (
                    <AttributeItem>
                        <Flex className="key">
                            <Icon iconName="AddTo" className="icon" />
                            <span>Add Attribute</span>
                        </Flex>
                        <Flex className="value" align="center">
                            <Dropdown
                                selectedKey="__place_holder__"
                                options={options}
                                onChange={this.onSelectAttribute.bind(this)}
                            />
                        </Flex>
                    </AttributeItem>
                )}
            </StyledEditor>
        )
    }
}

const StyledEditor = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0.5em;
`

const AttributeItem = styled(Flex)`
    .key {
        padding: 0.5em;
        background-color: ${(p) => p.theme.colors.backgroundDark};
        width: 40%;
    }
    .value {
        padding: 0.5em;
        background-color: ${(p) => p.theme.colors.backgroundDark}77;
        width: 60%;
        .ms-Dropdown-container {
            flex-grow: 1;
        }
        .ms-TextField {
            flex-grow: 1;
        }
    }
    .iconButton {
        margin: 0 0.25em;
        border-radius: 50%;
        font-size: 1.2em;
        &.delete:hover {
            color: #d50000;
        }
    }
    .icon {
        margin: 0 0.25em;
        color: ${(p) => p.theme.colors.primaryDark};
        font-size: 1.2em;
    }
    margin: 0.5em 0;
    border-radius: 0.5em;
    width: 100%;
`

function AttributeInput({
    type,
    value,
    onChange,
    disabled,
}: {
    value: any
    onChange?: (value: any) => void
    type: "DATE" | "NUMBER" | "TEXT"
    disabled?: boolean
}) {
    return (
        <Flex style={{ flexGrow: 1 }}>
            {type === "DATE" && (
                <TextField
                    type="datetime-local"
                    value={new Date(value).toJSON().substring(0, 23)}
                    onChange={(evt, val) => {
                        if (val === undefined || onChange === undefined) return
                        const value = new Date(val).getTime()
                        if (!isNaN(value)) {
                            onChange(value)
                        }
                    }}
                    disabled={disabled}
                />
            )}
            {type === "NUMBER" && (
                <TextField
                    type="number"
                    value={value}
                    onChange={(evt, val) => {
                        if (val === undefined || onChange === undefined) return
                        try {
                            const value = parseFloat(val)
                            if (!isNaN(value)) {
                                onChange(parseFloat(val))
                            }
                        } catch (Exception) {}
                    }}
                    disabled={disabled}
                />
            )}
            {type === "TEXT" && (
                <TextField
                    type="text"
                    value={value}
                    onChange={(evt, val) => {
                        if (val === undefined || onChange === undefined) return
                        onChange(val)
                    }}
                    disabled={disabled}
                />
            )}
        </Flex>
    )
}
