export function make_url(path: string): string {
    if (window.location.hostname.search("localhost") === 0) {
        return `http://localhost:8080${path}`
    }
    return `https://api.alt4.dev${path}`
}

export class ApiError extends Error {
    response: Response
    constructor(response: Response) {
        super(response.statusText)
        this.response = response
    }
}

export function api_error_handler(err: ApiError) {
    if (err.response) {
        err.response.json().then((resp) => {
            if (resp.message) {
                console.log(resp.message)
            } else {
                console.log("Error reaching processing request. Please retry")
            }
        })
    } else {
        console.log("Error reaching the server. Please check your connection")
    }
}

export interface _DefaultAPIResponse {
    message: string
}
