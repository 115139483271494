import { observable, autorun, computed } from "mobx"
import firebase from "firebase/app"
import { GetDate } from "utils"
import { Scope } from "data/scope"

type Cast = "json" | "date" | "value" | "scope"

interface LiveDataConstructor {
    Collection: () => firebase.firestore.CollectionReference
    Fields: string[]
}

interface UndoItem {
    key: string
    value: any
    cast: Cast
}

export class LiveData {
    @observable loading: boolean = false
    @observable exists: boolean = false
    private dbData: { [field: string]: string } = {}
    private changeData: { [field: string]: string } = {}
    @observable changes: string[] = []
    @observable undoPosition: number = -1
    @observable undoItems: UndoItem[] = []
    private doing: boolean = true

    static Collection: () => firebase.firestore.CollectionReference
    static Fields: string[] = []
    doc: firebase.firestore.DocumentReference
    private onLoad?: () => void

    static collection(
        name: string
    ): () => firebase.firestore.CollectionReference {
        return () => firebase.firestore().collection(name)
    }

    constructor(
        id: string,
        snapshot?: firebase.firestore.DocumentSnapshot,
        onLoad?: () => void
    ) {
        if (!this.cls.Collection) {
            throw new Error("Collection required on a livedata class.")
        }
        if (!this.cls.Fields) {
            throw new Error("Fields required on a livedata class.")
        }
        this.loading = !snapshot
        this.doc = this.cls.Collection().doc(id)
        this.onLoad = onLoad
        if (!snapshot) {
            this.doc.get().then((snapshot) => {
                this.exists = snapshot.exists
                if (snapshot.exists) {
                    this.processSnapshot(snapshot)
                }
                this.doneLoading()
            })
        } else {
            this.processSnapshot(snapshot)
            this.doneLoading()
        }
        this.watchForLocalChanges()
    }

    private doneLoading() {
        if (this.loading && this.onLoad) {
            this.onLoad()
        }
        this.loading = false
        this.doing = false
        this.doc.onSnapshot((snapshot) => {
            this.exists = snapshot.exists
            if (snapshot.exists) this.processSnapshot(snapshot)
        })
    }

    private processSnapshot(snapshot: firebase.firestore.DocumentSnapshot) {
        const data = snapshot.data()
        if (data === undefined) return
        this.cls.Fields.forEach((field) => {
            let content = data[field]
            if (content === undefined) return
            // Check if the content is a date
            if (getValue(this, field) instanceof Date) {
                content = GetDate(content)
            }
            if (getValue(this, field) instanceof Scope) {
                content = new Scope(content)
            }
            setValue(this, field, content)
        })
        this.updateDbData()
        this.changes = []
    }

    private updateDbData() {
        this.cls.Fields.forEach((field) => {
            this.dbData[field] = JSON.stringify(getValue(this, field))
        })
    }

    private watchForLocalChanges() {
        autorun(() => {
            const that = this as any
            this.cls.Fields.forEach((field: string) => {
                const value = JSON.stringify(that[field])
                const i = this.changes.indexOf(field)
                if (value !== this.dbData[field] && i < 0) {
                    this.changes.push(field)
                } else if (value === this.dbData[field] && i >= 0) {
                    this.changes.splice(i, 1)
                }
                const oldValue = this.changeData[field]
                const { hasChanged, newValue, cast } = compare(
                    oldValue,
                    that[field]
                )
                if (hasChanged) {
                    this.changeData[field] = newValue
                    if (!this.doing) {
                        this.addUndo(field, oldValue, cast)
                    }
                }
            })
        })
    }

    private get cls(): LiveDataConstructor {
        return this.constructor as any as LiveDataConstructor
    }

    data(): any {
        const data: any = {}
        this.cls.Fields.forEach((field) => {
            let content = getValue(this, field)
            if (content instanceof Date) {
                // Save dates in Nano seconds
                content = content.getTime() * 1000000
            }
            if (content instanceof Scope) {
                content = content.permissions
            }
            data[field] = content
        })
        return data
    }

    private addUndo(key: string, value: any, cast: Cast) {
        this.undoPosition += 1
        this.undoItems.splice(this.undoPosition)
        this.undoItems.push({ key, value, cast })
    }

    @computed get canUndo(): boolean {
        return (
            this.undoPosition >= 0 && this.undoPosition < this.undoItems.length
        )
    }

    @computed get canRedo(): boolean {
        return (
            this.undoItems.length > 0 &&
            this.undoPosition < this.undoItems.length - 1
        )
    }

    undo(): boolean {
        if (!this.canUndo) return false
        this.doing = true
        let { key, value, cast } = this.undoItems[this.undoPosition]
        if (cast === "json") value = JSON.parse(value)
        if (cast === "date") value = new Date(value)
        if (cast === "scope") value = new Scope(JSON.parse(value))
        setValue(this, key, value)
        this.undoPosition -= 1
        this.doing = false
        return true
    }

    redo(): boolean {
        if (!this.canRedo) return false
        this.doing = true
        this.undoPosition += 1
        let { key, value, cast } = this.undoItems[this.undoPosition]
        if (cast === "json") value = JSON.parse(value)
        if (cast === "date") value = new Date(value)
        if (cast === "scope") value = new Scope(JSON.parse(value))
        setValue(this, key, value)
        this.doing = false
        return true
    }

    update() {
        this.updateDbData()
        this.changes = []
        return this.doc.set(this.data())
    }
}

function setValue(object: any, key: string, value: any) {
    object[key] = value
}

function getValue(object: any, key: string): any {
    return object[key]
}

function compare(
    oldValue: any,
    newValue: any
): { hasChanged: boolean; newValue: any; cast: Cast } {
    let cast: Cast = "value"
    // Check the type of the new value
    if (newValue instanceof Date) {
        newValue = newValue.getTime()
        cast = "date"
    } else if (newValue instanceof Scope) {
        newValue = newValue.permissions
        cast = "scope"
    } else if (typeof newValue === "object") {
        newValue = JSON.stringify(newValue)
        cast = "json"
    }
    return { hasChanged: newValue !== oldValue, newValue, cast }
}

export class LiveQuery<T extends LiveData> {
    query: firebase.firestore.Query
    @observable results: T[] = []
    @computed get length(): number {
        return this.results.length
    }
    @computed get isEmpty(): boolean {
        return this.results.length === 0
    }
    unsubscribe?: firebase.Unsubscribe
    private __class: typeof LiveData
    constructor(ldClass: typeof LiveData, limit?: number) {
        this.query = ldClass.Collection().limit(limit ? limit : 100)
        this.__class = ldClass
    }

    where(field: string, opStr: firebase.firestore.WhereFilterOp, value: any) {
        if (!this.__class.Fields.includes(field)) {
            throw new Error(
                `Field '${field}' is not part of '${
                    this.__class.Collection().path
                }'`
            )
        }
        this.query = this.query.where(field, opStr, value)
    }

    orderBy(field: string, direction: firebase.firestore.OrderByDirection) {
        this.query = this.query.orderBy(field, direction)
    }

    itemAt(itemId: string): number {
        for (let i = 0; i < this.results.length; i++) {
            const item = this.results[i]
            if (itemId === item.doc.id) {
                return i
            }
        }
        return -1
    }

    private processChange(change: firebase.firestore.DocumentChange) {
        // Modifications are automatically handled by LiveData
        if (change.type === "modified") return
        const index = this.itemAt(change.doc.id)
        if (change.type === "added" && index < 0) {
            const obj = new this.__class(change.doc.id, change.doc)
            this.results.push(obj as T)
        }
        if (change.type === "removed" && index >= 0) {
            this.results.splice(index, 1)
        }
    }

    async run(): Promise<void> {
        const getResult = await this.query.get()
        getResult.docChanges().forEach(this.processChange.bind(this))

        this.unsubscribe = this.query.onSnapshot((snapshot) => {
            snapshot.docChanges().forEach(this.processChange.bind(this))
        })
    }

    map<U>(callbackfn: (value: T, index: number) => U) {
        return this.results.map<U>(callbackfn)
    }
}
