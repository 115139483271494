import React from "react"
import { observer } from "mobx-react"
import styled from "styled-components"
import {
    ConstrainedBody,
    FlexColumn,
    Flex,
    MessageBar,
    PageCommandBar,
    Title,
    Break,
    ListItem,
} from "components/shared"
import {
    DefaultButton,
    PrimaryButton,
    TextField,
    ChoiceGroup,
    IChoiceGroupOption,
    IconButton,
    Modal,
} from "@fluentui/react"
import { AttributeInterface } from "data/organization"
import { GlobalContext, GlobalState } from "data/state"
import TimeAgo from "react-timeago"
import { HasScope } from "./access"
import { Scope } from "data/scope"

interface AttributesState {
    editing: string | null
    valid: boolean
    key: string
    type: string
    description: string
}

@observer
export class Attributes extends React.Component<any, AttributesState> {
    static contextType = GlobalContext
    constructor(props: any) {
        super(props)
        this.state = {
            editing: null,
            valid: false,
            key: "",
            type: "TEXT",
            description: "",
        }
    }

    onSave() {
        const { key, type, description } = this.state
        const state: GlobalState = this.context
        const org = state.organization
        if (org === null) return
        if (org.AttributesSchema === undefined) org.AttributesSchema = {}
        let current = org.AttributesSchema[key] || {
            created: new Date().getTime(),
        }
        current.description = description
        current.type = type as "DATE" | "TEXT" | "NUMBER"
        org.AttributesSchema[key] = current
        org.update()
            .finally(state.promiseLoadingHelper())
            .then(() =>
                this.setState({
                    key: "",
                    type: "TEXT",
                    description: "",
                    editing: null,
                })
            )
    }

    render() {
        const { editing, key, type, description } = this.state
        const state: GlobalState = this.context
        const org = state.organization
        if (org === null) return null
        let error_message = undefined
        if (key && !new RegExp("^[a-zA-Z]{1}[a-zA-Z0-9_]*$").test(key)) {
            error_message =
                "Invalid attribute name, an attribute can only have the letters A-Za-z or an underscore(_). It cannot begin with a number or an underscore"
        } else if (
            org.AttributesSchema !== undefined &&
            key in org.AttributesSchema &&
            !editing &&
            !state.loading
        ) {
            error_message = "An attribute with this field name already exists."
        }

        const choices: IChoiceGroupOption[] = [
            { key: "TEXT", text: "Text" },
            {
                key: "NUMBER",
                text: "Numbers",
            },
            {
                key: "DATE",
                text: "Date and Time",
            },
        ]

        let schema: { [attributeKey: string]: AttributeInterface } = {}
        if (org.AttributesSchema !== undefined) schema = org.AttributesSchema

        return (
            <HasScope scope={Scope.ReadAttributes}>
                <ConstrainedBody maxWidth={1000}>
                    <PageCommandBar title="Attributes">
                        {[
                            {
                                key: "add",
                                text: "Add Attribute",
                                iconProps: { iconName: "Add" },
                                ariaLabel: "Add Attribute",
                                renderedInOverflow: false,
                                onClick: () =>
                                    this.setState({
                                        editing: "",
                                        key: "",
                                        description: "",
                                        type: "TEXT",
                                    }),
                                disabled:
                                    !state.user?.Scope.can_write_attributes,
                            },
                        ]}
                    </PageCommandBar>

                    <EditModal
                        isOpen={
                            editing !== null &&
                            state.user?.Scope.can_write_attributes
                        }>
                        <div className="content">
                            <Title>New Attribute</Title>
                            <Break size={1.5} />
                            <TextField
                                label="Field Name"
                                value={key}
                                onChange={(evt, value) => {
                                    if (value !== undefined)
                                        this.setState({ key: value })
                                }}
                                errorMessage={error_message}
                                disabled={Boolean(editing)}
                            />
                            <Break />
                            <ChoiceGroup
                                label="Data Type"
                                selectedKey={type}
                                onChange={(evt, opt) => {
                                    if (opt !== undefined)
                                        this.setState({ type: opt.key })
                                }}
                                options={choices}
                                disabled={Boolean(editing)}
                            />
                            <Break />
                            <TextField
                                label="Description"
                                onChange={(evt, value) => {
                                    if (value !== undefined)
                                        this.setState({ description: value })
                                }}
                                value={description}
                                multiline
                            />
                            <Break size={2} />
                            <Flex justify="flex-end">
                                <DefaultButton
                                    text="Cancel"
                                    onClick={() =>
                                        this.setState({ editing: null })
                                    }
                                    style={{ marginRight: "1em" }}
                                />
                                <PrimaryButton
                                    text="Save"
                                    onClick={this.onSave.bind(this)}
                                    disabled={Boolean(error_message) || !key}
                                    split={Boolean(editing)}
                                    menuProps={
                                        Boolean(editing)
                                            ? {
                                                  items: [
                                                      {
                                                          key: "delete",
                                                          text: "Delete",
                                                          iconProps: {
                                                              iconName:
                                                                  "Delete",
                                                          },
                                                          onClick: () => {
                                                              if (
                                                                  org.AttributesSchema ===
                                                                  undefined
                                                              )
                                                                  return
                                                              delete org
                                                                  .AttributesSchema[
                                                                  key
                                                              ]
                                                              this.setState(
                                                                  {
                                                                      editing:
                                                                          null,
                                                                      key: "",
                                                                      description:
                                                                          "",
                                                                  },
                                                                  () =>
                                                                      org
                                                                          .update()
                                                                          .finally(
                                                                              state.promiseLoadingHelper()
                                                                          )
                                                              )
                                                          },
                                                      },
                                                  ],
                                              }
                                            : undefined
                                    }
                                />
                            </Flex>
                        </div>
                    </EditModal>
                    <FlexColumn>
                        {Object.keys(schema).length === 0 && (
                            <MessageBar>
                                Attributes allow you to set properties on a
                                contact to suit your needs. They can include
                                anything like name and age to complex data like
                                dates. Attributes can be used while searching
                                for contacts and helps you keep customized
                                records for each contact.
                            </MessageBar>
                        )}
                        {Object.keys(schema).map((key) => {
                            const content = schema[key]
                            const { description, type } = content
                            return (
                                <Attribute
                                    onEdit={() =>
                                        this.setState({
                                            editing: key,
                                            description,
                                            key,
                                            type,
                                        })
                                    }
                                    key={key}
                                    name={key}
                                    content={content}
                                    readonly={
                                        !state?.user?.Scope.can_write_attributes
                                    }
                                />
                            )
                        })}
                    </FlexColumn>
                </ConstrainedBody>
            </HasScope>
        )
    }
}

const EditModal = styled(Modal)`
    .content {
        min-width: 450px;
        border-top: 3px solid ${(props) => props.theme.colors.primaryDark} !important;
        padding: 1.5em 1em;
    }
`

function Attribute({
    name,
    content,
    onEdit,
    readonly,
}: {
    name: string
    content: AttributeInterface
    onEdit: () => void
    readonly: boolean
}) {
    let iconName =
        content.type === "DATE"
            ? "DateTime"
            : content.type === "NUMBER"
            ? "NumberField"
            : "TextField"
    return (
        <ListItem
            icon={iconName}
            text={
                <Flex>
                    {name}: <span className="secondary">{content.type}</span>
                </Flex>
            }
            description={content.description}
            footer={
                <TimeAgo className="date" date={new Date(content.created)} />
            }
            farRight={
                !readonly ? (
                    <IconButton
                        onClick={onEdit}
                        iconProps={{ iconName: "EditSolid12" }}
                    />
                ) : undefined
            }
        />
    )
}
