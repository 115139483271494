import { observable, computed } from "mobx"
import { LiveData } from "data/livedata"
import { GlobalState } from "./state"
import { ChannelType } from "./channel_types"

export type LogicType =
    | "END"
    | "OPTIONS"
    | "OPEN_TEXT"
    | "OPEN_NUMBER"
    | "ENUM"
    | "REDIRECT"
    | "CUSTOM"

export interface Logic {
    title: string
    displayText: string
    voiceFile: string | null
    type: LogicType
    next: Logic | null
    options: Logic[] | null
    enumOptions:
        | {
              label: string
              logic: null | Logic
          }[]
        | null
    customData: {
        code: string
        errorMessage: string
    } | null
    hideOptions?: boolean
    showHome: null | string
    showPrev: null | string
    redirect: null | string
    code?: {
        custom?: string
        before?: string
        after?: string
    }
}

export function CheckLogic(
    logic: Logic,
    state?: GlobalState
): { ok: boolean; message: string } {
    if (!logic.title) {
        return { ok: false, message: "Question title required" }
    }
    if (
        !logic.displayText &&
        logic.type !== "REDIRECT" &&
        logic.type !== "CUSTOM"
    ) {
        return { ok: false, message: "Question text required" }
    }
    if (logic.type === "CUSTOM") {
        if (!logic.code?.custom) {
            return {
                ok: false,
                message:
                    "A custom-code question must provide the code to run when the question is reached.",
            }
        }
    }
    if (logic.type === "OPTIONS" && !logic.options?.length) {
        return {
            ok: false,
            message: "A multi-choice question must have at least one option",
        }
    }
    if (logic.type === "ENUM") {
        if (logic.enumOptions === null || !logic.enumOptions.length) {
            return {
                ok: false,
                message:
                    "An only accept question must provide at least one option",
            }
        }
        for (let i = 0; i < logic.enumOptions.length; i++) {
            const opt = logic.enumOptions[i].label
            if (!opt) {
                return {
                    ok: false,
                    message: "An option cannot be blank",
                }
            }
        }
    }
    if (logic.type === "REDIRECT") {
        if (!logic.redirect) {
            return {
                ok: false,
                message: "Redirect must point to a flow.",
            }
        }
        if (state !== undefined) {
            const flow = state?.flows[logic.redirect]
            if (!flow) {
                return {
                    ok: false,
                    message: "Flow redirected to doesn't exist",
                }
            }
        }
    }
    return { ok: true, message: "Ok" }
}

export class Flow extends LiveData {
    static Collection = LiveData.collection("Flows")
    static Fields = [
        "Name",
        "Logic",
        "Channels",
        "OrganizationId",
        "Description",
        "Created",
        "Updated",
    ]
    @observable Name: string = "Unnamed Flow"
    @observable Description: string = ""
    @observable Logic: Logic = NewLogic()
    @observable Channels: ChannelType[] = []
    @observable OrganizationId: string = ""
    @observable Created: Date = new Date()
    @observable Updated: Date = new Date()
    @observable errorMessage: string | null = null

    @computed get isValid(): string | null {
        function check(logic: Logic): boolean {
            const { ok } = CheckLogic(logic)
            if (!ok) return false
            if (logic.next !== null) {
                if (!check(logic.next)) return false
            }
            if (logic.options !== null) {
                for (let i = 0; i < logic.options.length; i++) {
                    if (!check(logic.options[i])) return false
                }
            }
            if (logic.enumOptions !== null) {
                for (let i = 0; i < logic.enumOptions.length; i++) {
                    const enumLogic = logic.enumOptions[i].logic
                    if (enumLogic !== null) {
                        if (!check(enumLogic)) return false
                    }
                }
            }

            return true
        }
        if (!this.Name) {
            return "Flow name is required."
        }
        if (!this.Channels.length) {
            return "You must select at least one channel."
        }
        if (!check(this.Logic)) {
            return "Your flow has invalid questions, fix them to continue."
        }
        return null
    }
    getLogic(path: string): Logic | null {
        if (!path) return this.Logic
        const parts = path.split("/")
        let logic: Logic | null = this.Logic
        for (let i = 0; i < parts.length; i++) {
            if (logic === null) break
            const step = parts[i]
            if (step === "next") {
                logic = logic.next
                continue
            }

            if (step === "options") {
                if (logic.options === null) {
                    logic = null
                    break
                }
                i++
                logic = logic.options[parseInt(parts[i])]
            }
            if (step === "enumOptions") {
                if (logic.enumOptions === null) {
                    logic = null
                    break
                }
                i++
                logic = logic.enumOptions[parseInt(parts[i])].logic
            }
        }
        return logic
    }
}

export class FlowLogic extends LiveData {
    static Collection = LiveData.collection("FlowLogic")
    static Fields = ["Flow", "Commit", "ChangedBy", "Logic", "Date"]
    @observable Flow: string = ""
    @observable Commit: string = ""
    @observable ChangedBy: string = ""
    @observable Logic: Logic | null = null
    @observable Date: Date = new Date()
}

export function NewLogic(): Logic {
    return {
        title: "",
        displayText: "",
        voiceFile: null,
        type: "END",
        next: null,
        options: null,
        enumOptions: null,
        customData: null,
        showHome: null,
        showPrev: null,
        redirect: null,
        code: {
            custom: "",
            before: "",
            after: "",
        },
    }
}

export const TypeData: { [key: string]: { icon: string; label: string } } = {
    END: { icon: "CircleStopSolid", label: "End" },
    OPTIONS: { icon: "BulletedList2", label: "Multi Choice" },
    OPEN_TEXT: { icon: "TextField", label: "Text Response" },
    OPEN_NUMBER: { icon: "NumberField", label: "Number Response" },
    ENUM: { icon: "NumberedList", label: "Only Accept" },
    REDIRECT: { icon: "Link", label: "Jump to flow" },
    CUSTOM: { icon: "Code", label: "Run custom code" },
}
