import React from "react"
import styled from "styled-components"
import { observer } from "mobx-react"
import { RouteComponentProps } from "react-router-dom"
import { closePaymentModal, FlutterWaveButton } from "flutterwave-react-v3"
import { HasScope } from "components/access"
import {
    Break,
    ConstrainedBody,
    Flex,
    FlexColumn,
    PageCommandBar,
} from "components/shared"
import { Scope } from "data/scope"
import { GlobalContext, GlobalState } from "data/state"
import { uniqueId } from "utils"
import {
    ChoiceGroup,
    IChoiceGroupOption,
    PrimaryButton,
    TextField,
} from "@fluentui/react"

interface BillingState {
    amount: number
    currency: "KES" | "USD"
    balance: string
    tx_ref: string
}

@observer
export class Billing extends React.Component<
    RouteComponentProps,
    BillingState
> {
    static contextType = GlobalContext
    timer: any = null
    constructor(props: RouteComponentProps) {
        super(props)
        this.state = {
            amount: 0,
            currency: "USD",
            balance: "0.00",
            tx_ref: uniqueId(),
        }
    }
    componentDidMount() {
        this.refreshBalance(true)
        this.timer = setInterval(this.refreshBalance.bind(this), 60000)
    }

    componentWillUnmount() {
        clearInterval(this.timer)
    }

    refreshBalance(showLoading?: boolean) {
        const state: GlobalState = this.context
        interface BillingResponse {
            Organization: string
            Balance: string
            NewBalance: string
        }
        const promise = state
            .fetch<BillingResponse>(
                `https://billing-gt6gcc6koa-uc.a.run.app/api/v1/balance/${state.organizationId}`
            )
            .then((resp) => {
                this.setState({
                    balance: parseFloat(resp.Balance).toFixed(2),
                    tx_ref: uniqueId(),
                })
            })
            .catch((err) => {
                console.error(err)
                this.setState({ tx_ref: uniqueId() })
            })
        if (showLoading) {
            promise.finally(state.promiseLoadingHelper())
        }
    }

    render() {
        const { amount, currency, balance, tx_ref } = this.state
        const state: GlobalState = this.context
        const flt_config = {
            public_key: "FLWPUBK-97ad68e8be83539bbf12b0c0d3acc134-X",
            tx_ref: `${state.organizationId}:${tx_ref}`,
            amount,
            currency,
            payment_options: "card,mobilemoney",
            customer: {
                email: `${state.authorized?.Emails[0]}`,
                name: `${state.authorized?.DisplayName}`,
                phonenumber: "",
                organization: state.organizationId,
            },
            customizations: {
                title: `Load for ${state.organization?.Name}`,
                description: `Load balance for ${state.organization?.Name}`,
                logo: "https://web.zohari.tech/banner-large.png",
            },
        }

        return (
            <HasScope scope={Scope.OrganizationAdmin}>
                <ConstrainedBody maxWidth={1000}>
                    <PageCommandBar icon="PlayerSettings" title="Billing" />
                    <Flex>
                        <BodyPart>
                            <PageCommandBar title="Balance">
                                {[
                                    {
                                        key: "refresh",
                                        iconOnly: true,
                                        iconProps: {
                                            iconName: "Refresh",
                                        },
                                        title: "Refresh",
                                        onClick: () =>
                                            this.refreshBalance(true),
                                    },
                                ]}
                            </PageCommandBar>
                            <BalanceView>
                                <span className="currency">USD</span>
                                <span className="amount">{balance}</span>
                            </BalanceView>
                        </BodyPart>
                        <BodyPart>
                            <PageCommandBar
                                style={{ borderRadius: 5 }}
                                title="Load account"
                            />
                            <Break />
                            <FlexColumn>
                                <ChoiceGroup
                                    selectedKey={currency}
                                    options={currency_options}
                                    label="Currency"
                                    onChange={(ev, opt) => {
                                        if (
                                            opt?.key === "KES" ||
                                            opt?.key === "USD"
                                        ) {
                                            this.setState({ currency: opt.key })
                                        }
                                    }}
                                />
                                <TextField
                                    label="Amount"
                                    type="Number"
                                    value={`${amount}`}
                                    onChange={(ev, val) => {
                                        if (val !== undefined) {
                                            this.setState({
                                                amount: parseFloat(val),
                                            })
                                        }
                                    }}
                                />
                            </FlexColumn>
                            <Break />
                            <Flex justify={"flex-end"}>
                                {amount > 0 && (
                                    <FlutterWaveButton
                                        {...flt_config}
                                        text="Load account"
                                        callback={(resp) => {
                                            console.log(resp)
                                            closePaymentModal()
                                        }}
                                        onClose={() => {}}
                                        className="ms-Button ms-Button--primary root-116"
                                    />
                                )}
                                {Boolean(isNaN(amount) || amount === 0) && (
                                    <PrimaryButton disabled>
                                        Load account
                                    </PrimaryButton>
                                )}
                            </Flex>
                            <Break />
                        </BodyPart>
                    </Flex>
                </ConstrainedBody>
            </HasScope>
        )
    }
}

const BodyPart = styled.div`
    flex-grow: 1;
    flex-basis: 0;
    margin: 5px;
    background-color: ${(p) => p.theme.colors.backgroundDark};
    padding: 0.5em;
    border-radius: 1em;
`

const currency_options: IChoiceGroupOption[] = [
    // { key: "KES", text: "Kenya Shillings" },
    { key: "USD", text: "US Dollars" },
]

const BalanceView = styled.div`
    display: flex;
    font-size: 4em;
    margin-top: 0.5em;
    .currency {
        margin-right: 0.5em;
    }
`
