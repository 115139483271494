import React from "react"
import { observer } from "mobx-react"
import { RouteComponentProps } from "react-router-dom"
import {
    FlexColumn,
    DataPoint,
    Flex,
    Break,
    PageCommandBar,
    Loading,
} from "components/shared"
import { PermissionPicker } from "./permission_picker"
import { OrganizationUser } from "data/organization"
import { GlobalContext, GlobalState } from "data/state"
import Timeago from "react-timeago"
import { PrimaryButton } from "@fluentui/react"
import { NotFound } from "components/notfound"

interface ViewUserProps extends RouteComponentProps {
    match: {
        isExact: boolean
        params: {
            userId: string
        }
        path: string
        url: string
    }
}

interface ViewUserState {
    user?: OrganizationUser
}

@observer
export class ViewUser extends React.Component<ViewUserProps, ViewUserState> {
    static contextType = GlobalContext
    constructor(props: ViewUserProps) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
        this.loadUser()
    }
    loadUser() {
        const state: GlobalState = this.context
        const { userId } = this.props.match.params
        const user = new OrganizationUser(
            userId,
            undefined,
            state.promiseLoadingHelper()
        )
        this.setState({ user })
    }
    componentDidUpdate(prevProps: ViewUserProps) {
        if (prevProps.match.params.userId !== this.props.match.params.userId) {
            this.loadUser()
        }
    }

    render() {
        const { user } = this.state
        const state: GlobalState = this.context
        if (user === undefined) return null

        if (user.loading) {
            return <Loading>User invite...</Loading>
        }

        if (!user.exists) {
            return (
                <NotFound>
                    User <kbd>{user.doc.id}</kbd> not found in this organization
                </NotFound>
            )
        }

        return (
            <FlexColumn>
                <PageCommandBar
                    icon="Contact"
                    title={user.firebaseUser.DisplayName}
                    top={100}
                />
                <Flex>
                    <DataPoint name={"UserId"}>{user.doc.id}</DataPoint>

                    <DataPoint name={"Email"}>
                        {user.firebaseUser.Emails[0]}
                    </DataPoint>
                </Flex>
                <Flex>
                    <DataPoint name={"Added"}>
                        <Timeago date={user.firebaseUser.Created} />
                    </DataPoint>

                    <DataPoint name={"Last Seen"}>
                        <Timeago date={user.firebaseUser.LastSeen} />
                    </DataPoint>
                </Flex>
                <Break />
                <PermissionPicker
                    readonly={
                        state.authorized?.doc.id === user.firebaseUser.doc.id ||
                        !state.user?.Scope.is_admin
                    }
                    title="Permissions"
                    scope={user.Scope}
                />
                <Break />
                {user.changes.length > 0 && (
                    <Flex justify="flex-end">
                        <PrimaryButton
                            disabled={user.Scope.permissions.length === 0}
                            onClick={() =>
                                user
                                    .update()
                                    .finally(state.promiseLoadingHelper())
                            }
                            text="Save"
                        />
                    </Flex>
                )}
                <Break size={3} />
            </FlexColumn>
        )
    }
}
