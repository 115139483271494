import { observable, computed } from "mobx"

export type ScopeValue =
    | "admin"
    | "read/users"
    | "channels/write"
    | "channels/read"
    | "channels/request"
    | "attributes/write"
    | "attributes/read"
    | "contacts/write"
    | "contacts/read"
    | "flows/create"
    | "flows/edit"
    | "flows/read"
    | "sessions/read"

export function ScopeLabel(scope: ScopeValue | ScopeValue[], sep?: string) {
    if (!Array.isArray(scope)) {
        scope = [scope]
    }
    let scopeLabels = scope.map((sc) => {
        let parts = sc
            .split("/")
            .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
        parts.reverse()
        return parts.join(" ")
    })
    return scopeLabels.join(sep)
}

export class Scope {
    // Administration
    static OrganizationAdmin: ScopeValue = "admin"
    static ReadUsers: ScopeValue = "read/users"
    static WriteChannels: ScopeValue = "channels/write"
    static ReadChannels: ScopeValue = "channels/read"
    static RequestChannels: ScopeValue = "channels/request"
    static WriteAttributes: ScopeValue = "attributes/write"
    static ReadAttributes: ScopeValue = "attributes/read"
    static WriteContacts: ScopeValue = "contacts/write"
    static ReadContacts: ScopeValue = "contacts/read"
    static CreateFlows: ScopeValue = "flows/create"
    static EditFlows: ScopeValue = "flows/edit"
    static ReadFlows: ScopeValue = "flows/read"
    static ReadSessions: ScopeValue = "sessions/read"
    @observable permissions: ScopeValue[] = []

    constructor(permissions: ScopeValue[]) {
        this.permissions = permissions
    }

    @computed get allPermissions(): ScopeValue[] {
        const permissions: ScopeValue[] = []
        if (this.is_admin) permissions.push(Scope.OrganizationAdmin)
        if (this.can_read_users) permissions.push(Scope.ReadUsers)
        if (this.can_read_contacts) permissions.push(Scope.ReadContacts)
        if (this.can_write_contacts) permissions.push(Scope.WriteContacts)
        if (this.can_read_attributes) permissions.push(Scope.ReadAttributes)
        if (this.can_write_attributes) permissions.push(Scope.WriteAttributes)
        if (this.can_read_channels) permissions.push(Scope.ReadChannels)
        if (this.can_write_channels) permissions.push(Scope.WriteChannels)
        if (this.can_request_channels) permissions.push(Scope.RequestChannels)
        if (this.can_create_flows) permissions.push(Scope.CreateFlows)
        if (this.can_edit_flows) permissions.push(Scope.EditFlows)
        if (this.can_read_flows) permissions.push(Scope.ReadFlows)
        if (this.can_read_sessions) permissions.push(Scope.ReadSessions)
        return permissions
    }

    hasScope(scope: ScopeValue | ScopeValue[]) {
        if (!Array.isArray(scope)) {
            scope = [scope]
        }
        for (let i = 0; i < scope.length; i++) {
            if (!this.allPermissions.includes(scope[i])) {
                return false
            }
        }
        return true
    }

    toggleScope(permission: ScopeValue) {
        const permissions = this.permissions
        if (permissions.includes(permission)) {
            permissions.splice(permissions.indexOf(permission), 1)
        } else {
            permissions.push(permission)
        }
        this.permissions = permissions
    }

    @computed get is_admin() {
        return this.permissions.includes(Scope.OrganizationAdmin)
    }

    @computed get can_read_users() {
        return this.is_admin || this.permissions.includes(Scope.ReadUsers)
    }

    @computed get can_write_channels() {
        return this.is_admin || this.permissions.includes(Scope.WriteChannels)
    }

    @computed get can_read_channels() {
        return (
            this.can_write_channels ||
            this.permissions.includes(Scope.ReadChannels)
        )
    }

    @computed get can_request_channels() {
        return this.is_admin || this.permissions.includes(Scope.RequestChannels)
    }

    @computed get can_write_attributes() {
        return this.is_admin || this.permissions.includes(Scope.WriteAttributes)
    }

    @computed get can_read_attributes() {
        return (
            this.can_write_attributes ||
            this.permissions.includes(Scope.ReadAttributes)
        )
    }

    @computed get can_write_contacts() {
        return this.is_admin || this.permissions.includes(Scope.WriteContacts)
    }

    @computed get can_read_contacts() {
        return (
            this.can_write_contacts ||
            this.permissions.includes(Scope.ReadContacts)
        )
    }

    @computed get can_create_flows() {
        return this.is_admin || this.permissions.includes(Scope.CreateFlows)
    }

    @computed get can_edit_flows() {
        return (
            this.can_create_flows || this.permissions.includes(Scope.EditFlows)
        )
    }

    @computed get can_read_flows() {
        return this.can_edit_flows || this.permissions.includes(Scope.ReadFlows)
    }

    @computed get can_read_sessions() {
        return this.is_admin || this.permissions.includes(Scope.ReadSessions)
    }
}
