import React from "react"
import styled from "styled-components"
import { observer } from "mobx-react"
import { observable } from "mobx"
import { Contact } from "data/contacts"
import {
    ConstrainedBody,
    PageCommandBar,
    FlexColumn,
    Flex,
    MessageBar,
    Title,
    Break,
    ListItem,
} from "components/shared"
import { ContactView } from "./contact"
import { RouteComponentProps, Route } from "react-router-dom"
import { GlobalContext, GlobalState } from "data/state"
import TimeAgo from "react-timeago"
import { DefaultButton, PrimaryButton, TextField, Modal } from "@fluentui/react"
import { uniqueId } from "utils"
import { AttributeEditor } from "./attributes"
import phone from "phone"
import { LiveQuery } from "data/livedata"
import { HasScope } from "components/access"
import { Scope } from "data/scope"

interface ContactListProps extends RouteComponentProps {
    match: {
        isExact: boolean
        params: {
            query?: string
        }
        path: string
        url: string
    }
}

@observer
export class ContactsList extends React.Component<ContactListProps> {
    static contextType = GlobalContext
    query = new LiveQuery<Contact>(Contact)
    @observable contact: Contact | null = null
    componentDidMount() {
        // Get contacts.
        const state: GlobalState = this.context
        this.query.where("Organization", "==", state.organizationId)
        this.query.orderBy("LastAccess", "desc")
        this.query.run().finally(state.promiseLoadingHelper())
    }

    saveContact() {
        const state: GlobalState = this.context
        if (this.contact === null || state.organizationId === null) return
        this.contact.Organization = state.organizationId
        if (!this.contact.validatePhone()) {
            return
        }
        this.contact
            .update()
            .then(() => {
                this.contact = null
            })
            .finally(state.promiseLoadingHelper())
    }
    render() {
        const {
            match: {
                isExact,
                params: { query },
            },
        } = this.props
        let errorMessage: string | undefined = undefined
        if (this.contact !== null && this.contact.PhoneNumber) {
            const [valid_phone] = phone(this.contact.PhoneNumber)
            if (valid_phone === undefined) {
                errorMessage =
                    "Invalid phone number. Expected format +254 XXX XXX XXX"
            }
        }
        const state: GlobalState = this.context
        return (
            <HasScope scope={Scope.ReadContacts}>
                <ConstrainedBody maxWidth={1200}>
                    <PageCommandBar icon="ContactList" title="Contacts">
                        {[
                            {
                                key: "add",
                                text: "Add Contact",
                                iconProps: { iconName: "Add" },
                                ariaLabel: "Add Contact",
                                renderedInOverflow: false,
                                onClick: () => {
                                    this.contact = new Contact(uniqueId())
                                },
                                disabled: !state.user?.Scope.can_write_contacts,
                            },
                        ]}
                    </PageCommandBar>
                    {this.contact !== null &&
                        state.user?.Scope.can_write_contacts && (
                            <NewContactModal isOpen={true}>
                                <FlexColumn className="container">
                                    <Title>Create Contact</Title>
                                    <FlexColumn>
                                        <TextField
                                            label="Phone Number"
                                            type="tel"
                                            value={this.contact.PhoneNumber}
                                            onChange={(_, val) => {
                                                if (
                                                    val !== undefined &&
                                                    this.contact !== null
                                                ) {
                                                    this.contact.PhoneNumber =
                                                        val
                                                }
                                            }}
                                            errorMessage={errorMessage}
                                        />
                                    </FlexColumn>
                                    <AttributeEditor contact={this.contact} />
                                    <Break size={3} />
                                    <Flex justify="flex-end">
                                        <DefaultButton
                                            text="Cancel"
                                            onClick={() => {
                                                this.contact = null
                                            }}
                                            style={{ marginRight: "1em" }}
                                        />
                                        <PrimaryButton
                                            disabled={Boolean(
                                                errorMessage ||
                                                    !this.contact?.PhoneNumber
                                            )}
                                            onClick={this.saveContact.bind(
                                                this
                                            )}
                                            text="Save"
                                        />
                                    </Flex>
                                </FlexColumn>
                            </NewContactModal>
                        )}
                    <ContactsContainer>
                        <FlexColumn
                            className={isExact ? "full-width" : "split"}>
                            {this.query.isEmpty && (
                                <MessageBar>
                                    You do not have any contacts in your
                                    organization. Add contact to continue.
                                </MessageBar>
                            )}
                            {this.query.map((contact) => (
                                <ContactItem
                                    query={query}
                                    key={contact.doc.id}
                                    contact={contact}
                                />
                            ))}
                        </FlexColumn>
                        <Route
                            component={ContactView}
                            path="/:orgId/contacts/c/:contactId"
                        />
                        <Route
                            component={ContactView}
                            path="/:orgId/contacts/q/:query/c/:contactId"
                        />
                    </ContactsContainer>
                </ConstrainedBody>
            </HasScope>
        )
    }
}

const NewContactModal = styled(Modal)`
    .container {
        border-top: 3px solid ${(props) => props.theme.colors.primaryDark} !important;
        min-width: 600px !important;
        padding: 1.5em 1em;
    }
`

const ContactsContainer = styled(Flex)`
    .full-width {
        flex-grow: 1;
    }
    .split {
        width: 40%;
    }
`

function ContactItem({ contact, query }: { contact: Contact; query?: string }) {
    let link: string
    if (query === undefined) {
        link = `/${contact.Organization}/contacts/c/${contact.doc.id}`
    } else {
        link = `/${contact.Organization}/contacts/q/${query}/c/${contact.doc.id}`
    }
    return (
        <ListItem
            to={link}
            icon={`https://lipis.github.io/flag-icon-css/flags/1x1/${contact.Country.toLowerCase()}.svg`}
            text={contact.DisplayName}
            footer={
                <Flex className="content">
                    <strong>Created:&nbsp;&nbsp;</strong>
                    <TimeAgo className="date" date={contact.RegisteredDate} />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <strong>Last Seen:&nbsp;&nbsp;</strong>
                    <TimeAgo className="date" date={contact.RegisteredDate} />
                </Flex>
            }
        />
    )
}
