import React from "react"
import { observer } from "mobx-react"
import { Link } from "react-router-dom"
import { ScopeValue, ScopeLabel } from "data/scope"
import { ConstrainedBody, Paragraph, Title } from "components/shared"
import { GlobalContext, GlobalState } from "data/state"

interface HasScopeProps {
    scope: ScopeValue | ScopeValue[]
    children: React.ReactNode
}

@observer
export class HasScope extends React.Component<HasScopeProps> {
    static contextType = GlobalContext
    render() {
        const state: GlobalState = this.context
        let { scope, children } = this.props
        if (state.user !== null) {
            if (!Array.isArray(scope)) scope = [scope]

            for (let i = 0; i < scope.length; i++) {
                if (state.user.Scope.hasScope(scope[i])) return children
            }
        }

        return (
            <ConstrainedBody maxWidth={800}>
                {<Title>Access Denied</Title>}

                <Paragraph>
                    You must have the permission{" "}
                    <kbd>{ScopeLabel(scope, " or ")}</kbd> to view this page.{" "}
                    <Link to={`/${state.organizationId}`}>Goto Home</Link>.
                </Paragraph>
            </ConstrainedBody>
        )
    }
}
