import React from "react"
import styled from "styled-components"
import { MessageBar as _MessageBar } from "@fluentui/react"
import { _TopLoadingBar, _Loading } from "components/shared/loading"
import {
    _Body,
    _ConstrainedBody,
    _Flex,
    _FlexColumn,
    _FormItem,
    _ButtonsHolder,
} from "components/shared/containers"
import {
    _Title,
    _SmallTitle,
    _SubTitle,
    _Paragraph,
} from "components/shared/typography"
import {
    _ButtonLink,
    _PageCommandBar,
    _ListItem,
    ZIcon as _ZIcon,
} from "components/shared/components"
import { Theme } from "data/theme"

export const TopLoadingBar = _TopLoadingBar
export const Loading = _Loading

export const Flex = _Flex
export const FlexColumn = _FlexColumn
export const ConstrainedBody = _ConstrainedBody
export const Body = _Body
export const FormItem = _FormItem
export const ButtonsHolder = _ButtonsHolder

export const Title = _Title
export const SmallTitle = _SmallTitle
export const SubTitle = _SubTitle
export const Paragraph = _Paragraph

export const ButtonLink = _ButtonLink
export const PageCommandBar = _PageCommandBar
export const ListItem = _ListItem
export const ZIcon = _ZIcon

interface BreakProps {
    size?: number
    divider?: boolean
    theme: Theme
}

export const Break = styled.div<BreakProps>`
    height: ${({ size }) => (size ? size : 1)}em;
    border-bottom: ${(p) =>
        p.divider ? "1px solid " + p.theme.colors.backgroundDark : "none"};
`

export const MessageBar = styled(_MessageBar)`
    color: ${(p) => p.theme.colors.fontPrimary};
    background-color: ${(p) => p.theme.colors.backgroundDark};
`

interface DataPointProps
    extends React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLDivElement>,
        HTMLDivElement
    > {
    name: string
}

export const DataPoint = styled.div<DataPointProps>`
    background-color: ${(p) => p.theme.colors.backgroundDark}77;
    margin: 0.5em;
    border-radius: 0.5em;
    flex-grow: 1;
    flex-basis: 0;
    padding: 0.5em;
    display: flex;

    &::before {
        background-color: ${(p) => p.theme.colors.backgroundDark};
        content: "${(p) => p.name}";
        padding: 0.5em;
        border-top-left-radius: 0.5em;
        border-bottom-left-radius: 0.5em;
        margin: -0.5em 0.5em -0.5em -0.5em;
    }
    user-select: none;
    .ms-Dropdown-container {
        flex-grow: 1;
    }
`
