import { LiveData } from "data/livedata"
import { observable, computed } from "mobx"
import phone from "phone"

export class Contact extends LiveData {
    static Collection = LiveData.collection("Contacts")
    static Fields = [
        "Attributes",
        "InteractionScore",
        "Country",
        "LastAccess",
        "Organization",
        "PhoneNumber",
        "RegisteredDate",
    ]
    @observable Attributes: {
        [attributeKey: string]: {
            Value: string
            Added: number
            Updated: number
        }
    } = {}
    @observable Country: string = "KE"
    @observable InteractionScore: number = 0
    @observable LastAccess: Date = new Date()
    @observable Organization: string = ""
    @observable PhoneNumber: string = ""
    @observable RegisteredDate: Date = new Date()

    @computed get DisplayName(): string {
        const possible_names = [
            "NAME",
            "name",
            "Name",
            "FullName",
            "FULL_NAME",
            "FULLNAME",
            "full_name",
            "fullname",
            "firstname",
            "first_name",
            "FIRSTNAME",
            "FIRST_NAME",
            "FirstName",
            "Firstname",
        ]
        for (let i = 0; i < possible_names.length; i++) {
            const field = `TEXT:${possible_names[i]}`
            if (field in this.Attributes) {
                return `${this.Attributes[field].Value} (${this.PhoneNumber})`
            }
        }
        return this.PhoneNumber
    }

    validatePhone(): boolean {
        const [phone_number, country] = phone(this.PhoneNumber)
        if (phone_number !== undefined && country !== undefined) {
            this.PhoneNumber = phone_number
            this.Country = country.substr(0, 2)
            return true
        }
        return false
    }
}
