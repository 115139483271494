import styled from "styled-components"

export const _Title = styled.div`
    color: ${(props) => props.theme.colors.fontSecondary};
    font-size: 24px;
`

export const _SubTitle = styled.div`
    color: ${(props) => props.theme.colors.fontSecondary};
`

export const _SmallTitle = styled.div`
    color: ${(props) => props.theme.colors.fontPrimary};
    margin-top: 10px;
    font-size: 1.3em;
`

export const _Paragraph = styled.div`
    color: ${(props) => props.theme.colors.fontPrimary};
    margin-top: 10px;
    font-size: 1.2em;
`
