import React from "react"
import styled from "styled-components"

interface FlexProps
    extends React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLDivElement>,
        HTMLDivElement
    > {
    justify?:
        | "flex-start"
        | "flex-end"
        | "center"
        | "space-between"
        | "space-around"
    align?: "flex-start" | "flex-end" | "center" | "stretch"
}
export const _Flex = styled.div<FlexProps>`
    display: flex;
    justify-content: ${(props) =>
        props.justify ? props.justify : "flex-start"};
    align-items: ${(props) => (props.align ? props.align : "stretch")};
`

export const _FlexColumn = styled.div<FlexProps>`
    display: flex;
    flex-direction: column;
    justify-content: ${(props) =>
        props.justify ? props.justify : "flex-start"};
    align-items: ${(props) => (props.align ? props.align : "stretch")};
`

export const _Body = styled.div`
    width: 100%;
    padding: 5px 10px 10px 5px;
`

interface ConstrainedBodyProps
    extends React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLDivElement>,
        HTMLDivElement
    > {
    maxWidth: number
}

export const _ConstrainedBody = styled.div<ConstrainedBodyProps>`
    padding-top: 1.5em;
    @media (min-width: ${(props) => props.maxWidth}px) {
        padding-right: calc((100% - ${(props) => props.maxWidth}px) / 2);
        padding-left: calc((100% - ${(props) => props.maxWidth}px) / 2);
    }
`

export const _FormItem = styled(_FlexColumn)`
    margin: 10px;
    a {
        font-size: 16px;
    }
`

export const _ButtonsHolder = styled(_FormItem)`
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    button {
        min-width: 160px;
    }
`
