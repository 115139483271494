import React from "react"
import styled from "styled-components"
import { observer } from "mobx-react"
import { ThemeProvider } from "styled-components"
import { ThemeProvider as FluentUIThemeProvider } from "@fluentui/react"
import {
    BrowserRouter,
    Switch,
    Route,
    Link,
    RouteComponentProps,
} from "react-router-dom"
import { GlobalContext, GlobalState } from "data/state"
import { fluentDark, fluentLight } from "data/theme"
import { Auth } from "components/auth/main"
import { Navbar } from "components/navigation/navbar"
import { NavMenu } from "components/navigation/navmenu"
import { CreateOrganization } from "components/organization/create"
import { FlowEditor } from "components/flows/edit"
import { FlowsList } from "components/flows"
import { NotFound } from "components/notfound"
import { Home } from "components/home"
import { SessionsView } from "components/sessions"
import { Attributes } from "components/attributes"
import { ContactsList } from "components/contacts"
import { ChannelsList } from "components/channels"
import { AccessManagement } from "components/organization/access_management"
import { AcceptInvite } from "components/organization/accept"
import { HasScope } from "components/access"
import { OrganizationHome } from "components/org-home"
import { Scope } from "data/scope"
import { Billing } from "components/organization/billing"

@observer
export default class App extends React.Component {
    globalState = new GlobalState()

    render() {
        const state = this.globalState
        const has_org = Boolean(state.organization)
        return (
            <BrowserRouter>
                <GlobalContext.Provider value={state}>
                    <ThemeProvider theme={state.theme}>
                        <FluentUIThemeProvider
                            applyTo="body"
                            theme={state.darkMode ? fluentDark : fluentLight}>
                            <Auth>
                                <Navbar />
                                {has_org && <NavMenu />}
                                <OrganizationBody
                                    className={
                                        has_org
                                            ? state.menuExpanded
                                                ? "menu-expanded"
                                                : "menu-collapsed"
                                            : ""
                                    }>
                                    {has_org && (
                                        <Switch>
                                            <Route
                                                path="/create"
                                                exact
                                                component={CreateOrganization}
                                            />
                                            <Route
                                                path="/:orgId/flows/new"
                                                component={(props: any) => (
                                                    <HasScope
                                                        scope={
                                                            Scope.CreateFlows
                                                        }>
                                                        <FlowEditor
                                                            {...props}
                                                        />
                                                    </HasScope>
                                                )}
                                                exact
                                            />

                                            <Route
                                                path="/:orgId/"
                                                component={OrganizationHome}
                                                exact
                                            />
                                            <Route
                                                path="/:orgId/flows/:flowId"
                                                component={FlowEditor}
                                                exact
                                            />
                                            <Route
                                                path="/:orgId/flows"
                                                component={FlowsList}
                                                exact
                                            />
                                            <Route
                                                path="/:orgId/sessions"
                                                component={SessionsView}
                                            />
                                            <Route
                                                path="/:orgId/attributes"
                                                component={Attributes}
                                            />
                                            <Route
                                                path="/:orgId/contacts/q/:query"
                                                component={ContactsList}
                                            />
                                            <Route
                                                path="/:orgId/contacts"
                                                component={ContactsList}
                                            />
                                            <Route
                                                path="/:orgId/settings/channels"
                                                component={ChannelsList}
                                            />
                                            <Route
                                                path="/:orgId/settings/access"
                                                component={AccessManagement}
                                            />
                                            <Route
                                                path="/:orgId/settings/billing"
                                                component={Billing}
                                            />
                                            <Route component={NotFound} />
                                        </Switch>
                                    )}
                                    {!has_org && (
                                        <Switch>
                                            <Route
                                                path="/"
                                                exact
                                                component={Home}
                                            />
                                            <Route
                                                path="/create"
                                                exact
                                                component={CreateOrganization}
                                            />
                                            <Route
                                                path="/invite/:inviteId"
                                                component={AcceptInvite}
                                            />
                                            <Route
                                                component={({
                                                    location: { pathname },
                                                }: RouteComponentProps) => (
                                                    <NotFound title="Organization Not Found">
                                                        Path{" "}
                                                        <kbd>{pathname}</kbd>{" "}
                                                        does not match any
                                                        organization that you
                                                        belong to.{" "}
                                                        <Link to="/">
                                                            Select organization
                                                        </Link>
                                                        .
                                                    </NotFound>
                                                )}
                                            />
                                        </Switch>
                                    )}
                                </OrganizationBody>
                            </Auth>
                        </FluentUIThemeProvider>
                    </ThemeProvider>
                </GlobalContext.Provider>
            </BrowserRouter>
        )
    }
}

const OrganizationBody = styled.div`
    &.menu-expanded {
        margin-left: 20em;
    }
    &.menu-collapsed {
        margin-left: 3em;
    }
    padding-top: 50px;
`
