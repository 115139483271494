import React from "react"
import { Link, withRouter, RouteComponentProps } from "react-router-dom"
import styled from "styled-components"
import firebase from "firebase/app"
import { FontIcon, CommandBar, ICommandBarItemProps } from "@fluentui/react"
import { GlobalContext, GlobalState } from "data/state"
import { observer } from "mobx-react"
import { TopLoadingBar } from "components/shared"

interface NavBarState {
    selectorOpen: boolean
}

@observer
class RoutedNavbar extends React.Component<RouteComponentProps, NavBarState> {
    static contextType = GlobalContext
    constructor(props: RouteComponentProps) {
        super(props)
        this.state = {
            selectorOpen: false,
        }
    }
    componentDidUpdate(prevProps: RouteComponentProps) {
        if (this.props.location !== prevProps.location) {
            this.setState({ selectorOpen: false })
        }
    }

    toggleAppSelector() {
        this.setState({ selectorOpen: !this.state.selectorOpen })
    }

    userLogout() {
        firebase.auth().signOut()
    }
    render() {
        const state = this.context as GlobalState
        const {
            organizationId,
            organization,
            loading,
            authorized,
            organizations,
            darkMode,
        } = state
        const homePath = organizationId ? `/${organizationId}` : "/"
        const organizationsList = Object.values(organizations)
        let selectorDisplay = "Select Organization"
        if (organization?.Name) selectorDisplay = organization.Name
        if (!organizationsList.length) selectorDisplay = "Create Organization"

        const menuItems: ICommandBarItemProps[] = [
            {
                key: "help",
                text: "Help",
                iconProps: { iconName: "Unknown" },
                ariaLabel: "Help",
                renderedInOverflow: false,
            },
            {
                key: "organization",
                text: selectorDisplay,
                iconProps: { iconName: "Org" },
                ariaLabel: selectorDisplay,
                onClick: !organizationsList.length
                    ? () => this.props.history.push("/create")
                    : undefined,
                split: true,
                renderedInOverflow: false,
                subMenuProps: {
                    items: [
                        ...organizationsList.map((orgObj) => {
                            return {
                                key: orgObj.org.doc.id,
                                text: orgObj.org.Name,
                                iconProps: { iconName: "Org" },
                                onClick: () =>
                                    this.props.history.push(
                                        `/${orgObj.org.doc.id}`
                                    ),
                                secondaryText: orgObj.org.Email,
                            }
                        }),
                        {
                            key: "create",
                            text: "Create Organization",
                            iconProps: { iconName: "Add" },
                            onClick: () => this.props.history.push("/create"),
                        },
                    ],
                },
            },
            {
                key: "account",
                text: authorized?.DisplayName
                    ? authorized.DisplayName
                    : "Account",
                iconProps: { iconName: "Contact" },
                ariaLabel: "Account",
                split: true,
                renderedInOverflow: false,
                subMenuProps: {
                    items: [
                        {
                            key: "theme",
                            text: darkMode ? "Light Mode" : "Dark Mode",
                            iconProps: {
                                iconName: darkMode ? "Sunny" : "ClearNight",
                            },
                            onClick: () => state.toggleMode(),
                        },
                        {
                            key: "logout",
                            text: "Logout",
                            iconProps: { iconName: "Leave" },
                            onClick: this.userLogout.bind(this),
                        },
                    ],
                },
            },
        ]
        return (
            <StyledNavBar className={state.darkMode ? "dark" : "light"}>
                <FontIcon
                    iconName="CollapseMenu"
                    className="menuIcon"
                    onClick={() => state.toggleMenuExpanded()}
                />
                <TopLoadingBar loading={loading} />
                <Link className="logo" to={homePath}>
                    <img className="banner" src="/banner.png" alt="" />
                </Link>
                <CommandBar
                    style={{ flexGrow: 1 }}
                    items={[]}
                    farItems={menuItems}
                />
            </StyledNavBar>
        )
    }
}

const StyledNavBar = styled.nav`
    display: flex;
    height: 50px;
    top: 2px; /*Leave space for loading animation*/
    left: 0;
    right: 0;
    position: fixed;
    align-items: center;
    user-select: none;
    z-index: 100;
    &.dark {
        box-shadow: 0px 0px 2px 2px #111111;
    }
    &.light {
        background-color: #ffffff;
        box-shadow: 0px 0px 2px 2px #f1f1f1;
    }
    .banner {
        height: 36px;
        width: auto;
        align-self: flex-start;
        margin: 5px 4px;
    }
    .menuIcon {
        margin: auto 10px;
        padding: 5px;
        font-size: 24px;
        cursor: pointer;
        color: ${(props) => props.theme.colors.primary};
        border-radius: 50%;
        &:hover {
            color: ${(props) => props.theme.colors.primaryDark};
        }
    }
    .search {
        flex-grow: 1;
        display: flex;
        justify-content: center;
        .inner {
            width: 350px;
        }
    }

    .button {
        margin: auto 5px;
        font-size: 36px;
        cursor: pointer;
        color: ${(props) => props.theme.colors.primary};
        border-radius: 50%;
        &:hover {
            color: ${(props) => props.theme.colors.primaryDark};
        }
    }

    .projectSelector {
        background-color: #f1f1f1;
        padding: 5px;
        border-radius: 5px;
        margin: 5px;
        display: flex;
        align-items: center;
        cursor: pointer;
        color: ${(props) => props.theme.colors.primary};
        span {
            font-weight: 400;
        }
        .projectIcon {
            font-size: 16px;
            margin-right: 5px;
        }
        &:hover {
            color: ${(props) => props.theme.colors.secondary};
        }
    }
`

export const Navbar = withRouter(RoutedNavbar)
