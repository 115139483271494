import React from "react"
import styled from "styled-components"
import { RouteComponentProps } from "react-router-dom"
import { observer } from "mobx-react"
import { Channel } from "data/channels"
import {
    Break,
    FlexColumn,
    PageCommandBar,
    Flex,
    DataPoint,
    MessageBar,
    Loading,
} from "components/shared"
import { PrimaryButton, Dropdown, IDropdownOption } from "@fluentui/react"
import { GlobalContext, GlobalState } from "data/state"
import { HasScope } from "components/access"
import { Scope } from "data/scope"
import { NotFound } from "components/notfound"

interface ViewChannelProps extends RouteComponentProps {
    match: {
        isExact: boolean
        params: {
            channelId: string
        }
        path: string
        url: string
    }
}

interface ViewChannelState {
    channel?: Channel
}

@observer
export class ViewChannel extends React.Component<
    ViewChannelProps,
    ViewChannelState
> {
    static contextType = GlobalContext
    constructor(props: ViewChannelProps) {
        super(props)
        this.state = {}
    }
    componentDidMount() {
        const {
            match: {
                params: { channelId },
            },
        } = this.props
        this.loadChannel(channelId)
    }

    componentDidUpdate(oldProps: ViewChannelProps) {
        const {
            match: {
                params: { channelId },
            },
        } = this.props
        if (channelId !== oldProps.match.params.channelId) {
            this.loadChannel(channelId)
        }
    }

    loadChannel(channelId: string) {
        const state: GlobalState = this.context
        const channel = new Channel(
            channelId,
            undefined,
            state.promiseLoadingHelper()
        )
        this.setState({ channel })
    }

    save() {
        const state: GlobalState = this.context
        const { channel } = this.state
        if (channel === undefined) return
        channel.update().finally(state.promiseLoadingHelper())
    }

    render() {
        const { channel } = this.state
        const state: GlobalState = this.context
        if (channel === undefined || channel.label === undefined) return null
        if (channel.loading) return <Loading />
        if (!channel.exists)
            return <NotFound title="Channel not found"></NotFound>

        return (
            <HasScope scope={Scope.ReadChannels}>
                <Container>
                    <PageCommandBar
                        icon={channel.label.icon}
                        title={`${channel.label.label} ${channel.ShortId}`}
                    />
                    <Break />
                    <Flex>
                        <DataPoint name="Type">{channel.Category}</DataPoint>
                        {channel.Category === "Shared" && (
                            <DataPoint name="Keyword">
                                {channel.KeyWord}
                            </DataPoint>
                        )}
                    </Flex>
                    <Break />
                    <MessageBar>
                        Users will be redirected to this flow when they visit
                        this channel.
                    </MessageBar>
                    <DataPoint style={{ flexGrow: 0 }} name="Flow">
                        <Dropdown
                            selectedKey={channel.FlowID}
                            placeholder="Select Flow"
                            options={Object.keys(state.flows).map((key) => {
                                const flow = state.flows[key]
                                const opt: IDropdownOption = {
                                    key,
                                    text: flow.Name,
                                }
                                return opt
                            })}
                            onChange={(evt, opt) => {
                                const { channel } = this.state
                                if (channel === undefined || opt === undefined)
                                    return null
                                channel.FlowID = `${opt.key}`
                            }}
                        />
                    </DataPoint>
                    <Break size={2} />
                    <Flex justify="flex-end">
                        <PrimaryButton
                            disabled={channel.changes.length === 0}
                            onClick={this.save.bind(this)}
                            text="Save"
                        />
                    </Flex>
                </Container>
            </HasScope>
        )
    }
}

const Container = styled(FlexColumn)`
    flex-grow: 1;
    padding: 0.25em;
`
