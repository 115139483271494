import { IDropdownOption, DropdownMenuItemType } from "@fluentui/react"

export type ChannelType =
    | "TESTING"
    | "SHORT_CODE"
    | "KEYWORD"
    | "USSD"
    | "WHATSAPP"
    | "TELEGRAM"
export type ChannelCategory = "Shared" | "Private"
export type ChannelStatus = "Active" | "Archived" | "Blocked"
export type ChannelRequestStatus =
    | "Requested"
    | "In Progress"
    | "Rejected"
    | "Complete"

function detail(label: string, description: string, icon: string) {
    return { label, description, icon }
}

export const channelLabels: Map<
    ChannelType,
    { label: string; description: string; icon: string }
> = new Map()
channelLabels.set(
    "TESTING",
    detail(
        "Testing Channel",
        "The default testing channel, helpful to test your flows at no cost.",
        "fas fa-comments"
    )
)
channelLabels.set(
    "SHORT_CODE",
    detail(
        "Short Code",
        "A numeric code code that your users can send messages to e.g. 2134",
        "fas fa-sms"
    )
)
channelLabels.set(
    "KEYWORD",
    detail(
        "Key Word",
        "A keyword is used in combination with a shared short code. To access your flows your users will have to start their messages with this keyword.",
        "fas fa-comment-dots"
    )
)
channelLabels.set(
    "WHATSAPP",
    detail(
        "Whatsapp",
        "A whatsapp number that your users can reach you through and the messages processed by Zohari",
        "fab fa-whatsapp"
    )
)
channelLabels.set(
    "TELEGRAM",
    detail(
        "Telegram",
        "A telegram bot that your users can interact with.",
        "fab fa-telegram-plane"
    )
)
channelLabels.set(
    "USSD",
    detail(
        "USSD",
        "A USSD code that your users can dial to e.g. *123#",
        "fas fa-phone-alt"
    )
)

export const ChannelTypeOptions: IDropdownOption[] = [
    {
        key: "sms",
        text: "Short Message Services",
        itemType: DropdownMenuItemType.Header,
    },
    {
        key: "SHORT_CODE",
        text: channelLabels.get("SHORT_CODE")?.label || "",
    },
    {
        key: "KEYWORD",
        text: channelLabels.get("KEYWORD")?.label || "",
    },
    {
        key: "messengers",
        text: "Instant Messengers",
        itemType: DropdownMenuItemType.Header,
    },
    {
        key: "WHATSAPP",
        text: channelLabels.get("WHATSAPP")?.label || "",
    },
    {
        key: "TELEGRAM",
        text: channelLabels.get("TELEGRAM")?.label || "",
    },
    {
        key: "phone",
        text: "Phone Channels",
        itemType: DropdownMenuItemType.Header,
    },
    {
        key: "USSD",
        text: channelLabels.get("USSD")?.label || "",
    },
]
