import { createTheme } from "@fluentui/react"

export interface Theme {
    isDark: boolean
    colors: {
        primary: string
        primaryDark: string
        secondary: string
        secondaryDark: string
        fontPrimary: string
        fontSecondary: string
        background: string
        backgroundDark: string
        backgroundLight: string
        danger: string
    }
}

export const lightTheme: Theme = {
    isDark: false,
    colors: {
        primary: "#5486bc",
        primaryDark: "#1c5a9a",
        secondary: "#fac51f",
        secondaryDark: "#f57f17",
        fontPrimary: "#444444",
        fontSecondary: "#666666",
        background: "#ffffff",
        backgroundDark: "#f1f1f1",
        backgroundLight: "#eeeeee",
        danger: "#f44336",
    },
}

export const darkTheme: Theme = {
    isDark: true,
    colors: {
        primary: "#5486bc",
        primaryDark: "#1c5a9a",
        secondary: "#fac51f",
        secondaryDark: "#f57f17",
        fontPrimary: "#f1f1f1",
        fontSecondary: "#ffffff",
        background: "#222222",
        backgroundLight: "#444444",
        backgroundDark: "#171717",
        danger: "#f44336",
    },
}

export const fluentLight = createTheme({
    palette: {
        themePrimary: "#1c5a9a",
        themeLighterAlt: "#010406",
        themeLighter: "#040e18",
        themeLight: "#081b2e",
        themeTertiary: "#11365c",
        themeSecondary: "#184f87",
        themeDarkAlt: "#2b67a3",
        themeDark: "#437ab1",
        themeDarker: "#6b98c6",
        neutralLighterAlt: "#f8f8f8",
        neutralLighter: "#f4f4f4",
        neutralLight: "#eaeaea",
        neutralQuaternaryAlt: "#dadada",
        neutralQuaternary: "#d0d0d0",
        neutralTertiaryAlt: "#c8c8c8",
        neutralTertiary: "#c7c7c7",
        neutralSecondary: "#8f8f8f",
        neutralPrimaryAlt: "#5b5b5b",
        neutralPrimary: "#444444",
        neutralDark: "#343434",
        black: "#272727",
        white: "#ffffff",
    },
})
export const fluentDark = createTheme({
    palette: {
        themePrimary: "#1c5a9a",
        themeLighterAlt: "#010406",
        themeLighter: "#040e18",
        themeLight: "#081b2e",
        themeTertiary: "#11365c",
        themeSecondary: "#184f87",
        themeDarkAlt: "#2b67a3",
        themeDark: "#437ab1",
        themeDarker: "#6b98c6",
        neutralLighterAlt: "#0b0b0b",
        neutralLighter: "#151515",
        neutralLight: "#252525",
        neutralQuaternaryAlt: "#2f2f2f",
        neutralQuaternary: "#373737",
        neutralTertiaryAlt: "#595959",
        neutralTertiary: "#c8c8c8",
        neutralSecondary: "#d0d0d0",
        neutralPrimaryAlt: "#dadada",
        neutralPrimary: "#ffffff",
        neutralDark: "#f4f4f4",
        black: "#f8f8f8",
        white: "#222222",
    },
})
