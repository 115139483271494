import React from "react"
import styled from "styled-components"
import { observer } from "mobx-react"
import { GlobalContext, GlobalState } from "data/state"
import { ConstrainedBody, FlexColumn, Flex, Title, ZIcon } from "./shared"
import { Link } from "react-router-dom"

@observer
export class OrganizationHome extends React.Component {
    static contextType = GlobalContext
    render() {
        const { organization, user } = this.context as GlobalState
        if (organization === null || user === null) return null

        return (
            <ConstrainedBody maxWidth={1300}>
                <Title style={{ textAlign: "center" }}>
                    {organization.Name}
                </Title>

                <Flex style={{ flexWrap: "wrap" }} justify="space-between">
                    {user.Scope.can_read_contacts && (
                        <MenuItem to={`/${organization.doc.id}/contacts`}>
                            <ZIcon name="ContactList" className="icon" />
                            <FlexColumn>
                                <span>Contacts List</span>
                                <small>
                                    View and edit contacts who have reached out
                                    to your organization.
                                </small>
                            </FlexColumn>
                        </MenuItem>
                    )}

                    {user.Scope.can_read_attributes && (
                        <MenuItem to={`/${organization.doc.id}/attributes`}>
                            <ZIcon name="ContactList" className="icon" />
                            <FlexColumn>
                                <span>Attributes</span>
                                <small>
                                    Attributes allow you to attach information
                                    to your contacts. View and edit them here.
                                </small>
                            </FlexColumn>
                        </MenuItem>
                    )}

                    {user.Scope.can_read_flows && (
                        <MenuItem to={`/${organization.doc.id}/flows`}>
                            <ZIcon name="MicrosoftFlowLogo" className="icon" />
                            <FlexColumn>
                                <span>Flows</span>
                                <small>
                                    View and edit processes that your users go
                                    through when communicating with you.
                                </small>
                            </FlexColumn>
                        </MenuItem>
                    )}

                    {user.Scope.can_read_sessions && (
                        <MenuItem to={`/${organization.doc.id}/sessions`}>
                            <ZIcon name="WorkFlow" className="icon" />
                            <FlexColumn>
                                <span>Sessions</span>
                                <small>View communication from users</small>
                            </FlexColumn>
                        </MenuItem>
                    )}
                </Flex>
            </ConstrainedBody>
        )
    }
}

const MenuItem = styled(Link)`
    width: 300px;
    display: flex;
    color: ${(props) => props.theme.colors.primaryDark};
    .icon {
        font-size: 3em;
        color: ${(props) => props.theme.colors.primaryDark};
        margin-right: 0.5em;
    }
    span {
        font-size: 1.4em;
        color: ${(props) => props.theme.colors.primaryDark};
    }
    small {
        font-size: 1em;
        color: ${(props) => props.theme.colors.fontPrimary};
    }
    padding: 0.8em 1em;
    background-color: ${(props) => props.theme.colors.backgroundDark};
    border-radius: 0.5em;
    margin: 1em;
    &:hover {
        background-color: ${(props) => props.theme.colors.backgroundDark};
    }
`
