import React from "react"
import styled, { keyframes } from "styled-components"
import { SpinnerSize, Spinner } from "@fluentui/react"
import {
    _ConstrainedBody as ConstrainedBody,
    _FlexColumn as FlexColumn,
} from "components/shared/containers"
import { _Title as Title } from "components/shared/typography"

interface LoadingBarProps {
    className?: string
    loading: boolean
}

export function LoadingBar(props: LoadingBarProps) {
    return (
        <div
            className={
                props.loading ? `loading ${props.className}` : props.className
            }>
            <div className="bar" />
        </div>
    )
}

const loadingAnimation = keyframes`
        0% {
            width: 10%
        }
        100% {
            width: 90%;
        }
`

export const _TopLoadingBar = styled(LoadingBar)`
    top: 0;
    height: 0.188em;
    left: 0;
    right: 0;
    position: fixed;
    z-index: 101;
    display: flex;
    justify-content: center;
    background-color: ${(props: any) => props.theme.colors.background};
    .bar {
        display: none;
        border-radius: 0.625em;
        height: 100%;
        animation-name: ${loadingAnimation};
        animation-duration: 800ms;
        animation-direction: alternate;
        animation-iteration-count: infinite;
    }
    &.loading {
        background-color: ${(props: any) => props.theme.colors.background};
        .bar {
            display: block;
            background-color: ${(props: any) => props.theme.colors.primary};
        }
    }
`

export const _LoadingBar = styled(_TopLoadingBar)`
    position: unset;
`

interface LoadingProps {
    title?: string
}

export class _Loading extends React.Component<LoadingProps> {
    render() {
        const { title } = this.props
        return (
            <ConstrainedBody maxWidth={800}>
                <FlexColumn justify="center" align="center">
                    {!title && <Title>Loading...</Title>}
                    {Boolean(title) && <Title>{title}</Title>}
                    <Spinner
                        style={{ marginTop: 20 }}
                        size={SpinnerSize.large}
                    />
                </FlexColumn>
            </ConstrainedBody>
        )
    }
}
