import React from "react"
import styled from "styled-components"
import { observer } from "mobx-react"
import { Scope } from "data/scope"
import { Flex, FlexColumn, SmallTitle } from "components/shared"
import { Checkbox } from "@fluentui/react"

interface PermissionPickerProps {
    readonly?: boolean
    scope: Scope
    title?: string
}

@observer
export class PermissionPicker extends React.Component<PermissionPickerProps> {
    render() {
        const { readonly, scope, title } = this.props
        const disabled = readonly
        return (
            <PickerContainer>
                <SmallTitle>{title}</SmallTitle>
                <PermissionTitle>Administration</PermissionTitle>
                <Flex>
                    <PermissionContainer>
                        <Checkbox
                            checked={scope.is_admin}
                            label="Admin"
                            onChange={() =>
                                scope.toggleScope(Scope.OrganizationAdmin)
                            }
                            disabled={disabled}
                        />
                        <small>
                            An admin has permission to access all the features
                            of an application.
                        </small>
                    </PermissionContainer>
                    <PermissionContainer>
                        <Checkbox
                            checked={scope.can_read_users}
                            label="View Users"
                            onChange={() => scope.toggleScope(Scope.ReadUsers)}
                            disabled={disabled}
                        />
                        <small>
                            Read only permission to users in your organization.
                        </small>
                    </PermissionContainer>
                </Flex>
                <PermissionTitle>Contacts</PermissionTitle>
                <Flex>
                    <PermissionContainer>
                        <Checkbox
                            checked={scope.can_write_contacts}
                            label="Write Contacts"
                            onChange={() =>
                                scope.toggleScope(Scope.WriteContacts)
                            }
                            disabled={disabled}
                        />
                        <small>
                            The user who can edit and create new contacts.
                        </small>
                    </PermissionContainer>
                    <PermissionContainer>
                        <Checkbox
                            checked={scope.can_read_contacts}
                            label="Read Contacts"
                            onChange={() =>
                                scope.toggleScope(Scope.ReadContacts)
                            }
                            disabled={disabled}
                        />
                        <small>Read only permission to users.</small>
                    </PermissionContainer>
                </Flex>
                <PermissionTitle>Flows</PermissionTitle>
                <Flex>
                    <PermissionContainer>
                        <Checkbox
                            checked={scope.can_create_flows}
                            label="Create Flows"
                            onChange={() =>
                                scope.toggleScope(Scope.CreateFlows)
                            }
                            disabled={disabled}
                        />
                        <small>
                            The user can create new flows and edit existing
                            ones.
                        </small>
                    </PermissionContainer>
                    <PermissionContainer>
                        <Checkbox
                            checked={scope.can_edit_flows}
                            label="Edit Flows"
                            onChange={() => scope.toggleScope(Scope.EditFlows)}
                            disabled={disabled}
                        />
                        <small>
                            The user can edit existing flows, however they can't
                            create new ones.
                        </small>
                    </PermissionContainer>
                </Flex>
                <Flex>
                    <PermissionContainer>
                        <Checkbox
                            checked={scope.can_read_flows}
                            label="Read Flows"
                            onChange={() => scope.toggleScope(Scope.ReadFlows)}
                            disabled={disabled}
                        />
                        <small>The user can only read flows.</small>
                    </PermissionContainer>

                    <PermissionContainer>
                        <Checkbox
                            checked={scope.can_read_sessions}
                            label="Read Sessions"
                            onChange={() =>
                                scope.toggleScope(Scope.ReadSessions)
                            }
                            disabled={disabled}
                        />
                        <small>
                            The user can view sessions of users going through
                            various flows.
                        </small>
                    </PermissionContainer>
                </Flex>
                <PermissionTitle>Attributes</PermissionTitle>
                <Flex>
                    <PermissionContainer>
                        <Checkbox
                            checked={scope.can_write_attributes}
                            label="Write Attributes"
                            onChange={() =>
                                scope.toggleScope(Scope.WriteAttributes)
                            }
                            disabled={disabled}
                        />
                        <small>
                            The user can edit existing attributes and create new
                            ones.
                        </small>
                    </PermissionContainer>
                    <PermissionContainer>
                        <Checkbox
                            checked={scope.can_read_attributes}
                            label="Read Attributes"
                            onChange={() =>
                                scope.toggleScope(Scope.ReadAttributes)
                            }
                            disabled={disabled}
                        />
                        <small>
                            The user can only view attributes without edit
                            permission.
                        </small>
                    </PermissionContainer>
                </Flex>
                <PermissionTitle>Channels</PermissionTitle>
                <Flex>
                    <PermissionContainer>
                        <Checkbox
                            checked={scope.can_write_channels}
                            label="Write Channels"
                            onChange={() =>
                                scope.toggleScope(Scope.WriteChannels)
                            }
                            disabled={disabled}
                        />
                        <small>The user can edit existing channels.</small>
                    </PermissionContainer>
                    <PermissionContainer>
                        <Checkbox
                            checked={scope.can_request_channels}
                            label="Request Channels"
                            onChange={() =>
                                scope.toggleScope(Scope.RequestChannels)
                            }
                            disabled={disabled}
                        />
                        <small>
                            The user can request for new channels to be added to
                            the organization.
                        </small>
                    </PermissionContainer>
                </Flex>
                <Flex>
                    <PermissionContainer>
                        <Checkbox
                            checked={scope.can_read_channels}
                            label="Read Channels"
                            onChange={() =>
                                scope.toggleScope(Scope.ReadChannels)
                            }
                            disabled={disabled}
                        />
                        <small>
                            The user can read active channels by the
                            organization.
                        </small>
                    </PermissionContainer>
                    <PermissionContainer className="clear" />
                </Flex>
            </PickerContainer>
        )
    }
}

const PermissionContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0;
    background-color: ${(p) => p.theme.colors.backgroundDark};
    margin: 5px 0;
    padding: 5px 10px;
    border-radius: 2px;
    &.clear {
        background-color: transparent;
    }
    small {
        color: ${(p) => p.theme.colors.fontSecondary};
        margin-top: 4px;
    }
    &:first-child {
        margin-right: 3px;
    }
    &:last-child {
        margin-left: 3px;
    }
`

const PermissionTitle = styled.div`
    margin-top: 1em;
    color: ${(p) => p.theme.colors.fontSecondary}99;
    font-size: 1.1em;
    padding-left: 0.5em;
`

const PickerContainer = styled(FlexColumn)`
    background-color: ${(p) => p.theme.colors.backgroundDark}77;
    padding: 5px 10px;
    border-radius: 5px;
    margin: 0.5em;
`
