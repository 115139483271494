import React from "react"
import styled from "styled-components"
import { NavLink } from "react-router-dom"
import {
    CommandBar,
    ICommandBarItemProps,
    Icon,
    ImageIcon,
} from "@fluentui/react"
import { _Title as Title } from "./typography"

export const _ButtonLink = styled.div`
    display: flex;
    color: ${(props: any) => props.theme.colors.primary};
    cursor: pointer;
    align-items: center;
    border: 1px solid ${(props: any) => props.theme.colors.primary};
    padding: 5px 10px;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 500;
    img {
        height: 16px;
        margin-right: 4px;
    }
    .ms-Icon {
        margin-right: 4px;
    }
`

export function ZIcon({
    name,
    className,
    size,
}: {
    name: string
    className?: string
    size?: number
}) {
    if (name.startsWith("fa")) {
        return <i className={`${className} ${name}`} />
    }
    if (
        name.startsWith("/") ||
        name.startsWith("http://") ||
        name.startsWith("https://")
    ) {
        return (
            <ImageIcon
                imageProps={{
                    src: name,
                    style: {
                        height: size ? size : 24,
                    },
                    className,
                }}
            />
        )
    }
    return <Icon className={className} iconName={name} />
}

export function _PageCommandBar({
    icon,
    title,
    children,
    top,
    style,
    className,
}: {
    top?: number
    icon?: string
    title: React.ReactNode
    children?: ICommandBarItemProps[]
    style?: React.CSSProperties
    className?: string
}) {
    return (
        <StyledPageCommandBar style={{ top, ...style }} className={className}>
            <Title style={{ display: "flex", alignItems: "center" }}>
                {icon !== undefined && <ZIcon className="icon" name={icon} />}
                {title}
            </Title>
            {children !== undefined && (
                <CommandBar
                    style={{
                        flexGrow: 1,
                    }}
                    items={[]}
                    farItems={children}
                />
            )}
        </StyledPageCommandBar>
    )
}

const StyledPageCommandBar = styled.div`
    border-bottom: 1px solid ${(p) => p.theme.colors.backgroundDark};
    background-color: ${(p) => p.theme.colors.background};
    display: flex;
    align-items: center;
    z-index: 20;
    .icon {
        color: ${(p) => p.theme.colors.primaryDark};
        padding: 0.5em;
        align-self: flex-end;
        img {
            border-radius: 50%;
        }
    }
    .fontIcon {
        color: ${(p) => p.theme.colors.primaryDark}dd;
        margin: 0.25em;
        font-size: 1.2em;
    }
    .selected {
        border-bottom: 2px solid ${(props) => props.theme.colors.primaryDark};
    }
    position: sticky;
    top: 50px;
`

export function _ListItem({
    to,
    text,
    description,
    footer,
    icon,
    farRight,
    className,
}: {
    to?: string
    text: React.ReactNode
    description?: React.ReactNode
    footer?: React.ReactNode
    farRight?: React.ReactNode
    icon?: string
    className?: string
}) {
    return (
        <StyledListItem
            as={to !== undefined ? NavLink : undefined}
            to={`${to}`}
            className={className}>
            {icon !== undefined && <ZIcon name={icon} className="icon" />}
            <div className="content">
                <div className="title">{text}</div>
                {description !== undefined && (
                    <div className="description">{description}</div>
                )}
                {footer !== undefined && <div className="footer">{footer}</div>}
            </div>
            {farRight}
        </StyledListItem>
    )
}

const StyledListItem = styled.div`
    display: flex;
    background-color: ${(p) => p.theme.colors.backgroundDark}77;
    margin: 0.5em 0.2em;
    padding: 0.5em 1em;
    &:hover,
    &.active {
        background-color: ${(p) => p.theme.colors.backgroundDark};
    }
    .icon {
        color: ${(p) => p.theme.colors.primaryDark}dd;
        margin-right: 0.8em;
        font-size: 1.4em;
        padding-top: 0.25em;
        img {
            border-radius: 50%;
        }
    }
    .content {
        flex-grow: 1;
    }
    .description {
        color: ${(p) => p.theme.colors.fontPrimary}cc;
    }
    .title {
        font-weight: bold;
        color: ${(p) => p.theme.colors.fontPrimary};
        .secondary {
            margin-left: 10px;
            color: ${(p) => p.theme.colors.fontSecondary}aa;
            font-style: italic;
        }
    }
    .footer {
        color: ${(p) => p.theme.colors.fontSecondary};
        font-size: 0.8em;
        strong {
            font-weight: 600;
        }
    }
`
