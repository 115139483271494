import React from "react"
import styled from "styled-components"
import { observer } from "mobx-react"
import { RouteComponentProps, Route, Link } from "react-router-dom"
import { HasScope } from "components/access"
import {
    ConstrainedBody,
    PageCommandBar,
    FlexColumn,
    ListItem,
    SmallTitle,
    Break,
    Flex,
} from "components/shared"
import { OrganizationInvite, OrganizationUser } from "data/organization"
import { Scope } from "data/scope"
import { LiveQuery } from "data/livedata"
import { GlobalContext, GlobalState } from "data/state"
import { ViewUser } from "./view_user"
import { ViewInvite } from "./view_invite"
import TimeAgo from "react-timeago"
import { Invite } from "./invite"

@observer
export class AccessManagement extends React.Component<RouteComponentProps> {
    usersQuery = new LiveQuery<OrganizationUser>(OrganizationUser)
    invitesQuery = new LiveQuery<OrganizationInvite>(OrganizationInvite)
    static contextType = GlobalContext
    componentDidMount() {
        const state: GlobalState = this.context
        this.usersQuery.where("OrganizationId", "==", state.organizationId)
        this.usersQuery.run().finally(state.promiseLoadingHelper())
        this.invitesQuery.where("OrganizationId", "==", state.organizationId)
        this.invitesQuery.where("Status", "==", "invited")
        this.invitesQuery.run().finally(state.promiseLoadingHelper())
    }
    render() {
        const {
            match: { isExact },
            history,
        } = this.props
        const state: GlobalState = this.context
        return (
            <HasScope scope={Scope.ReadUsers}>
                <ConstrainedBody maxWidth={1000}>
                    <PageCommandBar
                        icon="PlayerSettings"
                        title="Access Management">
                        {[
                            {
                                key: "InviteUser",
                                text: "Invite User",
                                iconProps: {
                                    iconName: "AddFriend",
                                },
                                onClick: () =>
                                    history.push(
                                        `/${state.organizationId}/settings/access/invite`
                                    ),
                                disabled: !state?.user?.Scope.is_admin,
                            },
                        ]}
                    </PageCommandBar>

                    <StyledUsersList
                        className={isExact ? "full-width" : "split"}>
                        <FlexColumn className="list">
                            {this.usersQuery.map((user) => (
                                <ListItem
                                    to={`/${state.organizationId}/settings/access/u/${user.doc.id}`}
                                    key={user.doc.id}
                                    icon="Contact"
                                    text={user.firebaseUser.DisplayName}
                                    description={user.firebaseUser.Emails[0]}
                                    footer={<TimeAgo date={user.Added} />}
                                />
                            ))}
                            <Break />
                            {!this.invitesQuery.isEmpty && (
                                <SmallTitle>Invited Users</SmallTitle>
                            )}
                            {this.invitesQuery.map((invite) => (
                                <ListItem
                                    to={`/${state.organizationId}/settings/access/i/${invite.doc.id}`}
                                    key={invite.doc.id}
                                    icon="Contact"
                                    text={invite.EmailAddress}
                                    description={invite.doc.id}
                                    footer={
                                        <Flex>
                                            <TimeAgo date={invite.Invited} />
                                            &nbsp;by&nbsp;
                                            <Link
                                                to={`/${state.organizationId}/settings/access/u/${invite.InvitedBy}`}>
                                                {
                                                    invite.invitedBy
                                                        .firebaseUser.Emails[0]
                                                }
                                            </Link>
                                        </Flex>
                                    }
                                />
                            ))}
                        </FlexColumn>
                        <Route
                            path={`/${state.organizationId}/settings/access/invite`}
                            component={Invite}
                        />
                        <Route
                            path={`/${state.organizationId}/settings/access/u/:userId`}
                            component={ViewUser}
                        />
                        <Route
                            path={`/${state.organizationId}/settings/access/i/:inviteId`}
                            component={ViewInvite}
                        />
                    </StyledUsersList>
                </ConstrainedBody>
            </HasScope>
        )
    }
}

const StyledUsersList = styled.div`
    padding: 0.25em;
    display: flex;
    width: 100%;
    &.full-width {
        .list {
            width: 100%;
        }
    }
    &.split {
        .list {
            width: 40%;
            margin-right: 0.25em;
        }
    }
`
