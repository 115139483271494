import React from "react"
import styled from "styled-components"
import Xarrow from "react-xarrows"
import { observer } from "mobx-react"
import { Flow, TypeData, CheckLogic, Logic } from "data/flows"
import { GlobalContext, GlobalState } from "data/state"
import { Icon, IconButton, MessageBar, MessageBarType } from "@fluentui/react"
import { QuestionEditor } from "components/flows/question-editor"
import {
    Break,
    Flex,
    FlexColumn,
    Paragraph,
    SmallTitle,
} from "components/shared"
import { Link } from "react-router-dom"

interface QuestionViewProps {
    flow: Flow
    path: string
    editable: boolean
    mayBeRedraw: () => void
    render_id: number
}

interface QuestionViewState {
    collapsed: boolean
    editing: boolean
}

@observer
export class QuestionView extends React.Component<
    QuestionViewProps,
    QuestionViewState
> {
    static contextType = GlobalContext
    constructor(props: QuestionViewProps) {
        super(props)
        this.state = {
            collapsed: false,
            editing: false,
        }
    }

    toggleCollapse() {
        const { collapsed } = this.state
        this.setState({ collapsed: !collapsed }, this.props.mayBeRedraw)
    }

    openEditor() {
        this.setState({ editing: true }, this.props.mayBeRedraw)
    }

    closeEditor() {
        this.setState({ editing: false }, this.props.mayBeRedraw)
    }

    render() {
        const { flow, path, editable, render_id } = this.props
        const { collapsed, editing } = this.state
        const state: GlobalState = this.context
        const logic = flow.getLogic(path)
        if (logic === null) return null
        const classes: string[] = []
        classes.push(state.darkMode ? "dark" : "light")
        let hasEnumOptions: boolean = false
        if (logic.enumOptions !== null && logic.enumOptions.length) {
            hasEnumOptions =
                logic.enumOptions.filter((opt) => opt.logic !== null).length > 0
            // console.log(logic)
        }
        if (logic.next !== null && !hasEnumOptions) classes.push("next")
        if (logic.options !== null || hasEnumOptions) classes.push("options")
        const { ok, message } = CheckLogic(logic)

        const parts = path.split("/")
        let parent: { index: number; logic: Logic } | null = null
        if (
            parts[parts.length - 2] === "options" ||
            parts[parts.length - 2] === "enumOptions"
        ) {
            const index = parseInt(`${parts.pop()}`)
            parts.pop()
            const parentLogic = flow.getLogic(parts.join("/"))
            if (parentLogic !== null) {
                parent = {
                    index,
                    logic: parentLogic,
                }
            }
        }

        let next: React.ReactNode = null
        if (logic.next && !collapsed) {
            next = (
                <>
                    <BigBreak />
                    <QuestionView
                        flow={flow}
                        editable={editable}
                        path={add_to_path(path, "next")}
                        mayBeRedraw={this.props.mayBeRedraw}
                        render_id={render_id}
                    />
                    <Xarrow
                        start={path_id(add_to_path(path, render_id))}
                        end={path_id(add_to_path(path, "next", render_id))}
                        startAnchor="bottom"
                        endAnchor="top"
                        headSize={5}
                        color={state.theme.colors.primaryDark}
                    />
                </>
            )
        }

        return (
            <QuestionContainer className={classes.join(" ")}>
                <QuestionContent
                    next={next}
                    className={!ok ? "error" : ""}
                    id={path_id(add_to_path(path, render_id))}>
                    <Flex className="title-bar" justify="space-between">
                        <Flex align="center">
                            <Icon
                                style={{
                                    fontSize: 24,
                                    color: state.theme.colors.primaryDark,
                                    marginRight: 10,
                                }}
                                iconName={TypeData[logic.type].icon}
                            />
                            <SmallTitle style={{ marginTop: 0 }}>
                                {logic.title}
                            </SmallTitle>
                        </Flex>
                        <Flex>
                            {Boolean(!editing && editable) && (
                                <IconButton
                                    onClick={this.openEditor.bind(this)}
                                    title={"Edit Question"}
                                    iconProps={{
                                        iconName: "Edit",
                                    }}
                                />
                            )}
                            {Boolean(logic.next || logic.options) && (
                                <IconButton
                                    onClick={this.toggleCollapse.bind(this)}
                                    title={collapsed ? "Expand" : "Collapse"}
                                    iconProps={{
                                        iconName: collapsed
                                            ? "ExploreContent"
                                            : "CollapseContentSingle",
                                    }}
                                />
                            )}
                        </Flex>
                    </Flex>

                    {Boolean(
                        logic.type !== "REDIRECT" && logic.type !== "CUSTOM"
                    ) && (
                        <Paragraph
                            style={{ whiteSpace: "pre-wrap", fontSize: "1em" }}>
                            {logic.displayText
                                ? logic.displayText
                                : "question text here..."}
                            {logic.options !== null && (
                                <ol>
                                    {logic.options.map((opt, i) => (
                                        <li key={i}>{opt.title}</li>
                                    ))}
                                </ol>
                            )}
                            {Boolean(logic.showHome) && (
                                <span>
                                    <br />
                                    {logic.showHome}
                                </span>
                            )}
                            {Boolean(logic.showPrev) && (
                                <span>
                                    <br />
                                    {logic.showPrev}
                                </span>
                            )}
                        </Paragraph>
                    )}
                    {Boolean(logic.type === "CUSTOM" && logic.code?.custom) && (
                        <Paragraph>
                            <kbd>Runs custom code</kbd>
                            <br />
                            {editable && (
                                <small>
                                    Go to advanced settings to view and edit the
                                    code.
                                </small>
                            )}
                        </Paragraph>
                    )}
                    {Boolean(logic.type === "REDIRECT" && logic.redirect) && (
                        <Paragraph>
                            Goto:{" "}
                            <Link
                                to={`/${state.organizationId}/flows/${logic.redirect}`}>
                                {state.flows[`${logic.redirect}`].Name}
                            </Link>
                            <br />
                            <small>
                                {state.flows[`${logic.redirect}`].Description}
                            </small>
                        </Paragraph>
                    )}
                    {!ok && <Break />}
                    {!ok && (
                        <MessageBar messageBarType={MessageBarType.error}>
                            {message}
                        </MessageBar>
                    )}
                    {editing && editable && (
                        <QuestionEditor
                            logic={logic}
                            mayBeRedraw={this.props.mayBeRedraw}
                            onClose={this.closeEditor.bind(this)}
                            parent={parent}
                        />
                    )}
                </QuestionContent>
                {Boolean(hasEnumOptions && !collapsed) && (
                    <OptionsQuestionsContainer>
                        {logic.enumOptions?.map((item, i) => {
                            if (item.logic === null) return null

                            return (
                                <span key={`option${item.label}${i}`}>
                                    <QuestionView
                                        flow={flow}
                                        editable={editable}
                                        path={add_to_path(
                                            path,
                                            "enumOptions",
                                            i
                                        )}
                                        mayBeRedraw={this.props.mayBeRedraw}
                                        render_id={render_id}
                                    />
                                    <Xarrow
                                        start={path_id(
                                            add_to_path(path, render_id)
                                        )}
                                        end={path_id(
                                            add_to_path(
                                                path,
                                                "enumOptions",
                                                i,
                                                render_id
                                            )
                                        )}
                                        path="grid"
                                        startAnchor="right"
                                        endAnchor="left"
                                        arrowBodyProps={{
                                            strokeLinejoin: "round",
                                        }}
                                        headSize={5}
                                        color={state.theme.colors.primaryDark}
                                    />
                                    <BigBreak />
                                </span>
                            )
                        })}
                    </OptionsQuestionsContainer>
                )}
                {Boolean(logic.options && !collapsed) && (
                    <OptionsQuestionsContainer>
                        {logic.options?.map((opt, i) => (
                            <span key={"option" + i}>
                                <QuestionView
                                    flow={flow}
                                    editable={editable}
                                    path={add_to_path(path, "options", i)}
                                    mayBeRedraw={this.props.mayBeRedraw}
                                    render_id={render_id}
                                />
                                <Xarrow
                                    start={path_id(
                                        add_to_path(path, render_id)
                                    )}
                                    end={path_id(
                                        add_to_path(
                                            path,
                                            "options",
                                            i,
                                            render_id
                                        )
                                    )}
                                    path="grid"
                                    startAnchor="right"
                                    endAnchor="left"
                                    arrowBodyProps={{
                                        strokeLinejoin: "round",
                                    }}
                                    headSize={5}
                                    color={state.theme.colors.primaryDark}
                                />
                                <BigBreak />
                            </span>
                        ))}
                    </OptionsQuestionsContainer>
                )}
            </QuestionContainer>
        )
    }
}

const QuestionContainer = styled.div`
    display: flex;
    align-items: flex-start;
    &.next {
        flex-direction: column;
    }
    &.options {
        flex-direction: row;
    }
`

const QuestionContentStyled = styled(FlexColumn)`
    background-color: ${(props) => props.theme.colors.backgroundDark};
    padding: 1em 0.5em;
    width: 320px;
    margin: 0.5em;
    border: 1px solid ${(props) => props.theme.colors.primaryDark};
    border-radius: 0.75em;
    &.error {
        border: 1px solid ${(props) => props.theme.colors.danger};
    }
    .title-bar {
        border-bottom: 1px solid ${(props) => props.theme.colors.primaryDark};
        padding-bottom: 2px;
    }
`

function QuestionContent({
    id,
    className,
    next,
    children,
}: {
    id: string
    className: string
    next: React.ReactNode
    children: React.ReactNode
}) {
    return (
        <FlexColumn>
            <QuestionContentStyled id={id} className={className}>
                {children}
            </QuestionContentStyled>
            {next}
        </FlexColumn>
    )
}

const BigBreak = styled(FlexColumn)`
    margin-top: 3em;
`

const OptionsQuestionsContainer = styled(FlexColumn)`
    margin-left: 3em;
`

function add_to_path(path: string, ...steps: any[]): string {
    let parts: string[] = []
    if (path) parts = path.split("/")
    steps.forEach((step) => parts.push(`${step}`))
    return parts.join("/")
}

function path_id(path: string): string {
    if (!path) return "primary-question"
    const parts = path.split("/").map((s) => {
        if (s === "next") return "n"
        if (s === "options") return "o"
        if (s === "enumOptions") return "e"
        return s
    })
    return `question-${parts.join("")}`
}
