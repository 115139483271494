import React from "react"
import styled from "styled-components"
import { observable } from "mobx"
import { observer } from "mobx-react"
import { RouteComponentProps } from "react-router-dom"
import { CommandBar, ICommandBarItemProps } from "@fluentui/react"
import {
    ConstrainedBody,
    Flex,
    FlexColumn,
    Title,
    ListItem,
} from "components/shared"
import { GlobalContext, GlobalState } from "data/state"
import { Flow } from "data/flows"
import TimeAgo from "react-timeago"
import { LiveQuery } from "data/livedata"
import { HasScope } from "components/access"
import { Scope } from "data/scope"

@observer
export class FlowsList extends React.Component<RouteComponentProps> {
    static contextType = GlobalContext
    @observable query = new LiveQuery<Flow>(Flow)
    componentDidMount() {
        const state = this.context as GlobalState
        this.query.where("OrganizationId", "==", state.organizationId)
        this.query.run().then(state.promiseLoadingHelper())
    }
    menuItems(): ICommandBarItemProps[] {
        const { history } = this.props
        const state = this.context as GlobalState
        return [
            {
                key: "add",
                text: "Add Flow",
                iconProps: { iconName: "Add" },
                ariaLabel: "Add Flow",
                renderedInOverflow: false,
                onClick: () =>
                    history.push(`/${state.organizationId}/flows/new`),
                disabled: !state.user?.Scope.can_create_flows,
            },
        ]
    }
    render() {
        const state = this.context as GlobalState

        return (
            <HasScope scope={Scope.ReadFlows}>
                <ConstrainedBody maxWidth={1000}>
                    <FlowsCommandBar align="center">
                        <Title>My Flows</Title>
                        <CommandBar
                            style={{
                                flexGrow: 1,
                            }}
                            items={[]}
                            farItems={this.menuItems()}
                        />
                    </FlowsCommandBar>
                    <FlexColumn>
                        {this.query.map((flow) => {
                            return (
                                <ListItem
                                    key={flow.doc.id}
                                    to={`/${state.organizationId}/flows/${flow.doc.id}`}
                                    icon="FlowChart"
                                    text={flow.Name}
                                    description={flow.Description}
                                    footer={
                                        <Flex>
                                            <span>Created</span>:{" "}
                                            <TimeAgo
                                                className="secondary"
                                                date={flow.Created}
                                            />
                                            &nbsp;&nbsp;&nbsp;
                                            <span>Updated</span>:{" "}
                                            <TimeAgo
                                                className="secondary"
                                                date={flow.Updated}
                                            />
                                        </Flex>
                                    }
                                />
                            )
                        })}
                    </FlexColumn>
                </ConstrainedBody>
            </HasScope>
        )
    }
}

const FlowsCommandBar = styled(Flex)`
    border-bottom: 1px solid ${(p) => p.theme.colors.backgroundDark};
`
