import React from "react"
import { Link } from "react-router-dom"
import { ConstrainedBody, Paragraph, Title } from "components/shared"

interface NotFoundProps {
    title?: string
    children?: React.ReactNode
}

export class NotFound extends React.Component<NotFoundProps> {
    render() {
        const { title, children } = this.props
        return (
            <ConstrainedBody maxWidth={800}>
                {!title && <Title>Page not-found</Title>}
                {Boolean(title) && <Title>{title}</Title>}
                {!children && (
                    <Paragraph>
                        The page not-found. <Link to="/">Goto Home</Link>.
                    </Paragraph>
                )}
                {Boolean(children) && <Paragraph>{children}</Paragraph>}
            </ConstrainedBody>
        )
    }
}
