import React from "react"
import { observer } from "mobx-react"
import styled from "styled-components"
import { Modal, CommandBar, ICommandBarItemProps } from "@fluentui/react"
import Editor from "@monaco-editor/react"
import { FlexColumn } from "components/shared"
import { Logic } from "data/flows"
import { GlobalContext, GlobalState } from "data/state"

interface AdvancedEditorProps {
    advanced: boolean
    logic: Logic
    children: React.ReactNode
    onClose: () => void
}

interface AdvancedEditorState {
    screen: "logic:before" | "logic:after" | "custom" | "basic"
}

@observer
export class AdvancedEditor extends React.Component<
    AdvancedEditorProps,
    AdvancedEditorState
> {
    static contextType = GlobalContext
    constructor(props: AdvancedEditorProps) {
        super(props)
        this.state = {
            screen: "logic:before",
        }
    }

    componentDidMount() {
        const { logic } = this.props
        if (logic.type === "CUSTOM") {
            this.setState({ screen: "custom" })
        }
    }

    getCode(): string {
        const { logic } = this.props
        const { screen } = this.state
        if (logic.code === undefined) return "// your code here"
        if (screen === "logic:after" && logic.code.after) {
            return logic.code.after
        }
        if (screen === "logic:before" && logic.code.before) {
            return logic.code.before
        }
        if (screen === "custom" && logic.code.custom) {
            return logic.code.custom
        }
        return "// your code here"
    }

    setCode(code: any) {
        const { logic } = this.props
        const { screen } = this.state
        if (logic.code === undefined) {
            logic.code = {}
        }
        if (screen === "logic:after") {
            logic.code.after = `${code}`
        }
        if (screen === "logic:before") {
            logic.code.before = `${code}`
        }
        if (screen === "custom") {
            logic.code.custom = `${code}`
        }
    }

    getMenu(): ICommandBarItemProps[] {
        const { screen } = this.state
        const { logic } = this.props

        const items: ICommandBarItemProps[] = []
        if (logic.type !== "CUSTOM") {
            items.push({
                key: "logic:before",
                text: "Logic Before",
                className: screen === "logic:before" ? "selected" : "",
                iconProps: { iconName: "CodeEdit" },
                onClick: () => this.setState({ screen: "logic:before" }),
            })
            if (logic.type !== "END" && logic.type !== "REDIRECT") {
                items.push({
                    key: "logic:after",
                    text: "Logic After",
                    className: screen === "logic:after" ? "selected" : "",
                    iconProps: { iconName: "CodeEdit" },
                    onClick: () => this.setState({ screen: "logic:after" }),
                })
            }
        }
        if (logic.type === "CUSTOM") {
            items.push({
                key: "custom",
                text: "Custom Code",
                className: screen === "custom" ? "selected" : "",
                iconProps: { iconName: "CodeEdit" },
                onClick: () => this.setState({ screen: "custom" }),
            })
        }
        return items
    }
    render() {
        const { advanced, children, onClose } = this.props
        const { screen } = this.state
        const state: GlobalState = this.context
        const items = this.getMenu()

        return (
            <span>
                {children}
                {advanced && (
                    <StyledModal isOpen={true} onDismiss={onClose}>
                        <div
                            className="container"
                            style={{
                                paddingTop: 0,
                                width: "100%",
                                display: "flex",
                            }}>
                            {children}
                            <FlexColumn className="main">
                                <CommandBar
                                    className="tabs"
                                    items={[]}
                                    farItems={items}
                                />

                                {Boolean(
                                    screen === "logic:after" ||
                                        screen === "logic:before" ||
                                        screen === "custom"
                                ) && (
                                    <Editor
                                        value={this.getCode()}
                                        onChange={this.setCode.bind(this)}
                                        height="65vh"
                                        defaultLanguage="javascript"
                                        className="editor"
                                        theme={
                                            state.theme.isDark
                                                ? "vs-dark"
                                                : "vs"
                                        }
                                        options={{
                                            minimap: { enabled: false },
                                        }}
                                    />
                                )}
                            </FlexColumn>
                        </div>
                    </StyledModal>
                )}
            </span>
        )
    }
}

const StyledModal = styled(Modal)`
    .container {
        border-top: 3px solid ${(props) => props.theme.colors.primaryDark} !important;
        width: 80vw !important;
        display: flex;
    }
    .main {
        flex-grow: 1;
        .tabs {
            border-bottom: 1px solid
                ${(props) => props.theme.colors.primaryDark};
        }
    }
    .selected {
        border-bottom: 2px solid ${(props) => props.theme.colors.primaryDark};
    }

    .ms-Modal-scrollableContent::-webkit-scrollbar {
        width: 3px;
        height: 3px;
    }
    .ms-Modal-scrollableContent::-webkit-scrollbar-track {
        background-color: ${(p) => p.theme.colors.backgroundLight};
    }

    .ms-Modal-scrollableContent::-webkit-scrollbar-thumb {
        background-color: ${(p) => p.theme.colors.backgroundDark};
        border-radius: 5px;
    }
`
