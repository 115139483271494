import { LiveData } from "data/livedata"
import { computed, observable } from "mobx"
import { Channel } from "./channels"
import { Contact } from "./contacts"

interface Activity {
    Input: string
    InputTime: number
    Output: string
    OutputTime: number
}

export class Session extends LiveData {
    @observable Activities: Activity[] = []
    @observable ContactID: string = ""
    @observable Channel: string = ""
    @observable Cost: string = ""
    @observable CreatedAt: Date = new Date()
    @observable Organization: string = ""
    @observable PhoneNumber: string = ""
    @observable SessionID: string = ""
    @observable UpdatedAt: Date = new Date()
    static Fields = [
        "Activities",
        "ContactID",
        "Channel",
        "Cost",
        "CreatedAt",
        "Organization",
        "PhoneNumber",
        "SessionID",
        "UpdatedAt",
    ]
    static Collection = LiveData.collection("Sessions")

    @computed get contact(): Contact | null {
        if (this.ContactID) {
            return new Contact(this.ContactID)
        }
        return null
    }
    @computed get channel(): Channel | null {
        if (this.Channel) {
            return new Channel(this.Channel)
        }
        return null
    }
}
