import { createContext } from "react"
import { observable, computed } from "mobx"
import firebase from "firebase/app"
import {
    Organization,
    OrganizationInvite,
    OrganizationUser,
} from "data/organization"
import { Flow } from "data/flows"
import { ApiError } from "utils/api"
import { Theme, darkTheme, lightTheme } from "data/theme"
import { FirebaseUser } from "./users"
import { LiveQuery } from "./livedata"

interface OrganizationsMap {
    [orgId: string]: {
        org: Organization
        user: OrganizationUser
    }
}

interface FlowsMap {
    [flowId: string]: Flow
}

interface FetchProps {
    method?: "GET" | "POST" | "PUT" | "DELETE"
    data?: any
    headers?: Headers
    load?: boolean
}

export class GlobalState {
    @observable authorized: FirebaseUser | null = null
    @observable organizations: OrganizationsMap = {}
    @observable flows: FlowsMap = {}
    @observable organizationId: string | null = null
    @observable loadingItems: string[] = []
    @observable darkMode: boolean = false
    @observable menuExpanded: boolean = true
    @observable invitesQuery: LiveQuery<OrganizationInvite> | null = null

    constructor() {
        this.darkMode = window.localStorage.getItem("mode") === "dark"
        this.menuExpanded =
            window.localStorage.getItem("menuExpanded") === "true"
    }

    @computed get theme(): Theme {
        if (this.darkMode) return darkTheme
        return lightTheme
    }
    @computed get organization(): Organization | null {
        this.flows = {}
        if (this.organizationId) {
            this.loadFlows()
            return this.organizations[this.organizationId].org
        }
        return null
    }

    @computed get user(): OrganizationUser | null {
        if (this.organizationId) {
            return this.organizations[this.organizationId].user
        }
        return null
    }

    @computed get loading(): boolean {
        return this.loadingItems.length > 0
    }

    toggleMode() {
        this.darkMode = !this.darkMode
        window.localStorage.setItem("mode", this.darkMode ? "dark" : "light")
    }

    toggleMenuExpanded() {
        this.menuExpanded = !this.menuExpanded
        window.localStorage.setItem(
            "menuExpanded",
            this.menuExpanded ? "true" : "false"
        )
    }

    startLoading(item: string) {
        this.loadingItems.push(item)
    }

    stopLoading(item: string) {
        const position = this.loadingItems.indexOf(item)
        if (position >= 0) {
            this.loadingItems.splice(position, 1)
        }
    }

    promiseLoadingHelper(): () => void {
        // In most generic promises that we need to inform the user
        // Call this function as the final block.
        const processId = new Date().getTime().toString(32)
        this.startLoading(processId)
        return () => {
            this.stopLoading(processId)
        }
    }

    confirmAuth() {
        this.startLoading("auth")
        firebase.auth().onAuthStateChanged((user: firebase.User | null) => {
            this.stopLoading("auth")
            if (!user) {
                this.authorized = null
                this.invitesQuery = null
                return
            }
            this.authorized = new FirebaseUser(user.uid, undefined, () => {
                if (this.authorized === null) return
                this.authorized.LastSeen = new Date()
                this.authorized.update()
            })
            this.invitesQuery = new LiveQuery(OrganizationInvite)
            this.invitesQuery.where("EmailAddress", "==", user.email)
            this.invitesQuery.where("Status", "==", "invited")
            this.invitesQuery.run().finally(this.promiseLoadingHelper())
            this.loadOrganizations()
        })
    }

    setOrgFromPath() {
        const orgId = window.location.pathname.split("/")[1]
        if (orgId === this.organizationId) return
        const org = this.organizations[orgId]
        if (org) {
            this.organizationId = orgId
            document.title = `Flow Builder | ${org.org.Name}`
        } else {
            this.organizationId = null
            document.title = "Flow Builder"
        }
    }

    fetch<T>(url: string, props?: FetchProps): Promise<T> {
        // Replace common rout variables before fetch
        let headers = props?.headers
        let data = props?.data
        const method = props?.method ? props.method : "GET"
        const processId = new Date().getTime().toString(32)
        const authorizedUser = firebase.auth().currentUser
        if (!authorizedUser) {
            return Promise.reject("Unauthorized user")
        }
        if (props?.load) this.startLoading(processId)
        return authorizedUser
            .getIdToken()
            .then((token) => {
                if (!headers) {
                    headers = new Headers()
                }
                if (data && typeof data !== "string") {
                    data = JSON.stringify(data)
                }
                headers.set("Accept", "application/json")
                headers.set("Authorization", `Bearer ${token}`)
                return fetch(url, {
                    mode: "cors",
                    credentials: "include",
                    headers,
                    method,
                    body: data,
                })
                    .then((response) => {
                        this.stopLoading(processId)
                        if (!response.ok) {
                            if (response.status === 401) {
                                firebase.auth().signOut()
                                return
                            } else {
                                throw new ApiError(response)
                            }
                        }
                        return response.json()
                    })
                    .catch((err: ApiError) => {
                        if (props?.load) this.stopLoading(processId)
                        throw err
                    })
            })
            .catch((err) => {
                if (props?.load) this.stopLoading(processId)
                throw err
            })
    }

    loadOrganizations() {
        OrganizationUser.Collection()
            .where("UserId", "==", this.authorized?.doc.id)
            .onSnapshot((querySnapshot) => {
                querySnapshot.docChanges().forEach((change) => {
                    if (change.type === "added") {
                        const data = change.doc.data()
                        const orgId = data["OrganizationId"]
                        this.organizations[orgId] = {
                            org: new Organization(orgId),
                            user: new OrganizationUser(
                                change.doc.id,
                                change.doc
                            ),
                        }
                    }
                    if (this.organization === null) this.setOrgFromPath()
                })
            })
    }

    loadFlows() {
        Flow.Collection()
            .where("OrganizationId", "==", this.organizationId)
            .onSnapshot((querySnapshot) => {
                querySnapshot.docChanges().forEach((change) => {
                    if (change.type === "added") {
                        this.flows[change.doc.id] = new Flow(
                            change.doc.id,
                            change.doc
                        )
                    }
                    if (change.type === "removed") {
                        delete this.flows[change.doc.id]
                    }
                })
            })
    }

    async createUser(
        email: string,
        password: string,
        displayName: string
    ): Promise<void> {
        this.startLoading("register")
        const cred = await firebase
            .auth()
            .createUserWithEmailAndPassword(email, password)
        if (cred.user) {
            await cred.user.updateProfile({ displayName })
        }
        this.startLoading("register")
    }
}

export const GlobalContext = createContext(new GlobalState())
