import React from "react"
import styled from "styled-components"
import { Link, RouteComponentProps } from "react-router-dom"
import {
    TextField,
    PrimaryButton,
    MessageBarType,
    MessageBar,
} from "@fluentui/react"
import { ButtonsHolder, FormItem, Title, ButtonLink } from "components/shared"
import { GlobalContext, GlobalState } from "data/state"
import firebase from "firebase/app"

interface RegState {
    error: null | string
}

export class Register extends React.Component<RouteComponentProps, RegState> {
    static contextType = GlobalContext
    constructor(props: any) {
        super(props)
        this.state = {
            error: null,
        }
    }

    register(evt: React.FormEvent<HTMLFormElement>) {
        evt.preventDefault()
        const name = evt.currentTarget.displayName.value
        const email = evt.currentTarget.email.value
        const password = evt.currentTarget.password.value
        const passwordConfirm = evt.currentTarget.passwordConfirm.value
        if (password !== passwordConfirm) {
            this.setState({ error: "Passwords must match" })
            return
        }

        const state: GlobalState = this.context
        state
            .createUser(email, password, name)
            .then(() => {
                this.props.history.push("/")
            })
            .catch((error: firebase.FirebaseError) => {
                this.setState({ error: error.message })
            })
    }

    googleLogin() {
        const state: GlobalState = this.context
        const stop = state.promiseLoadingHelper()
        this.setState({ error: null }, () => {
            firebase
                .auth()
                .signInWithPopup(new firebase.auth.GoogleAuthProvider())
                .then(() => {
                    stop()
                    this.props.history.push("/")
                })
                .catch((error: firebase.FirebaseError) => {
                    this.setState({ error: error.message })
                })
                .finally(stop)
        })
    }

    render() {
        const { error } = this.state
        const state: GlobalState = this.context
        return (
            <Container onSubmit={this.register.bind(this)}>
                <FormItem>
                    <Title>Create Account</Title>
                </FormItem>
                <FormItem>
                    Display Name
                    <TextField
                        disabled={state.loading}
                        name="displayName"
                        required
                        type="text"
                        placeholder="Joel Doe"
                    />
                </FormItem>
                <FormItem>
                    Email Address
                    <TextField
                        disabled={state.loading}
                        name="email"
                        required
                        type="email"
                        placeholder="name@domain.tld"
                    />
                </FormItem>
                <FormItem>
                    Password
                    <TextField
                        name="password"
                        disabled={state.loading}
                        type="password"
                        required
                        canRevealPassword
                        placeholder="A tricky password"
                    />
                </FormItem>

                <FormItem>
                    Confirm Password
                    <TextField
                        name="passwordConfirm"
                        disabled={state.loading}
                        required
                        type="password"
                        canRevealPassword
                        placeholder="Repeat password"
                    />
                </FormItem>
                {error !== null && (
                    <MessageBar messageBarType={MessageBarType.error}>
                        {error}
                    </MessageBar>
                )}
                <ButtonsHolder>
                    <ButtonLink onClick={this.googleLogin.bind(this)}>
                        <img src="/google.png" alt="" />
                        Login with Google
                    </ButtonLink>
                    <PrimaryButton
                        text="Register"
                        disabled={state.loading}
                        type="primary"
                    />
                </ButtonsHolder>
                <ButtonsHolder>
                    <Link to="/auth">Already have an account? Login.</Link>
                </ButtonsHolder>
            </Container>
        )
    }
}

const Container = styled.form`
    display: flex;
    flex-direction: column;
`
