import React from "react"
import styled from "styled-components"
import { observer } from "mobx-react"
import { Link, RouteComponentProps } from "react-router-dom"
import {
    TextField,
    PrimaryButton,
    MessageBar,
    MessageBarType,
    Spinner,
} from "@fluentui/react"
import { FlexColumn, FormItem, ButtonsHolder } from "components/shared"
import firebase from "firebase/app"
import { GlobalContext, GlobalState } from "data/state"
import { Title, ButtonLink } from "components/shared"

interface LoginState {
    error: null | string
}

@observer
export class Login extends React.Component<RouteComponentProps, LoginState> {
    static contextType = GlobalContext
    constructor(props: RouteComponentProps) {
        super(props)
        this.state = {
            error: null,
        }
    }

    login(evt: React.FormEvent<HTMLFormElement>) {
        evt.preventDefault()
        const email = evt.currentTarget.email.value
        const password = evt.currentTarget.password.value
        const state: GlobalState = this.context
        const stopLoading = state.promiseLoadingHelper()
        this.setState({ error: null }, () => {
            firebase
                .auth()
                .signInWithEmailAndPassword(email, password)
                .then(() => {
                    stopLoading()
                    if (this.props.location.pathname === "/auth") {
                        this.props.history.push("/")
                    }
                })
                .catch((error: firebase.FirebaseError) => {
                    let message = error.message
                    switch (error.code) {
                        case "auth/user-not-found":
                        case "auth/wrong-password":
                            message = "Invalid username or password"
                    }
                    this.setState({ error: message })
                })
                .finally(stopLoading)
        })
    }

    googleLogin() {
        const provider = new firebase.auth.GoogleAuthProvider()
        const state: GlobalState = this.context
        const stop = state.promiseLoadingHelper()
        this.setState({ error: null }, () => {
            firebase
                .auth()
                .signInWithPopup(provider)
                .then(() => {
                    stop()
                    if (this.props.location.pathname === "/auth") {
                        this.props.history.push("/")
                    }
                })
                .catch((error: firebase.FirebaseError) => {
                    this.setState({ error: error.message })
                })
                .finally(stop)
        })
    }

    render() {
        const { loading } = this.context
        const { error } = this.state
        return (
            <LoginContainer onSubmit={this.login.bind(this)}>
                <FormItem>
                    <Title>Sign In</Title>
                </FormItem>
                <FormItem>
                    Email
                    <TextField
                        name="email"
                        disabled={loading}
                        type="email"
                        required
                        placeholder="name@domain.tld"
                    />
                </FormItem>
                <FormItem>
                    Password
                    <TextField
                        name="password"
                        disabled={loading}
                        required
                        type="password"
                        canRevealPassword
                        placeholder="Unguessable characters"
                    />
                </FormItem>
                {error !== null && (
                    <MessageBar messageBarType={MessageBarType.error}>
                        {error}
                    </MessageBar>
                )}
                {!loading && (
                    <ButtonsHolder>
                        <ButtonLink onClick={this.googleLogin.bind(this)}>
                            <img src="/google.png" alt="" />
                            Login with Google
                        </ButtonLink>
                        <PrimaryButton
                            text="Login"
                            disabled={loading}
                            type="primary"
                        />
                    </ButtonsHolder>
                )}
                {!loading && (
                    <ButtonsHolder
                        style={{ justifyContent: "space-between", margin: 20 }}>
                        <Link to="/auth/new">Create Account</Link>
                        <Link to="/auth/reset">Forgot your password?</Link>
                    </ButtonsHolder>
                )}
                {loading && (
                    <FlexColumn align="center">
                        <Spinner />
                    </FlexColumn>
                )}
            </LoginContainer>
        )
    }
}

const LoginContainer = styled.form`
    display: flex;
    flex-direction: column;
`
