import React from "react"
import styled from "styled-components"
import { observer } from "mobx-react"
import TimeAgo from "react-timeago"
import { MessageBar, MessageBarType, Icon } from "@fluentui/react"
import { RouteComponentProps, Route } from "react-router-dom"
import { GlobalContext, GlobalState } from "data/state"
import {
    FlexColumn,
    Flex,
    PageCommandBar,
    ConstrainedBody,
    ListItem,
} from "components/shared"
import { Session } from "data/sessions"
import { SessionView } from "./session"
import { LiveQuery } from "data/livedata"
import { channelLabels, ChannelType } from "data/channel_types"
import { HasScope } from "components/access"
import { Scope } from "data/scope"

@observer
export class SessionsView extends React.Component<RouteComponentProps> {
    static contextType = GlobalContext
    query = new LiveQuery<Session>(Session)

    componentDidMount() {
        const state: GlobalState = this.context
        this.query.where("Organization", "==", state.organizationId)
        this.query.orderBy("CreatedAt", "desc")
        this.query.run().finally(state.promiseLoadingHelper())
    }
    render() {
        const state: GlobalState = this.context
        const {
            location: { pathname },
        } = this.props
        return (
            <HasScope scope={Scope.ReadSessions}>
                <ConstrainedBody maxWidth={1200}>
                    <PageCommandBar icon="WorkFlow" title="Sessions">
                        {[
                            {
                                key: "filter",
                                text: "Filter",
                                iconProps: { iconName: "Filter" },
                            },
                        ]}
                    </PageCommandBar>
                    <Flex>
                        <SessionList
                            className={
                                pathname.split("/").pop() === `sessions`
                                    ? "full-width"
                                    : ""
                            }>
                            {this.query.isEmpty && (
                                <MessageBar
                                    messageBarType={MessageBarType.info}>
                                    You don't have any sessions yet.
                                </MessageBar>
                            )}
                            {this.query.map((session) => {
                                let channelType: ChannelType = "SHORT_CODE"
                                if (session.channel !== null)
                                    channelType = session.channel.Type
                                const label = channelLabels.get(channelType)
                                return (
                                    <ListItem
                                        key={session.doc.id}
                                        to={`/${state.organizationId}/sessions/${session.doc.id}`}
                                        icon={label?.icon}
                                        text={
                                            session.contact?.DisplayName || ""
                                        }
                                        description={
                                            <Flex align="center">
                                                {label?.label}{" "}
                                                <Icon
                                                    style={{ fontSize: 8 }}
                                                    iconName="ChevronRight"
                                                />{" "}
                                                <span>
                                                    {session.channel?.ShortId}
                                                </span>
                                            </Flex>
                                        }
                                        footer={
                                            <TimeAgo
                                                className="date"
                                                date={session.CreatedAt}
                                            />
                                        }
                                    />
                                )
                            })}
                        </SessionList>
                        <Route
                            path="/:ogrId/sessions/:sessionId"
                            component={SessionView}
                        />
                    </Flex>
                </ConstrainedBody>
            </HasScope>
        )
    }
}

const SessionList = styled(FlexColumn)`
    width: 30%;
    &.full-width {
        width: 100%;
    }
`
