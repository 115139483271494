import React from "react"
import styled from "styled-components"
import { observer } from "mobx-react"
import { Link } from "react-router-dom"
import {
    TextField,
    PrimaryButton,
    MessageBar,
    FontIcon,
    MessageBarType,
} from "@fluentui/react"
import { FlexColumn, Title, ButtonLink } from "components/shared"
import { GlobalContext, GlobalState } from "data/state"
import firebase from "firebase/app"

interface ResetStartState {
    message: string | null
    success: boolean
}

@observer
export class ResetStart extends React.Component<any, ResetStartState> {
    static contextType = GlobalContext
    constructor(props: any) {
        super(props)
        this.state = {
            message: null,
            success: false,
        }
    }

    startReset(evt: React.FormEvent<HTMLFormElement>) {
        evt.preventDefault()
        const state: GlobalState = this.context
        const email = evt.currentTarget.email.value
        this.setState({ success: false, message: null })
        firebase
            .auth()
            .sendPasswordResetEmail(email)
            .then(() => {
                this.setState({
                    message: `Password reset instructions sent to ${email}.`,
                    success: true,
                })
            })
            .catch((err: any) => {
                this.setState({
                    message: err.message,
                    success: false,
                })
            })
            .finally(state.promiseLoadingHelper())
    }

    render() {
        const { message, success } = this.state
        const state: GlobalState = this.context
        return (
            <Container onSubmit={this.startReset.bind(this)}>
                <FormItem>
                    <Title>Reset Password</Title>
                </FormItem>
                <FormItem>
                    Email Address
                    <TextField
                        name="email"
                        type="email"
                        required
                        placeholder="name@domain.tld"
                        disabled={state.loading}
                    />
                </FormItem>
                {Boolean(message) && (
                    <MessageBar
                        messageBarType={
                            success
                                ? MessageBarType.success
                                : MessageBarType.error
                        }>
                        {message}
                    </MessageBar>
                )}
                <ButtonsHolder>
                    <ButtonLink as={Link} to="/auth">
                        <FontIcon iconName="Back" />
                        Back to Login
                    </ButtonLink>
                    <PrimaryButton
                        disabled={state.loading}
                        text="Get Reset Link"
                        type="primary"
                    />
                </ButtonsHolder>
            </Container>
        )
    }
}

interface ResetCompleteState {
    email: string
    fullname: string
    loading: Boolean
}

const Container = styled.form`
    display: flex;
    flex-direction: column;
`

const FormItem = styled(FlexColumn)`
    margin: 10px;
    a {
        font-size: 16px;
    }
`

const ButtonsHolder = styled(FormItem)`
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    button {
        min-width: 160px;
    }
`
