import React from "react"
import styled from "styled-components"
import { RouteComponentProps } from "react-router-dom"
import { observer } from "mobx-react"
import { ChannelRequest } from "data/channels"
import {
    channelLabels,
    ChannelType,
    ChannelTypeOptions,
} from "data/channel_types"
import { uniqueId } from "utils"
import {
    Break,
    FlexColumn,
    PageCommandBar,
    Flex,
    MessageBar,
} from "components/shared"
import { Dropdown, TextField, PrimaryButton } from "@fluentui/react"
import { GlobalContext, GlobalState } from "data/state"
import { HasScope } from "components/access"
import { Scope } from "data/scope"

@observer
export class RequestChannel extends React.Component<RouteComponentProps> {
    request = new ChannelRequest(uniqueId())
    static contextType = GlobalContext
    componentDidMount() {
        const state: GlobalState = this.context
        if (state.user === null) return
        if (state.authorized === null) return
        this.request.RequestedBy = state.user.doc.id
        this.request.Email = state.authorized.Emails[0] || ""
        this.request.Phone = state.organization?.Phone || ""
        this.request.OrganizationID = `${state.organizationId}`
    }

    save() {
        const state: GlobalState = this.context
        const { history } = this.props
        this.request
            .update()
            .then(() => {
                history.push(
                    `/${state.organizationId}/settings/channels/r/${this.request.doc.id}`
                )
            })
            .finally(state.promiseLoadingHelper())
    }

    render() {
        return (
            <HasScope scope={Scope.RequestChannels}>
                <Container>
                    <PageCommandBar title="Request a new channel" />
                    <Break />
                    <Dropdown
                        label="Channel Type"
                        options={ChannelTypeOptions}
                        selectedKey={this.request.Type}
                        onChange={(evt, opt) => {
                            if (opt === undefined) return
                            this.request.Type = opt.key as ChannelType
                        }}
                    />
                    <MessageBar>
                        {channelLabels.get(this.request.Type)?.description}
                    </MessageBar>
                    <Break />
                    <TextField
                        value={this.request.Email}
                        label="Email Address"
                        onChange={(evt, value) => {
                            if (value === undefined) return
                            this.request.Email = value
                        }}
                    />
                    <Break />
                    <TextField
                        value={this.request.Phone}
                        label="Phone Number"
                        onChange={(evt, value) => {
                            if (value === undefined) return
                            this.request.Phone = value
                        }}
                    />
                    <Break />
                    <TextField
                        value={this.request.Message}
                        label="Message"
                        multiline
                        onChange={(evt, value) => {
                            if (value === undefined) return
                            this.request.Message = value
                        }}
                    />
                    <Break size={2} />
                    <Flex justify="flex-end">
                        <PrimaryButton
                            onClick={this.save.bind(this)}
                            text="Send Request"
                        />
                    </Flex>
                </Container>
            </HasScope>
        )
    }
}

const Container = styled(FlexColumn)`
    flex-grow: 1;
    padding: 0.25em;
`
