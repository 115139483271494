import { observable, computed } from "mobx"
import { LiveData } from "data/livedata"
import { FirebaseUser } from "data/users"
import { Scope } from "./scope"

export interface AttributeInterface {
    type: "TEXT" | "DATE" | "NUMBER"
    description: string
    created: number
}

export class Organization extends LiveData {
    static Collection = LiveData.collection("Organizations")
    static Fields = [
        "Name",
        "Description",
        "Email",
        "Phone",
        "Status",
        "Created",
        "AttributesSchema",
    ]

    @observable AttributesSchema: {
        [attributeKey: string]: AttributeInterface
    } = {}
    @observable Name: string = ""
    @observable Email: string = ""
    @observable Phone: string = ""
    @observable Description: string = ""
    @observable Status: "active" | "archived" = "active"
    @observable Created: Date = new Date()
}

export class OrganizationUser extends LiveData {
    static Collection = LiveData.collection("OrganizationUsers")
    static Fields = [
        "OrganizationId",
        "UserId",
        "Scope",
        "Status",
        "Updated",
        "Added",
    ]
    @observable OrganizationId: string = ""
    @observable UserId: string = "blank_user"
    @observable Scope: Scope = new Scope([])
    @observable Status: "active" | "archived" = "active"
    @observable Updated: Date = new Date()
    @observable Added: Date = new Date()

    @computed get firebaseUser(): FirebaseUser {
        return new FirebaseUser(this.UserId)
    }
}

export class OrganizationInvite extends LiveData {
    static Collection = LiveData.collection("OrganizationInvites")
    static Fields = [
        "OrganizationId",
        "OrganizationName",
        "EmailAddress",
        "Scope",
        "Status",
        "InvitedBy",
        "InviterEmail",
        "Invited",
        "Updated",
    ]

    @observable OrganizationId: string = "blank_org"
    @observable OrganizationName: string = ""
    @observable EmailAddress: string = ""
    @observable Scope: Scope = new Scope([])
    @observable Status: "invited" | "accepted" | "rejected" | "canceled" =
        "invited"
    @observable InvitedBy: string = "blank_user"
    @observable InviterEmail: string = ""
    @observable Invited: Date = new Date()
    @observable Updated: Date = new Date()

    @computed get emailValid(): boolean {
        const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(this.EmailAddress.toLowerCase())
    }

    @computed get invitedBy(): OrganizationUser {
        return new OrganizationUser(this.InvitedBy)
    }

    @computed get organization() {
        return new Organization(this.OrganizationId)
    }
}
