import React from "react"
import styled from "styled-components"
import { RouteComponentProps } from "react-router-dom"
import { observer } from "mobx-react"
import { FlexColumn, Loading, PageCommandBar } from "components/shared"
import { Session } from "data/sessions"
import { GlobalContext, GlobalState } from "data/state"
import { NotFound } from "components/notfound"
import TimeAgo from "react-timeago"
import { GetDate } from "utils"
import { channelLabels } from "data/channel_types"

interface SessionViewProps extends RouteComponentProps {
    match: {
        params: {
            sessionId: string
        }
        isExact: boolean
        path: string
        url: string
    }
}

interface SessionViewState {
    session?: Session
}

@observer
export class SessionView extends React.Component<
    SessionViewProps,
    SessionViewState
> {
    static contextType = GlobalContext
    constructor(props: SessionViewProps) {
        super(props)
        this.state = {
            session: undefined,
        }
    }
    componentDidMount() {
        this.loadSession()
    }
    componentDidUpdate(prevProps: SessionViewProps) {
        if (
            prevProps.match.params.sessionId !==
            this.props.match.params.sessionId
        ) {
            this.loadSession()
        }
    }
    loadSession() {
        const state: GlobalState = this.context
        const {
            match: {
                params: { sessionId },
            },
        } = this.props
        state.startLoading(sessionId)
        const session = new Session(sessionId, undefined, () =>
            state.stopLoading(sessionId)
        )
        this.setState({ session })
    }
    render() {
        const { session } = this.state
        if (session === undefined) return null
        const state: GlobalState = this.context
        if (session.loading) {
            return <Loading title="Fetching Session..." />
        }
        if (!session.exists) {
            return (
                <NotFound title="Session not found">
                    The session <kbd>{session.doc.id}</kbd> was not found under{" "}
                    {state.organization?.Name}
                </NotFound>
            )
        }

        let icon = undefined
        if (session.channel !== null) {
            icon = channelLabels.get(session.channel.Type)?.icon
        }

        return (
            <SessionContainer>
                <PageCommandBar
                    icon={icon}
                    title={session.contact?.DisplayName || "Deleted Contact"}
                    top={0}
                    style={{
                        backgroundColor: state.theme.colors.backgroundDark,
                    }}
                />
                <ActivitiesList>
                    <div className="date-main">
                        {session.CreatedAt.toLocaleString()}
                    </div>
                    {session.Activities.map((act, index) => (
                        <ActivityEntry key={`item${index}`}>
                            <FlexColumn className="input">
                                <div>{act.Input}</div>
                                <MessageTime date={GetDate(act.InputTime)} />
                            </FlexColumn>
                            <FlexColumn className="output">
                                <div>{act.Output}</div>
                                <MessageTime date={GetDate(act.OutputTime)} />
                            </FlexColumn>
                        </ActivityEntry>
                    ))}
                </ActivitiesList>
            </SessionContainer>
        )
    }
}

const MessageTime = styled(TimeAgo)`
    background-color: ${(props) => props.theme.colors.fontPrimary}33;
    padding: 2px 10px 5px;
    font-size: 0.8em;
    margin: 5px -10px -5px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    &::before {
        content: "<${(p: { date: Date }) => p.date.toLocaleTimeString()}> ";
    }
`

const SessionContainer = styled(FlexColumn)`
    flex-grow: 1;
    background-color: ${(props) => props.theme.colors.backgroundDark}99;
    margin-top: 1px;
    max-height: calc(100vh - 100px);
    overflow: auto;
    &::-webkit-scrollbar {
        width: 3px;
        height: 3px;
    }
    &::-webkit-scrollbar-track {
        background-color: ${(p) => p.theme.colors.backgroundLight};
    }

    &::-webkit-scrollbar-thumb {
        background-color: ${(p) => p.theme.colors.primaryDark};
        border-radius: 5px;
    }
`

const ActivitiesList = styled(FlexColumn)`
    margin: 5px;
    padding: 10px;
    background-color: ${(props) => props.theme.colors.background};
    border-radius: 0.5em;
    .date-main {
        &::before,
        &::after {
            content: "-----------";
            margin: 0.5em 1em;
        }
        display: flex;
        justify-content: center;
        align-items: center;
    }
`

const ActivityEntry = styled(FlexColumn)`
    .input {
        background-color: ${({
            theme: {
                colors: { primary, primaryDark },
                isDark,
            },
        }) => (isDark ? primaryDark : primary)};
        color: ${(props) => (props.theme.isDark ? "#fff" : "#000")};
        align-self: flex-start;
    }
    .output {
        background-color: ${(props) => props.theme.colors.backgroundDark};
        color: ${(props) => (props.theme.isDark ? "#fff" : "#000")};
        align-self: flex-end;
    }
    .input,
    .output {
        margin: 5px;
        padding: 5px 10px;
        width: 80%;
        border-radius: 8px;
    }
`
